import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-fee-discount-setup',
  templateUrl: './fee-discount-setup.component.html',
  styleUrls: ['./fee-discount-setup.component.css']
})
export class FeeDiscountSetupComponent implements OnInit {

  IsMaxFine: boolean = false;
  InstallmentList: any;
  ComponentList: any;
  IsConcession: boolean = false;
  DiscountCategoryList: any;
  FeeDiscountList: any;
  Submitted: boolean = false;


  constructor(private financeService: FinanceService, private LookupService: LookupService, public globalHelper: GlobalHelper,
    private spinnerService: Ng4LoadingSpinnerService) { }


  FeeDiscountModel = {
    DiscountID: 0,

  }

  FineModel = {
    FineAmount: 0,
    MaxFineDays: 30,
    IsPerWeek: false,
    IsPerDay: false,
    IsPerMonth:false,
    IsFixed: true,
  };




  GetFeeDiscountSetup() {
    this.InstallmentList = [];
    this.ComponentList = [];
    this.IsConcession = true;
    this.Submitted = true;
    if (!this.ValidDropDown()) {
      this.spinnerService.show();
      this.financeService.GetFeeDiscountSetup(this.FeeDiscountModel.DiscountID).subscribe((data: any) => {
        this.ComponentList = data.FeeComponentList;
        this.InstallmentList = data.InstallmentList;
        this.Submitted = false;
        this.spinnerService.hide();
      });
    }
  };

  ValidDropDown() {
    return this.FeeDiscountModel.DiscountID == 0;
  }
  GetDiscountPriority() {

    this.financeService.GetDiscountPriority().subscribe((data: any) => {
      this.DiscountCategoryList = data.DiscountPriorityList;

    });

  };


  SaveFeeDiscountPriority() {
    this.spinnerService.show();
    this.financeService.SaveFeeDiscountPriority(JSON.stringify(this.DiscountCategoryList)).subscribe((data: any) => {
      this.spinnerService.hide();
      this.globalHelper.CheckActionStatus(data);
    })
  };

  SelectIsPerDay() {
    this.FineModel.IsPerDay = true;
    this.FineModel.IsPerWeek = false;
    this.FineModel.IsFixed = false;
    this.FineModel.IsPerMonth=false;
    this.IsMaxFine = true;
  }
  SelectIsPerWeek() {
    this.FineModel.IsPerDay = false;
    this.FineModel.IsPerWeek = true;
    this.FineModel.IsFixed = false;
    this.FineModel.IsPerMonth=false;
    this.IsMaxFine = true;
  }
  SelectIsPerMonth() {
    this.FineModel.IsPerDay = false;
    this.FineModel.IsPerWeek = false;
    this.FineModel.IsPerMonth=true;
    this.FineModel.IsFixed = false;
    this.IsMaxFine = true;
  }
  SelectIsFixed() {
    this.FineModel.IsPerDay = false;
    this.FineModel.IsPerWeek = false;
    this.FineModel.IsPerMonth=false;
    this.FineModel.IsFixed = true;
    this.IsMaxFine = false;
  }
  
  SaveFeeFineAmount() {

    this.spinnerService.show();
    this.financeService.SaveFeeFineAmount(JSON.stringify(this.FineModel)).subscribe((data: any) => {
      this.spinnerService.hide();
      this.globalHelper.CheckActionStatus(data);
    })


  };

  valid = true;
  SaveFeeDiscountSetup() {
    this.valid = true;
    var FeeComponentList = this.ComponentList;

    for (var i = 0; i < FeeComponentList.length; i++) {

      for (var j = 0; j < FeeComponentList[i].InstallmentList.length; j++) {
        if ((Math.round(FeeComponentList[i].InstallmentList[j].Amount * 1000) / 1000) > 100) {
          this.valid = false;
          break;
        }
      }

    }

    if (this.valid) {
      this.valid = false;
      this.spinnerService.show();
      this.financeService.SaveFeeDiscountSetup(JSON.stringify(this.ComponentList), this.FeeDiscountModel.DiscountID).subscribe((data: any) => {
        this.spinnerService.hide();
        this.globalHelper.CheckActionStatus(data);


      })
    }
    else {

    }
  };




  ngOnInit() {
    this.GetFineRule();
    this.GetFeeDiscount();
    this.GetDiscountPriority();

  }


  GetFineRule() {
    this.spinnerService.show();
    this.LookupService.GetLookupsList('FineRule', '').subscribe((data: any) => {

      if (data.LookupItems != null && data.LookupItems.length > 1) {
        this.FineModel = data.LookupItems[1];
        if (this.FineModel.IsFixed) {
          this.IsMaxFine = false;
        }
        else {
          this.IsMaxFine = true;
        }

      }
      else {
        this.IsMaxFine = false;
        this.FineModel.IsFixed = true;
      }
      this.spinnerService.hide();
    });
  };

  GetFeeDiscount() {
    this.LookupService.GetLookupsList('FeeDiscount', '').subscribe((data: any) => {
      this.FeeDiscountList = data.LookupItems;

    });
  };

}
