import { AuthenticationService } from './services/user/authentication.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { ReactiveFormsModule, FormsModule, } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastNotificationsModule } from "ngx-toast-notifications";
import { NgxLoadingModule } from 'ngx-loading';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AdminModule } from './admin/admin.module';
import { SharedModule } from './shared/shared.module';
import { PublicRoutingModule } from './public/public-routing.module';
import { SharedRoutingModule } from './shared/shared-routing.module';
import { SuperadminRoutingModule } from './superadmin/superadmin-routing.module';
import { FeeRoutingModule } from './fee/fee-routing.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MasterModule } from './master/master.module';
import { FeeModule } from './fee/fee.module';
import { WINDOW_PROVIDERS } from './services/Common/Window';
import { CommonService } from './services/Common/common.service';
import { StudentModalComponent } from './modal/student-modal/student-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ConfirmationDialogComponent } from './sal/confirmation-dialog/confirmation-dialog.component';
import { SaleorderviewmodelComponent } from './sal/saleorderviewmodel/saleorderviewmodel.component';
import { ConfirmationDialogService } from './services/sal/confirmation-dialog.service';
import { InvoiceviewmodelComponent } from './sal/invoiceviewmodel/invoiceviewmodel.component';
import { PaymentviewmodelComponent } from './sal/paymentviewmodel/paymentviewmodel.component';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { TreeviewModule } from 'ngx-treeview1';



@NgModule({
  declarations: [
    AppComponent,
    StudentModalComponent, 
    ConfirmationDialogComponent,
    SaleorderviewmodelComponent,  
    InvoiceviewmodelComponent,
    PaymentviewmodelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,    
    AlertModule.forRoot(),
    MasterModule,
    NgbModule,
    FeeModule,
    Ng2ImgMaxModule,
    NgMultiSelectDropDownModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    ToastNotificationsModule.forRoot({duration: 6000,position:'top-right'}),
    NgxLoadingModule.forRoot({}),
    Ng4LoadingSpinnerModule.forRoot(),
    CollapseModule.forRoot(),
    TabsModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    TreeviewModule.forRoot()
  ],
  providers: [
    WINDOW_PROVIDERS,
    CommonService,ConfirmationDialogService,
    {
     provide:HTTP_INTERCEPTORS,
     useClass:AuthInterceptor,
     multi:true
    }],
  bootstrap: [AppComponent],
  entryComponents: [ StudentModalComponent,ConfirmationDialogComponent,SaleorderviewmodelComponent,InvoiceviewmodelComponent,PaymentviewmodelComponent ]
})
export class AppModule { }
