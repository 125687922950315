import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


@Component({
  selector: 'app-idcardtype',
  templateUrl: './idcardtype.component.html',
  styleUrls: ['./idcardtype.component.css']
})
export class IdCardTypeComponent implements OnInit {

  Submitted: boolean = false;
  IDCardList: any;
  
  constructor(private MasterService: MasterService, private globalHelper: GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  IDCardModel = {
   
    IDCardTypeID: 0,
    IDCardType: '',
    IsActive: true
           
  };

  GetIDCardType = function () {
    this.spinnerService.show();
    this.MasterService.GetIDCardType().subscribe((response: any) => {
      this.IDCardList = response.IDCardList;
      this.spinnerService.hide();
    });
  };
  

  Clear() {
    this.IDCardModel.IDCardTypeID = 0;
    this.IDCardModel.IDCardType = '';   
    this.IDCardModel.IsActive = true;
    this.Submitted = false;
  };

 


  SaveIDCardType(valid) {
    this.Submitted = true;
    if (valid) {
      this.spinnerService.show();
      this.MasterService.SaveIDCardType(JSON.stringify(this.IDCardModel)).subscribe((data: any) => {
        if (data.webResponse.Status) {
          this.GetIDCardType();
        }
        if (data.webResponse.Status && this.IDCardModel.IDCardTypeID == 0) {
          this.Clear();
          this.GetIDCardType();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
  };

  IDCardMarkActive(item) {
    this.IDCardModel.IDCardTypeID = item.IDCardTypeID;
    this.IDCardModel.IsActive = item.IsActive == true ? false : true;
    this.spinnerService.show();
    this.MasterService.IDCardMarkActive(JSON.stringify(this.IDCardModel)).subscribe((data: any) => {
      if (data.webResponse.Status) {
        this.Clear();
        this.GetIDCardType();
      }
      this.globalHelper.CheckActionStatus(data);
      this.spinnerService.hide();
    });

  };

 

  Edit(obj) {

    this.IDCardModel.IDCardTypeID = obj.IDCardTypeID;
    this.IDCardModel.IDCardType = obj.IDCardType;   
    this.IDCardModel.IsActive = obj.IsActive;


  };


  // Init and Lookup

  ngOnInit() {
    this.GetIDCardType();
  }

 

}