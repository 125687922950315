import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-classsectionmap',
  templateUrl: './classsectionmap.component.html',
  styleUrls: ['./classsectionmap.component.css']
})
export class ClassSectionMapComponent implements OnInit {


  Submitted: boolean = false;
  ClassSectionMapList: any;
  ClassList: any;

  constructor(private MasterService: MasterService, private LookupService: LookupService, private globalHelper: GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  ClassModel = {
    ClassID: 0,
  };

  GetClassSectionMap = function () {
    this.ClassSectionMapList = [];
    this.spinnerService.show();
    this.MasterService.GetClassSectionMap(this.ClassModel.ClassID).subscribe((response: any) => {
      this.ClassSectionMapList = response.ClassSectionMapList;
      this.spinnerService.hide();
    });
  };

  ValidDropDown(){
    return this.ClassModel.ClassID==0;
  }

  SaveClassSectionMap(valid) {
    this.Submitted = true;
    if (valid && !this.ValidDropDown()) {
      this.spinnerService.show();
     this.MasterService.SaveClassSectionMap(JSON.stringify(this.ClassSectionMapList), this.ClassModel.ClassID).subscribe((data: any) => {
        if (data.webResponse.Status) {
          this.GetClassSectionMap();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
  };


  ngOnInit() {
    this.GetClass();
  }

  GetClass() {

    this.LookupService.GetLookupsList('Class', '').subscribe((data: any) => {
      this.ClassList = data.LookupItems;
    });

  }
}
