import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-specialcategory',
  templateUrl: './specialcategory.component.html',
  styleUrls: ['./specialcategory.component.css']
})
export class SpecialCategoryComponent implements OnInit {

  Submitted: boolean = false;
  SpecialCategoryList: any;

  constructor(private MasterService: MasterService, private globalHelper: GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  SpecialCategoryModel = {
    SpecialCategoryID: 0,
    SpecialCategoryName: '',
    IsActive: true
  };

  Clear() {
    this.SpecialCategoryModel.SpecialCategoryID = 0;
    this.SpecialCategoryModel.SpecialCategoryName = '';
    this.SpecialCategoryModel.IsActive = true;
    this.Submitted = false;
  };

  GetSpecialCategory = function () {
    this.spinnerService.show();
    this.MasterService.GetSpecialCategory(JSON.stringify(this.SpecialCategoryModel)).subscribe((response: any) => {
      this.SpecialCategoryList = response.SpecialCategoryList;
      this.spinnerService.hide();
    });
  };


  SaveSpecialCategory(valid) {
    this.Submitted = true;
    if (valid) {
      this.spinnerService.show();
      this.MasterService.SaveSpecialCategory(JSON.stringify(this.SpecialCategoryModel)).subscribe((data: any) => {
        if (data.webResponse.Status) {        
          this.GetSpecialCategory();
        }
        if (data.webResponse.Status && this.SpecialCategoryModel.SpecialCategoryID==0) {
          this.Clear();
          this.GetSpecialCategory();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
  };

  SpecialCategoryMarkActive(item) {
    this.SpecialCategoryModel.SpecialCategoryID = item.SpecialCategoryID;
    this.SpecialCategoryModel.IsActive = item.IsActive == true ? false : true;
    this.spinnerService.show();
    this.MasterService.SpecialCategoryMarkActive(JSON.stringify(this.SpecialCategoryModel)).subscribe((data: any) => {
      if (data.webResponse.Status) {
        this.Clear();
        this.GetSpecialCategory();
      }
      this.globalHelper.CheckActionStatus(data);
      this.spinnerService.hide();
    });

  };


  Edit(obj) {

    this.SpecialCategoryModel.SpecialCategoryID = obj.SpecialCategoryID;
    this.SpecialCategoryModel.SpecialCategoryName = obj.SpecialCategoryName;
    this.SpecialCategoryModel.IsActive = obj.IsActive;


  };


  // Init and Lookup

  ngOnInit() {
    this.GetSpecialCategory();
  }


}

