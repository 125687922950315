import { Injectable } from '@angular/core';
import { GlobalHelper } from '../Common/global.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor( private globalHelper:GlobalHelper, private httpclient: HttpClient) { }
  GetUserRight():Observable<any>{   
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded' });
   return this.httpclient.post<any>(this.globalHelper.ApiUrl+'Layout/GetUserRight',{headers:reqHeader});
 }
}
