import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TransportService } from 'src/app/services/Transport/transport.service';
import * as moment from 'moment';

@Component({
  selector: 'app-fee-consolidated-report',
  templateUrl: './fee-consolidated-report.component.html',
  styleUrls: ['./fee-consolidated-report.component.css']
})

export class FeeConsolidatedReportComponent implements OnInit {
  CurrentDate = moment(new Date()).format('MM/DD/YYYY');
        SearchModel = {
            StudentID: 0,
            SectionID: 0,
            ClassID: 0,
            StudentNo: '',
            StartDate:  moment(new Date()).format('DD-MMM-YYYY'),
            EndDate:  moment(new Date()).format('DD-MMM-YYYY'),
            FeeFromDate: '',
            FeeToDate: '',
            OrderBy: 'PaymentDate',
            Order: 'DESC',
            TransactionType: "",
        };
        SectionList:any;
        SectionFilterList:any;
        SummaryModel = {
          TotalAmount: 0,
          CancelAmount: 0,
          PendingAmount: 0,
          CollectionAmount: 0,
          TotalOnline: 0,
          TotalPaytm:0,
          TotalCheque: 0,
          TotalCash: 0,
          TotalTransfer:0,
          TotalCard:0,
          TotalBookStore:0,
          TotalGeneralPayment:0,
          TotalFee:0,
      }
      StudentList:any;
      StudentFilterList:any;
     
        FilterModel = {
          StudentID: 0,
          SectionID: 0,
          ClassID: 0,
          StudentNo: '',
          StartDate: moment(new Date()).format('DD-MMM-YYYY'),
          EndDate: moment(new Date()).format('DD-MMM-YYYY'),
          OrderBy: 'PaymentDate',
          Order: 'DESC',
      };
      CancelModel = {
        ReceiptNo: '',
        TransactionType: '',
        CancelRemarks: '',
    }
    SelectedRowID:any;
    PaymentSummaryList:any;
    PaymentReceiptList:any;
    TotalSummaryAmount=0;
    StudentReceipt:any;
    SummaryDisplayModel={
      GRNo:"",
      FirstName:"",
      ClassName:"",
      SectionName:"",
      TotalAmount:"",
      PaymentDate:"",
    }
    ClassList:any;
  constructor(private transportService:TransportService,private spinnerService: Ng4LoadingSpinnerService,private financeService: FinanceService,private lookupService:LookupService,public globalHelper :GlobalHelper) { }
  ClearSearch  () {
    this.SearchModel.StudentID = 0;
    this.SearchModel.SectionID = 0;
    this.SearchModel.ClassID = 0;
    this.SearchModel.TransactionType = "";
    this.SearchModel.StudentNo = '';
    this.SearchModel.StartDate = moment(new Date()).format('DD-MMM-YYYY');
    this.SearchModel.EndDate = moment(new Date()).format('DD-MMM-YYYY');
    this.SearchModel.FeeFromDate = '';
    this.SearchModel.FeeToDate = '';
    
    this.ClearList();
}
ClearFilter  () {
  this.SelectedRowID = '';
  this.FilterModel.StudentID = 0;
  this.FilterModel.SectionID = 0;
  this.FilterModel.ClassID = 0;
  this.FilterModel.StudentNo = '';
  this.FilterModel.StartDate = moment(new Date()).format('DD-MMM-YYYY');
  this.FilterModel.EndDate = moment(new Date()).format('DD-MMM-YYYY');
  
  this.ClearFilterList();
}
ClearFilterList  () {
  this.PaymentSummaryList = [];
  this.GetConsolidatedSummary();
}


GetReceiptNo  (item) {
  this.CancelModel.ReceiptNo = item.ReceiptNo;
  this.CancelModel.TransactionType = item.TransactionType;

}
ClearList  () {
  this.PaymentReceiptList = [];
  this.SummaryModel.TotalAmount = 0;
  this.SummaryModel.CancelAmount = 0;
  this.SummaryModel.CollectionAmount = 0;
  this.GetConsolidatedReport();
}
 ValidityState() {
  return this.SearchModel.StartDate == '' || this.SearchModel.EndDate == '';
}
 ValidityFilterState() {
  return this.FilterModel.StartDate == '' || this.FilterModel.EndDate == '';
}
SubmittedFilter=false;
GetConsolidatedReport  () {
 
  if (!this.ValidityState()) {
     this.spinnerService.show();
     this.SubmittedFilter=true;
     this.SearchModel.StartDate=moment(this.SearchModel.StartDate).format('DD-MMM-YYYY');
     this.SearchModel.EndDate=moment(this.SearchModel.EndDate).format('DD-MMM-YYYY');
    
      this.financeService.GetConsolidatedReport(JSON.stringify(this.SearchModel)).subscribe( (data)=> {
          this.PaymentReceiptList = data.PaymentReceiptList;
          this.SubmittedFilter=false;
          var TotalAmount = 0;
          var CancelAmount = 0;
          var PendingAmount = 0;
          var CollectionAmount = 0;
          var TotalCash = 0;
          var TotalCheque = 0;
          var TotalTransfer = 0;
          var TotalCard = 0;
          var TotalOnline = 0;
          var TotalPaytm = 0;
          var TotalFee = 0;
          var TotalGeneralPayment = 0;
          var TotalBookStore = 0;
          if (this.PaymentReceiptList != null) {
              for (var i = 0; i < this.PaymentReceiptList.length; i++) {
                  TotalAmount = TotalAmount + parseFloat(this.PaymentReceiptList[i].PaidAmount);
                  //if (this.PaymentReceiptList[i].Cancelled == 'Y' && this.PaymentReceiptList[i].PaymentStatus == 'Y') {
                  //    CancelAmount = CancelAmount + parseFloat(this.PaymentReceiptList[i].PaidAmount);
                  //}
                  if (this.PaymentReceiptList[i].Cancelled == 'N' && this.PaymentReceiptList[i].PaymentStatus == 'Y' && this.PaymentReceiptList[i].TransactionType == 'Fee') {
                      TotalFee = TotalFee + parseFloat(this.PaymentReceiptList[i].PaidAmount);
                  }
                  if (this.PaymentReceiptList[i].Cancelled == 'N' && this.PaymentReceiptList[i].PaymentStatus == 'Y' && this.PaymentReceiptList[i].TransactionType == 'General Payment') {
                      TotalGeneralPayment = TotalGeneralPayment + parseFloat(this.PaymentReceiptList[i].PaidAmount);
                  }
                  if (this.PaymentReceiptList[i].Cancelled == 'N' && this.PaymentReceiptList[i].PaymentStatus == 'Y' && this.PaymentReceiptList[i].TransactionType == 'Book Store') {
                      TotalBookStore = TotalBookStore + parseFloat(this.PaymentReceiptList[i].PaidAmount);
                  }
                  if (this.PaymentReceiptList[i].Cancelled == 'Y') {
                      CancelAmount = CancelAmount + parseFloat(this.PaymentReceiptList[i].PaidAmount);
                  }
                  if (this.PaymentReceiptList[i].Cancelled == 'N' && this.PaymentReceiptList[i].PaymentStatus == 'N') {
                      PendingAmount = PendingAmount + parseFloat(this.PaymentReceiptList[i].PaidAmount);
                  }
                  if (this.PaymentReceiptList[i].PaymentModeID == 1 && this.PaymentReceiptList[i].Cancelled == 'N' &&  this.PaymentReceiptList[i].PaymentStatus == 'Y') {
                      TotalCash = TotalCash + parseFloat(this.PaymentReceiptList[i].PaidAmount);
                  }

                  if (this.PaymentReceiptList[i].PaymentModeID == 2 && this.PaymentReceiptList[i].Cancelled == 'N' && this.PaymentReceiptList[i].PaymentStatus == 'Y') {
                      TotalCheque = TotalCheque + parseFloat(this.PaymentReceiptList[i].PaidAmount);
                  }
                  if (this.PaymentReceiptList[i].PaymentModeID == 3 && this.PaymentReceiptList[i].Cancelled == 'N' && this.PaymentReceiptList[i].PaymentStatus == 'Y') {
                      TotalTransfer = TotalTransfer + parseFloat(this.PaymentReceiptList[i].PaidAmount);
                  }
                  if (this.PaymentReceiptList[i].PaymentModeID == 4 && this.PaymentReceiptList[i].Cancelled == 'N' && this.PaymentReceiptList[i].PaymentStatus == 'Y') {
                      TotalCard = TotalCard + parseFloat(this.PaymentReceiptList[i].PaidAmount);
                  }
                  if (this.PaymentReceiptList[i].PaymentModeID == 5 && this.PaymentReceiptList[i].Cancelled == 'N' && this.PaymentReceiptList[i].PaymentStatus == 'Y') {
                      TotalOnline = TotalOnline + parseFloat(this.PaymentReceiptList[i].PaidAmount);
                  }
                  if (this.PaymentReceiptList[i].PaymentModeID == 6 && this.PaymentReceiptList[i].Cancelled == 'N' && this.PaymentReceiptList[i].PaymentStatus == 'Y') {
                    TotalPaytm = TotalPaytm + parseFloat(this.PaymentReceiptList[i].PaidAmount);
                }
              }
              this.SummaryModel.TotalAmount = this.globalHelper.Decimal(TotalAmount);
              this.SummaryModel.CancelAmount = this.globalHelper.Decimal(CancelAmount);
              this.SummaryModel.PendingAmount = this.globalHelper.Decimal(PendingAmount);
              this.SummaryModel.CollectionAmount = this.globalHelper.Decimal(TotalAmount - CancelAmount - PendingAmount);
              this.SummaryModel.TotalCash = this.globalHelper.Decimal(TotalCash);
              this.SummaryModel.TotalCheque = this.globalHelper.Decimal(TotalCheque);
              this.SummaryModel.TotalTransfer = this.globalHelper.Decimal(TotalTransfer);
              this.SummaryModel.TotalCard = this.globalHelper.Decimal(TotalCard);
              this.SummaryModel.TotalOnline = this.globalHelper.Decimal(TotalOnline);
              this.SummaryModel.TotalPaytm = this.globalHelper.Decimal(TotalPaytm);
              this.SummaryModel.TotalFee = this.globalHelper.Decimal(TotalFee);
              this.SummaryModel.TotalBookStore = this.globalHelper.Decimal(TotalBookStore);
              this.SummaryModel.TotalGeneralPayment = this.globalHelper.Decimal(TotalGeneralPayment);
          }
          this.spinnerService.hide()
      });
  }
}


GetConsolidatedSummary  () {
 
  this.SelectedRowID = '';
  if (!this.ValidityFilterState()) {
    this.spinnerService.show();
    this.FilterModel.StartDate=moment(this.FilterModel.StartDate).format('DD-MMM-YYYY');
    this.FilterModel.EndDate=moment(this.FilterModel.EndDate).format('DD-MMM-YYYY');
      this.financeService.GetConsolidatedSummary(JSON.stringify(this.FilterModel)).subscribe( (data)=> {
          this.PaymentSummaryList = data.PaymentSummaryList;
        
          var TotalSummaryAmount = 0;

          if (this.PaymentSummaryList != null) {
              for (var i = 0; i < this.PaymentSummaryList.length; i++) {
                  TotalSummaryAmount = TotalSummaryAmount + parseFloat(this.PaymentSummaryList[i].TotalAmount);

              }
              this.TotalSummaryAmount = TotalSummaryAmount;

          }
         
          this.spinnerService.hide();
      });
  }
  
}
CancelConsolidatedReceipt  () {
 this.spinnerService.show();
  this.financeService.CancelConsolidatedReceipt(JSON.stringify(this.CancelModel)).subscribe( (response)=> {
      this.globalHelper.CheckActionStatus(response);
    this.GetConsolidatedReport();
    this.spinnerService.hide()

  });
}
GetStudentReceipt  (ID,item) {
  this.StudentReceipt = [];
  this.SelectedRowID = ID;
  this.SummaryDisplayModel.GRNo = item.GRNo;
  this.SummaryDisplayModel.FirstName = item.FirstName;
  this.SummaryDisplayModel.ClassName = item.ClassName;
  this.SummaryDisplayModel.SectionName = item.SectionName;
  this.SummaryDisplayModel.TotalAmount = item.TotalAmount;
  this.SummaryDisplayModel.PaymentDate = item.PaymentDate;
  this.StudentReceipt = item.ChildList;


}
PrintFeeReceipt  (ReceiptNo, TransType, PrintType, CopyType, Cancel) {
  var Template = this.globalHelper.GetLoginToken().FeeTemplate;
  if (Cancel == 'Y') {
      let CopyType = 'C';
  }
  if (TransType == 'Fee') {
    var Template = this.globalHelper.GetLoginToken().FeeTemplate;

      //var url = "../../../../App/Template/FeeReceipt/" + Template + "?PrintType=FeeReceipt&ReceiptNo=" + ReceiptNo + "&PrintType=" + PrintType + "&CopyType=" + CopyType;
      var url = Template + "?PrintTypes=FeeReceipt&ReceiptNo=" + ReceiptNo + "&PrintType=" + PrintType + "&CopyType=" + CopyType;
      this.globalHelper.OpenPopUpWindow(url, "yes", "950", "1000");
  }
 
  else if (TransType == 'General Payment') {
      var url = "generalpaymentprint?PrintTypes=GeneralPaymentReceipt&ReceiptNo=" + ReceiptNo + "&PrintType=" + PrintType + "&CopyType=" + CopyType;
  }
  else if (TransType == 'Book Store') {
      var url = "printitemissue?PrintTypes=Receipt&ReceiptNo=" + ReceiptNo + "&PrintType=" + PrintType + "&CopyType=" + CopyType;
  }
  else if (TransType == 'Library Fine') {
      var url = "bookfinereceiptprint?PrintTypes=FineReceipt&ReceiptNo=" + ReceiptNo + "&PrintType=" + PrintType + "&CopyType=" + CopyType;
  }

  this.globalHelper.OpenPopUpWindow(url, "yes", "950", "1000");
};
GetClass  () {
  this.lookupService.GetLookupsList('Class', '').subscribe( (data)=> {
      this.ClassList = data.LookupItems;
  });

};

GetStudent  () {
  this.SearchModel.StudentID = 0;
  var itembo = {
      ItemId: this.SearchModel.ClassID,
      ItemId2: this.SearchModel.SectionID
  }
  this.lookupService.GetLookupsList('StudentList', JSON.stringify(itembo)).subscribe( (data)=> {
      this.StudentList = data.LookupItems;
      this.ClearList();

  });
};
GetStudentFilter  () {
  this.FilterModel.StudentID = 0;
  var itembo = {
      ItemId: this.FilterModel.ClassID,
      ItemId2: this.FilterModel.SectionID
  }
  this.lookupService.GetLookupsList('Student', JSON.stringify(itembo)).subscribe( (data)=> {
      this.StudentFilterList = data.LookupItems;


  });
};
GetSection  () {
  this.StudentList = [];
  this.SearchModel.SectionID = 0;
  this.SearchModel.StudentID = 0;
  var ItemBo = {
      'ItemId': this.SearchModel.ClassID
  }
  this.lookupService.GetLookupsList('SectionName', JSON.stringify(ItemBo)).subscribe( (data)=> {
      this.SectionList = data.LookupItems;
      this.ClearList();

  });
};

GetSectionFilter  () {
  this.StudentFilterList = [];
  this.FilterModel.SectionID = 0;
  this.FilterModel.StudentID = 0;
  var ItemBo = {
      'ItemId': this.FilterModel.ClassID
  }
  this.lookupService.GetLookupsList('SectionName', JSON.stringify(ItemBo)).subscribe( (data)=> {
      this.SectionFilterList = data.LookupItems;


  });
};
XLSReport  () {
  if (this.PaymentReceiptList != null) {
      this.JSONToCsvFeeConsolidatedList(this.PaymentReceiptList, 'FeeConsolidated_List', true);
  }
 
}

 JSONToCsvFeeConsolidatedList(JSONData, ReportTitle, ShowLabel) {
  var arrayData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
  var CSV = '';
  if (ShowLabel) {
      var row = "";
      row += 'Sr. No.' + ',';
      row += 'Receipt No.' + ',';
      row += 'Date' + ',';
      row += 'Transaction Type' + ',';
      row += 'Payment Mode' + ',';
      row += 'GR.No.' + ',';
      row += 'Name' + ',';
      row += 'Class' + ',';
      row += 'Section' + ',';
      row += 'Amount' + ',';
      row += 'Status' + ',';
      row += 'Remarks' + ',';
      row += 'Created By' + ',';
      row += 'Created On' + ',';
      row = row.slice(0, -1);
      CSV += row + '\r\n';
  }
  for (var i = 0; i < arrayData.length; i++) {
      var sno = i + 1;
      var row = "";
      row += '"' + sno + '",';
      row += '"' + arrayData[i].ReceiptNo + '",';
      row += '"' + moment(arrayData[i].PaymentDate).format('DD-MMM-YYYY') + '",';
      row += '"' + arrayData[i].TransactionType + '",';
      row += '"' + arrayData[i].PaymentMode + '",';
      row += '"' + arrayData[i].GRNo + '",';
      row += '"' + arrayData[i].FirstName + '",';
      row += '"' + arrayData[i].ClassName + '",';
      row += '"' + arrayData[i].SectionName + '",';
      row += '"' + arrayData[i].PaidAmount + '",';
      var Cancel = 'Paid';
      if (arrayData[i].Cancelled == 'Y') {
          Cancel = 'Cancelled';
      }
      row += '"' + Cancel + '",';
      row += '"' + arrayData[i].Remarks + '",';
      row += '"' + arrayData[i].CreatedBy + '",';
      row += '"' +  moment(arrayData[i].CreatedOn).format('DD-MMM-YYYY') + '",';
      row.slice(0, row.length - 1);
      CSV += row + '\r\n';
  }
  if (CSV == '') {
      alert('Invalid Data');
      return;
  }
  var FileName = ReportTitle + '_' + moment(new Date()).format('DD-MMM-YYYY');

  var csvData = new Blob([CSV], { type: 'text/csv;charset=utf-8;' });
  var csvURL = window.URL.createObjectURL(csvData);
  var tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', FileName + '.csv');
  tempLink.click();
}

XLSSummaryReport  () {
  if (this.PaymentSummaryList != null) {
      this.JSONToCsvFeeConsolidatedSumList(this.PaymentSummaryList, 'FeeConsolidated_Summary_List', true);
  }
}

 JSONToCsvFeeConsolidatedSumList(JSONData, ReportTitle, ShowLabel) {
  var arrayData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
  var CSV = '';
  if (ShowLabel) {
      var row = "";
      row += 'Sr. No.' + ',';
      row += 'GR No.' + ',';
      row += 'Date' + ',';
      row += 'Name' + ',';
      row += 'Class' + ',';
      row += 'Section' + ',';
      row += 'Amount' + ',';
      row = row.slice(0, -1);
      CSV += row + '\r\n';
  }
  for (var i = 0; i < arrayData.length; i++) {
      var sno = i + 1;
      var row = "";
      row += '"' + sno + '",';
      row += '"' + arrayData[i].GRNo + '",';
      row += '"' + moment(arrayData[i].PaymentDate).format('DD-MMM-YYYY') + '",';
      row += '"' + arrayData[i].FirstName + '",';
      row += '"' + arrayData[i].ClassName + '",';
      row += '"' + arrayData[i].SectionName + '",';
      row += '"' + arrayData[i].TotalAmount + '",';
      row.slice(0, row.length - 1);
      CSV += row + '\r\n';
  }
  if (CSV == '') {
      alert('Invalid Data');
      return;
  }
  var FileName = ReportTitle + '_' +moment(new Date()).format('DD-MMM-YYYY') ;

  var csvData = new Blob([CSV], { type: 'text/csv;charset=utf-8;' });
  var csvURL = window.URL.createObjectURL(csvData);
  var tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', FileName + '.csv');
  tempLink.click();
}
  ngOnInit() {
    this.GetClass();
            //this.GetConsolidatedReport();
           // this.GetConsolidatedSummary();
  }

}
