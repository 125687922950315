import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastConfig, Toaster, ToastType } from "ngx-toast-notifications";

@Injectable({
    providedIn: 'root'
})

export class GlobalHelper {

    // local host

//    ApiUrl = 'http://localhost:4466/'; 

   //WebZoomUrl = 'http://localhost:8080/zoomjoin/';  
   //apiZoomUrl="http://localhost:54740/api/"
     
   MobileApiUrl="https://mapi.josacloud.com/"
  
   // Test 

    // ApiUrl='https://josaapitesting.josacloud.com/';
    //WebZoomUrl = 'https://josatesting.josacloud.com/'; 
   
   
    // live

     ApiUrl='https://josaapi.josacloud.com/';     
     apiZoomUrl="https://meetapi.josacloud.com/api/"
     WebZoomUrl = 'https://meet.josacloud.com/'; 

     ReceiptNo:"";
     RefernceNo:"";
  
    App:string='';
    SiteMap: any;
    
    LoginToken = {
        PartnerName:"",
        PartnerLogoPath:"",
        SessionCount:0,
        MerchantID:"",
        WorkingKey:'',
        AccessCode:'',
        ResponseURL:"",
        IsPG:false,
        PGUrl:"",
        UserTypeID: 0,
        PersonPhotoPath:"",
        LoginId: "",
        UserName: "",
        UserLoginId: 0,
        BranchID: 0,
        BranchName: "",
        FirstName: "",
        FirstLogin: false,
        DecimalPlace: 0,
        CurrencyDecimal: "",
        CurrencyInteger: "",
        SessionID: 0,
        Session:"",
        FeeTemplate: "",
        SessionEndDate: "",
        SessionStartDate: "",
        FeeReminderTemplate: "",
        TCTemplate: "",
        ChallanTemplate:"",
        FeeBookTemplate:"",
        TransportTemplate: "",
        IsSMSPackage: false,
        CurrencyCode: "",
        IsSubBranch:false,
        StaffID:0,
        PartnerTypeID:0,
        SchoolID:0,
        PartnerID:0,
        CountryID:0,
        SchoolLogoPath:'',
        Salt:'',
        PaymentGatewayType:'',
        IsAllowPaymentDateChange:false,
        IsFeeChallan:false,
        IsStream:false,
    };
  
    placeValue: any;
    SessionTemplate: any;
    n: any;

    DateFormat:'DD-MMM-YYYY';
    constructor(private toaster: Toaster) {

    }

    SetUserToken(UserToken: any) {
        window.localStorage.setItem("UserToken", UserToken);
    }

    GetUserToken() {
        return window.localStorage.getItem("UserToken");
    }

    SetToken(Token: any) {
        window.localStorage.setItem("LoginToken", Token);
    }

    GetToken() {
        return window.localStorage.getItem("LoginToken") == null ? null : JSON.parse(window.localStorage.getItem("LoginToken"));
    }

    SetSessionTemplate(SessionTemplate: any) {
        window.localStorage.setItem("SessionTemplate", JSON.stringify(SessionTemplate));
    }

    GetSessionTemplate() {
        return window.localStorage.getItem("SessionTemplate") == null ? null : JSON.parse(window.localStorage.getItem("SessionTemplate"));
    }




    GetLoginToken() {

        let token = this.GetToken();
        let SessionTemplate = this.GetSessionTemplate();
        this.LoginToken.SessionCount = token.SessionCount;
        this.LoginToken.PartnerName = token.PartnerName;
        this.LoginToken.PartnerLogoPath = token.PartnerLogoPath;
        this.LoginToken.UserTypeID = token.UserTypeID;
        this.LoginToken.LoginId = token.LoginId;
        this.LoginToken.UserName = token.UserName;
        this.LoginToken.UserLoginId = token.UserLoginId;
        this.LoginToken.PersonPhotoPath = token.PersonPhotoPath;
        this.LoginToken.BranchID = token.BranchID;
        this.LoginToken.BranchName = token.BranchName;
        this.LoginToken.FirstName = token.FirstName;
        this.LoginToken.FirstLogin = token.FirstLogin;
        this.LoginToken.DecimalPlace = token.DecimalPlace;
        this.LoginToken.CurrencyDecimal = token.CurrencyDecimal;
        this.LoginToken.CurrencyInteger = token.CurrencyInteger;
        this.LoginToken.IsSMSPackage = token.IsSMSPackage;
        this.LoginToken.CurrencyCode = token.CurrencyCode;       
        this.LoginToken.IsSubBranch = token.IsSubBranch;
        this.LoginToken.StaffID = token.StaffID;
        this.LoginToken.IsPG = token.IsPG;        
        this.LoginToken.PGUrl = token.PGUrl;
        this.LoginToken.PaymentGatewayType = token.PaymentGatewayType;
        this.LoginToken.MerchantID = token.MerchantID;
        this.LoginToken.AccessCode = token.AccessCode;
        this.LoginToken.WorkingKey = token.WorkingKey;
        this.LoginToken.ResponseURL = token.ResponseURL;
        this.LoginToken.PartnerTypeID = token.PartnerTypeID;
        this.LoginToken.SchoolID = token.SchoolID;
        this.LoginToken.PartnerID = token.PartnerID;
        this.LoginToken.CountryID = token.CountryID;
        this.LoginToken.SchoolLogoPath = token.SchoolLogoPath;
        this.LoginToken.IsAllowPaymentDateChange = token.IsAllowPaymentDateChange;
        this.LoginToken.IsFeeChallan = token.IsFeeChallan;
        this.LoginToken.IsStream = token.IsStream;
        this.LoginToken.Salt = token.Salt;
        if (SessionTemplate != null) {
            this.LoginToken.SessionID = SessionTemplate.SessionID;
            this.LoginToken.Session = SessionTemplate.Session;
            this.LoginToken.FeeTemplate = SessionTemplate.FeeTemplate;
            this.LoginToken.SessionEndDate = SessionTemplate.SessionEndDate;
            this.LoginToken.SessionStartDate = SessionTemplate.SessionStartDate;
            this.LoginToken.FeeReminderTemplate = SessionTemplate.FeeReminderTemplate;
            this.LoginToken.TCTemplate = SessionTemplate.TCTemplate;
            this.LoginToken.ChallanTemplate = SessionTemplate.ChallanTemplate;
            this.LoginToken.FeeBookTemplate = SessionTemplate.FeeBookTemplate;
        }

        return this.LoginToken;
    }

    OpenPopUpWindow(url, scrl, wdth, higt) {
        var leftVal = 50;
        var topVal = 163;
        window.open(url, scrl, 'location=no' + ',' + 'directories=no' + ',' + 'toolbar=no' + ',' + 'width=' + wdth + ',' + 'height=' + higt + ',' + 'left=' + leftVal + ',' + 'top=' + topVal + ',' + 'scrollbars=' + scrl + ',' + 'resizable=no');
    }

    
    toWords(s) {
      
        if (s != 'undefined' && s != '' && s != null) {
            
            var th = ['', 'Thousand ', 'million', 'billion', 'trillion'];
            var dg = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
            var tn = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
            var tw = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

            s = s.toString();
            s = s.replace(/[\, ]/g, '');
            if (s != (s)) return 'not a number';
            var x: number = s.indexOf('.');
           
            if (x == -1) x = s.length;
            if (x > 15) return 'too big';
            var n = s.split('');
            var str = '';
            var sk = 0;
            
            for (var i = 0; i < x; i++) {
                if ((x - i) % 3 == 2) {
                    if (n[i] == '1') {
                        str += tn[Number(n[i + 1])] + ' ';
                        i++;
                        sk = 1;
                    }
                    else if (n[i] != 0) {
                        str += tw[n[i] - 2] + ' ';
                        sk = 1;
                    }
                }
                else if (n[i] != 0) {
                    str += dg[n[i]] + ' ';
                    if ((x - i) % 3 == 0) str += 'Hundred ';
                    sk = 1;
                }


                if ((x - i) % 3 == 1) {
                    if (sk) str += th[(x - i - 1) / 3] + '';
                    sk = 0;
                }
            }
            if (x != s.length) {
                var y: number = s.length;
                str += 'point ';
                for (var i = x + 1; i < y; i++) str += dg[n[i]] + ' ';
            }
           
            return str.replace(/\s+/g, ' ');
        }
    }
    CheckActionStatus(response) {
        const type = response.webResponse.Type.toLowerCase();
        if(type!=null && type!='list'&& type!='')
        {
        this.toaster.open({
            text: response.webResponse.Message,
            caption: type + ' notification',
            type: type,
        });
    }
    }


    Decimal(newValue) {
        if (newValue == 'undefined' || newValue == '' || newValue == null)
            this.n = '';
        if (this.LoginToken.DecimalPlace > 0)
            this.placeValue = this.LoginToken.DecimalPlace;
        else
            this.placeValue = 2;
        var n = String(newValue).split(".");
        if (n[1]) {
            var n2 = n[1];
            for (var j = 0; j < this.placeValue; j++) {
                if (n2.length == this.placeValue) {
                    n2 = n2;
                    break;
                }
                else {
                    n2 = n2 + '0';
                }
            }
            n2 = n2.substr(0, this.placeValue);
            newValue = [n[0], n2].join(".");
        }

        else {
            var n1 = n[0] == 'undefined' || n[0] == '' || n[0] == null ? 0 : n[0];
            var Zeros = '';
            for (var i = 0; i < this.placeValue; i++) {
                Zeros = Zeros + '0';
            }
            newValue = [n1, Zeros].join(".");
        }

        return newValue;
    };


    requiredPattern=/^[A-Za-z0-9]{1}[A-Za-z0-9_ ]*$/;

    emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

    CharCount(value) {
        return value.length;
    }

    
    SmsCount(value) {
        if (value.length == 0)
            return 0;
        else
            return Math.ceil(value.length / 160)
    }  
    
     GetDate(str) {
  
        if (str != null) {
            var currentTime = new Date(str);
            var month = currentTime.getMonth() + 1;
            var day = currentTime.getDate();
            var year = currentTime.getFullYear();
            var date = (month < 10 ? "0" + month : month) + "/" + (day < 10 ? "0" + day : day) + "/" + year;
            
            return date;
        }
        return "";
    };

    LimitTo(value,args){       
        let limit = args ? parseInt(args, 10) : 10;
        let trail = '...';    
        return value.length > limit ? value.substring(0, limit) + trail : value;
    }
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
        return true;
    }
    alphaWithoutBlank(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode==32) {
          return false;
        }
        return true;
    }

    
}