import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as moment from 'moment';

@Component({
  selector: 'app-monthly-collection',
  templateUrl: './monthly-collection.component.html',
  styleUrls: ['./monthly-collection.component.css']
})
export class MonthlyCollectionComponent implements OnInit {
 
  MonthlyCollectionReportsList:any=[];
  FeeComponentList:any=[];
  lstTotalCollection:any=[];
  lstBankCollection:any=[];
  lstBankSummaryCollection:any=[];
  ClassList:any=[];
  SectionList:any=[];
  PaymentModeList:any=[];
  BankList:any=[];
  Bank:any=[];
  SectionSummaryList:any=[];
  SubmittedFilter=false;
  FilePath: any;
  constructor(private spinnerService: Ng4LoadingSpinnerService,private financeService: FinanceService,private lookupService:LookupService,public globalHelper :GlobalHelper) { }
 
  SearchModel = {   
    ClassGroupID:0,
    ClassID: 0,
    SectionID: 0,
    PaymentModeID:0,
    BankID:0,
    BankAccountID:0,
    StudentNo: '',
    Print:'',
    BankName:'',
    RecType:'0',
    FromDate:  moment(new Date()).format('DD-MMM-YYYY'),
    ToDate:  moment(new Date()).format('DD-MMM-YYYY'),   
};

Reset(){
  this.SearchModel.ClassGroupID=0;
  this.SearchModel.ClassID=0;
  this.SearchModel.SectionID=0;
  this.SearchModel.PaymentModeID=0;
  this.SearchModel.BankID=0;
  this.SearchModel.BankAccountID=0;
  this.SearchModel.StudentNo='';
  this.SearchModel.BankName='';
  this.SearchModel.RecType='0';
  this.SearchModel.FromDate=moment(new Date()).format('DD-MMM-YYYY');
  this.SearchModel.ToDate=moment(new Date()).format('DD-MMM-YYYY');
  this.MonthlyCollectionReportsList=[];
  this.lstBankCollection=[];
  this.TotalBankCollection=0;
  this.SubmittedFilter=false;
}
ClearList(){
  this.MonthlyCollectionReportsList=[];
  this.lstBankCollection=[];
  this.TotalBankCollection=0;
 }

ValidityState() {
  return this.SearchModel.FromDate == '' || this.SearchModel.ToDate == '';
}

TotalBankCollection=0;
GetMonthlyCollectionReport  () { 
  if (!this.ValidityState()) {
     this.spinnerService.show();
     this.SubmittedFilter=true;
    var TotalBankCollection=0;
     this.SearchModel.FromDate=moment(this.SearchModel.FromDate).format('DD-MMM-YYYY');
     this.SearchModel.ToDate=moment(this.SearchModel.ToDate).format('DD-MMM-YYYY');
     this.SearchModel.Print = '';
      this.financeService.GetMonthlyCollectionReport(JSON.stringify(this.SearchModel)).subscribe( (data)=> {
          this.MonthlyCollectionReportsList = data.MonthlyCollectionReportsList;       
          this.lstTotalCollection=data.lstTotalCollection;
          this.FeeComponentList = data.FeeComponentList;
          this.lstBankCollection=data.lstBankCollection
          if(this.lstBankCollection!=null && this.lstBankCollection.length>0){
            for(var i=0;i<this.lstBankCollection.length;i++){
              TotalBankCollection=TotalBankCollection+this.lstBankCollection[i].PaidAmount;
            }
            this.TotalBankCollection=TotalBankCollection;
          }
          this.SubmittedFilter=false;       
          this.spinnerService.hide()
      });
  }
}

  ngOnInit() {
    this.GetClass();
    this.BindClassGroup();
    this.GetClassGroupClass();
    this.GetPaymentMode();
    this.BindBank();   
  }

 

GetClass () {
  this.MonthlyCollectionReportsList = [];
  this.SearchModel.ClassID = 0;
  this.SearchModel.SectionID = 0;
  var ItemBo = {
      'ItemId': this.SearchModel.ClassGroupID
  }
  this.lookupService.GetLookupsList('ClassGroupClass', JSON.stringify(ItemBo)).subscribe( (data)=> {
      this.ClassList = data.LookupItems;
       
  });
};
  GetSection  () {
    this.MonthlyCollectionReportsList = [];
    this.SearchModel.SectionID = 0;
    
    var ItemBo = {
        'ItemId': this.SearchModel.ClassID
    }
    this.lookupService.GetLookupsList('SectionName', JSON.stringify(ItemBo)).subscribe( (data)=> {
        this.SectionList = data.LookupItems;
         
    });
  };
   
 SelectBank(){
  for(var i=0;i<this.BankList.length;i++){
  
    if(this.BankList[i].ItemId==this.SearchModel.BankID){
      this.SearchModel.BankName=this.BankList[i].ItemName;
      break;
    }
   else{
    this.SearchModel.BankName='';
   }
  }
 }
  GetMonthlyCollectionReportsDownload() {
      this.SearchModel.FromDate = this.SearchModel.FromDate != null && this.SearchModel.FromDate != "" ? moment(this.SearchModel.FromDate).format('DD-MMM-YYYY') : "";
      this.SearchModel.ToDate = this.SearchModel.ToDate != null && this.SearchModel.ToDate != "" ? moment(this.SearchModel.ToDate).format('DD-MMM-YYYY') : "";

      this.spinnerService.show();     
      this.SearchModel.Print = 'XLS';
     
      this.financeService.GetMonthlyCollectionReport(JSON.stringify(this.SearchModel)).subscribe( (data)=> {
        this.spinnerService.hide();
          window.location = data.FilePath;         

      });
  };


  // Summary

  MonthlyCollectionSummaryReportsList:any=[];
  FeeComponentSummaryList:any=[];
  lstTotalSummaryCollection:any=[];
 
  SearchSummaryModel = {   
    ClassID: 0,
    ClassGroupID:0,
    SectionID: 0,
    PaymentModeID:0,
    BankID:0,
    BankAccountID:0,
    StudentNo: '',
    Print:'',
    BankName:'',
    RecType:'0',
    FromDate:  moment(new Date()).format('DD-MMM-YYYY'),
    ToDate:  moment(new Date()).format('DD-MMM-YYYY'),   
};


ResetSummary(){
  this.SearchSummaryModel.ClassGroupID=0;
  this.SearchSummaryModel.ClassID=0;
  this.SearchSummaryModel.SectionID=0;
  this.SearchSummaryModel.PaymentModeID=0;
  this.SearchSummaryModel.BankID=0;
  this.SearchSummaryModel.BankAccountID=0;
  this.SearchSummaryModel.StudentNo='';
  this.SearchSummaryModel.BankName='';
  this.SearchSummaryModel.RecType='0';
  this.SearchSummaryModel.FromDate=moment(new Date()).format('DD-MMM-YYYY');
  this.SearchSummaryModel.ToDate=moment(new Date()).format('DD-MMM-YYYY');
  this.MonthlyCollectionSummaryReportsList=[];
  this.lstBankSummaryCollection=[];
  this.SubmittedFilter=false;
}
GetPaymentMode() {
  this.lookupService.GetLookupsList('PaymentMode', '').subscribe((data) => {
    this.PaymentModeList = data.LookupItems;

  });
};

BindBank() {
  this.lookupService.GetLookupsList('BankAll', '').subscribe((response) => {
      this.BankList = response.LookupItems;

  });
};

BindBankBranch() {
  this.lookupService.GetLookupsList('Bank', '').subscribe((response) => {
      this.Bank = response.LookupItems;
 });
};

ClearSummaryList(){
  this.MonthlyCollectionReportsList=[];
  this.lstBankSummaryCollection=[];
 }
 
 SelectSummaryBank(){
  for(var i=0;i<this.BankList.length;i++){
  
    if(this.BankList[i].ItemId==this.SearchSummaryModel.BankID){
      this.SearchSummaryModel.BankName=this.BankList[i].ItemName;
      break;
    }
   else{
    this.SearchSummaryModel.BankName='';
   }
  }
 }
 GetSummarySection  () {
  this.MonthlyCollectionSummaryReportsList = [];
  this.SearchSummaryModel.SectionID = 0;
  
  var ItemBo = {
      'ItemId': this.SearchSummaryModel.ClassID
  }
  this.lookupService.GetLookupsList('SectionName', JSON.stringify(ItemBo)).subscribe( (data)=> {
      this.SectionSummaryList = data.LookupItems;
       
  });
};
ClassGroupList=[];
BindClassGroup() {
  this.lookupService.GetLookupsList('ClassGroup', '').subscribe((response) => {
      this.ClassGroupList = response.LookupItems;
 });
};
ClassSummaryList=[];
GetClassGroupClass  () {
  this.MonthlyCollectionSummaryReportsList = [];
  this.SearchSummaryModel.ClassID = 0;
  this.SearchSummaryModel.SectionID = 0;
  var ItemBo = {
      'ItemId': this.SearchSummaryModel.ClassGroupID
  }
  this.lookupService.GetLookupsList('ClassGroupClass', JSON.stringify(ItemBo)).subscribe( (data)=> {
      this.ClassSummaryList = data.LookupItems;
       
  });
};
ValiditySummaryState() {
  return this.SearchSummaryModel.FromDate == '' || this.SearchSummaryModel.ToDate == '';
}

TotalBankSummaryCollection=0;
GetMonthlyCollectionSummaryReport  () { 
  if (!this.ValiditySummaryState()) {
     this.spinnerService.show();
     this.SubmittedFilter=true;
     var TotalBankCollection=0;
     this.SearchSummaryModel.FromDate=moment(this.SearchSummaryModel.FromDate).format('DD-MMM-YYYY');
     this.SearchSummaryModel.ToDate=moment(this.SearchSummaryModel.ToDate).format('DD-MMM-YYYY');
     this.SearchSummaryModel.Print = '';
      this.financeService.GetMonthlyCollectionSummaryReport(JSON.stringify(this.SearchSummaryModel)).subscribe( (data)=> {
          this.MonthlyCollectionSummaryReportsList = data.MonthlyCollectionReportsList;
          this.lstTotalSummaryCollection=data.lstTotalCollection;
          this.FeeComponentSummaryList = data.FeeComponentList;
          this.lstBankSummaryCollection=data.lstBankCollection
          if(this.lstBankSummaryCollection!=null && this.lstBankSummaryCollection.length>0){
            for(var i=0;i<this.lstBankSummaryCollection.length;i++){
              TotalBankCollection=TotalBankCollection+this.lstBankSummaryCollection[i].PaidAmount;
            }
            this.TotalBankSummaryCollection=TotalBankCollection;
          }
          this.SubmittedFilter=false;       
          this.spinnerService.hide()
      });
  }
}

GetMonthlyCollectionSummaryReportsDownload() {
  this.SearchSummaryModel.FromDate = this.SearchSummaryModel.FromDate != null && this.SearchSummaryModel.FromDate != "" ? moment(this.SearchSummaryModel.FromDate).format('DD-MMM-YYYY') : "";
  this.SearchSummaryModel.ToDate = this.SearchSummaryModel.ToDate != null && this.SearchSummaryModel.ToDate != "" ? moment(this.SearchSummaryModel.ToDate).format('DD-MMM-YYYY') : "";

  this.spinnerService.show();     
  this.SearchSummaryModel.Print = 'XLS';
 
  this.financeService.GetMonthlyCollectionSummaryReport(JSON.stringify(this.SearchSummaryModel)).subscribe( (data)=> {
    this.spinnerService.hide();
      window.location = data.FilePath;         

  });
};

}
