import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { SalService } from 'src/app/Services/sal/sal.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Component({
  selector: 'app-saleorderviewmodel',
  templateUrl: './saleorderviewmodel.component.html',
  styleUrls: ['./saleorderviewmodel.component.css']
})
export class SaleorderviewmodelComponent implements OnInit {
  @Input() public OrderDetails;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ItemList: any;
  Details: any;
  SumTotalTax: any;
  TotalAmount: any;
  constructor(private activeModal: NgbActiveModal, public globalHelper: GlobalHelper,
    private salservice: SalService, private spinnerService: Ng4LoadingSpinnerService) { }
  OrganizationCountryID: any;
  OrganizationStateID: any;

  DisplayModel = {
    SaleOrderID: 0,
  }
 
  GetSOItem() {
   this.spinnerService.show();
    this.salservice.GetSOItem(JSON.stringify(this.DisplayModel)).subscribe((response: any) => {
      this.ItemList = response.ItemList;
        
      this.SumTotalTax=0;
      this.TotalAmount=0;
      for (let index = 0; index < this.ItemList.length; index++) {
        
        this.SumTotalTax=this.SumTotalTax+this.ItemList[index].TaxAmount;
        this.TotalAmount=this.TotalAmount+this.ItemList[index].Amount*this.ItemList[index].Qty;
      }
      this.spinnerService.hide();
      this.globalHelper.CheckActionStatus(response);
    });
  }
  ngOnInit() {
    this.DisplayModel.SaleOrderID = this.OrderDetails.SaleOrderID;
    this.Details = this.OrderDetails;    
    this.GetSOItem();
  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}
