import { HttpInterceptor,HttpHandler,HttpEvent,HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalHelper } from './services/Common/global.service';
import { CommonService } from './services/Common/common.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor{

  SessionTemplate:any;
    constructor(private router:Router,private globalHelper :GlobalHelper, private commonService:CommonService){}
    intercept(req: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>>
    {

     if(req.headers.get('No-Auth') == "True")
     {    
        return next.handle(req.clone());
     }   
   
     if(this.globalHelper.GetUserToken()!=null){      

      

      this.SessionTemplate=this.globalHelper.GetSessionTemplate()==null?"":JSON.stringify(this.globalHelper.GetSessionTemplate());      
       const clonedReq=req.clone({
          headers:req.headers.set("Authorization","Bearer " +this.globalHelper.GetUserToken()).set("SessionTemplate",this.SessionTemplate).set("JOSAHostName",this.commonService.getHostname())
         });
         return next.handle(clonedReq);
     }
     else{
       
        if(this.globalHelper.App=='PUBLIC')
        {

         const clonedReq=req.clone({
            headers:req.headers.set("JOSAHostName",this.commonService.getHostname())
           });
           return next.handle(clonedReq);
        }
      else
      this.router.navigateByUrl("/login");
     }
    }
}