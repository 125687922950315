
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalHelper } from '../Common/global.service';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  
  constructor( private globalHelper:GlobalHelper, private httpclient: HttpClient) { }
 
  UserAuthentication(UserName,Password):Observable<any>{   
   
    let credentials='username=' +UserName  + '&password=' +Password +'&grant_type=password'; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
   return this.httpclient.post<any>(this.globalHelper.ApiUrl+'token',encodeURI(credentials),{headers:reqHeader});
 }
  
 VerifyMGPCode(MPGCode):Observable<any>{   
let formData = new FormData();
formData.append("MPGCode", MPGCode);
return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Account/VerifyMGPCode', formData);


}


 GetSchoolSiteMap():Observable<any>{   

  
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
 return this.httpclient.post<any>(this.globalHelper.ApiUrl+'CMS/GetSchoolSiteMap',{headers:reqHeader}).pipe(
  retry(1),
  catchError(this.handleError)
);;
}


handleError(error) {
  let errorMessage = '';
  if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
  } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  console.log(errorMessage);
  return throwError(errorMessage);
}

}
