import { Component, OnInit, ElementRef, Inject, Renderer2,Input,
  AfterViewInit, ViewEncapsulation} from '@angular/core';
declare var jQuery: any;
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { GlobalHelper } from '../services/Common/global.service';
import { UserService } from '../services/user/user.service';
import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../services/user/authentication.service';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css',
 
]
  
})
export class AdminComponent implements OnInit  {
  closeResult: string;
  modalOptions:NgbModalOptions;
  canvas: any;
  ctx: any;
  SiteMapList:any;
  LoginToken:any;

  constructor( @Inject(DOCUMENT) private document: Document, private modalService: NgbModal,
  private renderer: Renderer2, private router:Router,public elementRef: ElementRef,
  private authenticationService:AuthenticationService ,
  public globalHelper:GlobalHelper,private userService:UserService) {
    
    
   }
   

   open(content) {
    this.modalService.open(content, this.modalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
 
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
   Logout() {
    window.localStorage.clear();
    this.globalHelper.SiteMap=null;
    this.router.navigate(['/login']);
  }
  GetUserRight(){    
    this.userService.GetUserRight().subscribe((data:any)=>{
       this.globalHelper.SiteMap=  data.BranchRolePermissionList;
     
       
     });
  }
  
  ngOnInit() {

    this.globalHelper.App="";
 
    
   this.LoginToken =this.globalHelper.GetLoginToken();  
   if(this.globalHelper.SiteMap==null ||this.globalHelper.SiteMap=="")
   {
    this.GetUserRight();  
   }
   

    this.renderer.removeClass(this.document.body, 'body2');
    this.renderer.addClass(this.document.body, 'body1');
    
 



//     this.canvas = document.getElementById('chart1');
//     this.ctx = this.canvas.getContext('2d');
//     let myChart1 = new Chart(this.ctx, {
//            type: 'line',
//               data: {
//                 labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
//                 datasets: [{
//                   label: 'New Visitor',
//                   data: [3, 3, 8, 5, 7, 4, 6, 4, 6, 3],
//                   backgroundColor: '#14abef',
//                   borderColor: "transparent",
//                   pointRadius :"0",
//                   borderWidth: 3
//                 }, {
//                   label: 'Old Visitor',
//                   data: [7, 5, 14, 7, 12, 6, 10, 6, 11, 5],
//                   backgroundColor: "rgba(20, 171, 239, 0.35)",
//                   borderColor: "transparent",
//                   pointRadius :"0",
//                   borderWidth: 1
//                 }]
//               },
//             options: {
//               maintainAspectRatio: false,
//               legend: {
//                 display: false,
//                 labels: {
//                 fontColor: '#585757',  
//                 boxWidth:40
//                 }
//               },
//               tooltips: {
//                 displayColors:false
//               },	
//               scales: {
//                 xAxes: [{
//                 ticks: {
//                   beginAtZero:true,
//                   fontColor: '#585757'
//                 },
//                 gridLines: {
//                   display: true ,
//                   color: "rgba(0, 0, 0, 0.05)"
//                 },
//                 }],
//                  yAxes: [{
//                 ticks: {
//                   beginAtZero:true,
//                   fontColor: '#585757'
//                 },
//                 gridLines: {
//                   display: true ,
//                   color: "rgba(0, 0, 0, 0.05)"
//                 },
//                 }]
//                }
      
//              }
//             });  

//             // chart 2

//             this.canvas = document.getElementById('chart1');
//             this.ctx = this.canvas.getContext('2d');
//             let myChart2 = new Chart(this.ctx, {
//       type: 'doughnut',
//       data: {
//         labels: ["Direct", "Affiliate", "E-mail", "Other"],
//         datasets: [{
//           backgroundColor: [
//             "#14abef",
//             "#02ba5a",
//             "#d13adf",
//             "#fba540"
//           ],
//           data: [5856, 2602, 1802, 1105],
//           borderWidth: [0, 0, 0, 0]
//         }]
//       },
//     options: {
//       maintainAspectRatio: false,
//        legend: {
//        position :"bottom",	
//        display: false,
//           labels: {
//           fontColor: '#ddd',  
//           boxWidth:15
//          }
//       }
//       ,
//       tooltips: {
//         displayColors:false
//       }
//        }
//     });
//  // chart 3

//  this.canvas = document.getElementById('chart3');
//  this.ctx = this.canvas.getContext('2d');
//  let myChart3 = new Chart(this.ctx, {
//   type: 'line',
//   data: {
//     labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
//     datasets: [{
//       label: 'Page Views',
//       data: [0, 8, 12, 5, 12, 8, 16, 25, 15, 10, 20, 10, 30],
//       backgroundColor: 'rgba(0, 150, 136, 0.33)',
//       borderColor: '#009688',
//       pointBackgroundColor:'#fff',
//       pointHoverBackgroundColor:'#fff',
//       pointBorderColor :'#fff',
//       pointHoverBorderColor :'#fff',
//       pointBorderWidth :1,
//       pointRadius :0,
//       pointHoverRadius :4,
//       borderWidth: 3
//     }]
//   }
//   ,
//   options: {
// maintainAspectRatio: false,
//         legend: {
//           position: false,
//           display: true,
//       },
//   tooltips: {
//      enabled: false
// },
// scales: {
//     xAxes: [{
//       display: false,
//       gridLines: false
//     }],
//     yAxes: [{
//       display: false,
//       gridLines: false
//     }]
//   }
//   }

// });

//  // chart 4

//  this.canvas = document.getElementById('chart4');
//  this.ctx = this.canvas.getContext('2d');
//  let myChart4 = new Chart(this.ctx, {
//   type: 'bar',
//   data: {
//     labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
//     datasets: [{
//       label: 'Total Clicks',
//       data: [0, 10, 14, 18, 12, 8, 16, 25, 15, 10, 20, 10, 30],
//       backgroundColor: "#ff6a00"
//     }]
//   },
//   options: {
//     maintainAspectRatio: false,
//     legend: {
//       display: false,
//       labels: {
//       fontColor: '#ddd',  
//       boxWidth:40
//       }
//     },
//     tooltips: {
//       enabled:false
//     },	
    
//     scales: {
//       xAxes: [{
//         barPercentage: .3,
//       display: false,
//       gridLines: false
//       }],
//       yAxes: [{
//       display: false,
//       gridLines: false
//       }]
//     }

//  }
 
// });

// // chart 5

// this.canvas = document.getElementById('chart5');
// this.ctx = this.canvas.getContext('2d');
// let myChart5 = new Chart(this.ctx, {
//   type: 'bar',
//   data: {
//     labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
//     datasets: [{
//       label: 'Total Earning',
//       data: [39, 19, 25, 16, 31, 39, 23, 20, 23, 18, 15, 20],
//       backgroundColor: "#04b35a"
//     },{
//       label: 'Total Sales',
//       data: [27, 12, 26, 15, 21, 27, 13, 19, 32, 22, 18, 30],
//       backgroundColor: "rgba(4, 179, 90, 0.35)"
//     }]
//   },
//   options: {
//     maintainAspectRatio: false,
//     legend: {
//       display: false,
//       position: 'bottom',
//       labels: {
//       fontColor: '#ddd',  
//       boxWidth:13
//       }
//     },
//     tooltips: {
//       enabled:true,
//       displayColors:false,
//     },	
    
//     scales: {
//       xAxes: [{
//          stacked: true,
//         barPercentage: .4,
//       display: false,
//       gridLines: false
//       }],
//       yAxes: [{
//         stacked: true,
//       display: false,
//       gridLines: false
//       }]
//     }

//  }
 
// });


    (function ($) {
      $(document).ready(function() {
        "use strict";
      
        //sidebar menu js
        $.sidebarMenu($('.sidebar-menu'));
        
        // === toggle-menu js
        $(".toggle-menu").on("click", function(e) {
                e.preventDefault();
                $("#wrapper").toggleClass("toggled");
            });	 
             
        // === sidebar menu activation js
        
        $(function() {
                for (var i = window.location, o = $(".sidebar-menu a").filter(function() {
                    return this.href == i;
                }).addClass("active").parent().addClass("active"); ;) {
                    if (!o.is("li")) break;
                    o = o.parent().addClass("in").parent().addClass("active");
                }
            }), 	   
             
        
        
        
        /* Back To Top */
        
        $(document).ready(function(){ 
            $(window).on("scroll", function(){ 
                if ($(this).scrollTop() > 300) { 
                    $('.back-to-top').fadeIn(); 
                } else { 
                    $('.back-to-top').fadeOut(); 
                } 
            }); 
        
            $('.back-to-top').on("click", function(){ 
                $("html, body").animate({ scrollTop: 0 }, 600); 
                return false; 
            }); 
        });	   
             
        
          // page loader
        
            $(window).on('load', function(){
        
             $('#pageloader-overlay').fadeOut(1000);
        
            })  
           
           
        $(function () {
          $('[data-toggle="popover"]').popover()
        })
        
        
        $(function () {
          $('[data-toggle="tooltip"]').tooltip()
        })
        
        
           // theme setting
           $(".switcher-icon").on("click", function(e) {
                e.preventDefault();
                $(".right-sidebar").toggleClass("right-toggled");
            });
          
          $('#theme1').click(theme1);
            $('#theme2').click(theme2);
            $('#theme3').click(theme3);
            $('#theme4').click(theme4);
            $('#theme5').click(theme5);
            $('#theme6').click(theme6);
            
            function theme1() {
              $('#sidebar-wrapper').attr('class', 'bg-theme bg-theme1');
            }
        
            function theme2() {
              $('#sidebar-wrapper').attr('class', 'bg-theme bg-theme2');
            }
        
            function theme3() {
              $('#sidebar-wrapper').attr('class', 'bg-theme bg-theme3');
            }
        
            function theme4() {
              $('#sidebar-wrapper').attr('class', 'bg-theme bg-theme4');
            }
          
          function theme5() {
              $('#sidebar-wrapper').attr('class', 'bg-theme bg-theme5');
            }
          
          function theme6() {
              $('#sidebar-wrapper').attr('class', 'bg-theme bg-theme6');
            }
        
           
            // header setting 
          
          $('#header1').click(header1);
            $('#header2').click(header2);
          $('#header3').click(header3);
          $('#header4').click(header4);
          $('#header5').click(header5);
          $('#header6').click(header6);
          
          function header1() {
              $('#header-setting').attr('class', 'navbar navbar-expand fixed-top color-header bg-theme1');
            }
          
          function header2() {
              $('#header-setting').attr('class', 'navbar navbar-expand fixed-top color-header bg-theme2');
            }
          
          function header3() {
              $('#header-setting').attr('class', 'navbar navbar-expand fixed-top color-header bg-theme3');
            }
          
          function header4() {
              $('#header-setting').attr('class', 'navbar navbar-expand fixed-top color-header bg-theme4');
            }
          
          function header5() {
              $('#header-setting').attr('class', 'navbar navbar-expand fixed-top color-header bg-theme5');
            }
          
          function header6() {
              $('#header-setting').attr('class', 'navbar navbar-expand fixed-top color-header bg-theme6');
            }
          
          
          
          // default header & sidebar
          
          $(document).ready(function(){
            
             $("#default-header").click(function(){
              
             $("#header-setting").removeClass("color-header bg-theme1 bg-theme2 bg-theme3 bg-theme4 bg-theme5 bg-theme6");
            
            });
            
            
            $("#default-sidebar").click(function(){
              
             $("#sidebar-wrapper").removeClass("bg-theme bg-theme1 bg-theme2 bg-theme3 bg-theme4 bg-theme5 bg-theme6");
            
            });
            
            
            
          });
          
          
      
		
      
    
		

	
          
          
          
          
      });
    })(jQuery);
  }

}
