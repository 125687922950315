import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-student-opening-balance',
  templateUrl: './student-opening-balance.component.html',
  styleUrls: ['./student-opening-balance.component.css']
})
export class StudentOpeningBalanceComponent implements OnInit {

  SelectedFileForUpload:any;
  StudentOpeningBalanceList:any;
  Count:number=0;
  ClassList:any;
  SectionList:any;
  ClassSectionList:any;
  StudentList:any;

  constructor(private financeService: FinanceService, private LookupService: LookupService, public globalHelper: GlobalHelper,
    private spinnerService: Ng4LoadingSpinnerService) { }


  StudentOpeningBalanceModel = {
    ClassID: 0,
    SectionID: 0,
    StudentID: 0,
    OpeningAmount: 0,
    UploadType: 0
}

StudentSearchModel = {
    ClassID: 0,
    SectionID: 0,
    RegistrationNumber: '',
    StudentNo: ''
};       


 // Upload File

 onUpload(event: any) {
  this.SelectedFileForUpload = event.target.files[0];
}


SaveStudentOpeningBalance() {
   
  this.spinnerService.show();
    this.financeService.SaveStudentOpeningBalance(this.SelectedFileForUpload, JSON.stringify(this.StudentOpeningBalanceModel)).subscribe((data: any) => {
      if (data.webResponse.Status) {
        this.GetStudentOpeningBalance();
        this.Clear();
      }
      this.globalHelper.CheckActionStatus(data);
      this.spinnerService.hide();
    });
};


GetStudentOpeningBalance() {
  this.StudentOpeningBalanceList=[];
  this.spinnerService.show();
    this.financeService.GetStudentOpeningBalance(JSON.stringify(this.StudentSearchModel)).subscribe((data: any) => {
        this.StudentOpeningBalanceList = data.StudentOpeningBalanceList;
        this.spinnerService.hide();

    });
};


UpdateStudentOpeningBalance() {   
  this.spinnerService.show();
    this.financeService.UpdateStudentOpeningBalance(JSON.stringify(this.StudentOpeningBalanceList)).subscribe((data: any) => {
      this.spinnerService.show();
      this.globalHelper.CheckActionStatus(data);
        this.GetStudentOpeningBalance();
    });

}


Clear() {
    this.StudentOpeningBalanceModel.UploadType = 0;
    this.StudentOpeningBalanceModel.ClassID = 0;
    this.StudentOpeningBalanceModel.SectionID = 0;
    this.StudentOpeningBalanceModel.OpeningAmount = 0;
    this.StudentOpeningBalanceModel.StudentID = 0;
   
}


SelectModel = {
    Active: false
}


SelectAll() {
    if (this.SelectModel.Active) {
        for (var i = 0; i < this.StudentOpeningBalanceList.length; i++) {
            this.StudentOpeningBalanceList[i].IsSelect = true;
        }
    }
    // if (this.SelectModel.Active == false) {
    //     for (var i = 0; i < this.StudentOpeningBalanceList.length; i++) {
    //         this.StudentOpeningBalanceList[i].IsSelect = false;
    //     }
    // }
}

ChangeSelect(item) {
    this.Count = 0;
    for (var i = 0; i < this.StudentOpeningBalanceList.length; i++) {
        if (this.StudentOpeningBalanceList[i].IsSelect) {
            this.Count = this.Count + 1;
        }
    }

    if (this.Count == this.StudentOpeningBalanceList.length) {
        this.SelectModel.Active = true;
    }
    else {
        this.SelectModel.Active = false;
    }
}



  ngOnInit() {
    this.GetClass();
    this.GetStudentOpeningBalance();
    this.GetSection();
    this.GetClassSection();
    this.GetStudent();
  }


  GetClass() {
    this.LookupService.GetLookupsList('Class', '').subscribe((data: any) => {
        this.ClassList = data.LookupItems;

    });
};

GetSection() {
  this.spinnerService.show();
    this.StudentSearchModel.SectionID = 0;
    var itemBo = {
        ItemId: this.StudentSearchModel.ClassID

    };

    this.LookupService.GetLookupsList('SectionName', JSON.stringify(itemBo)).subscribe((data: any) => {
        this.SectionList = data.LookupItems;
        this.spinnerService.hide();
    });
};

GetClassSection() {
  this.spinnerService.show();
    this.StudentOpeningBalanceModel.SectionID = 0;
    this.StudentOpeningBalanceModel.StudentID = 0;

    var itemBo = {
        ItemId: this.StudentOpeningBalanceModel.ClassID

    };

    this.LookupService.GetLookupsList('SectionName', JSON.stringify(itemBo)).subscribe((data: any) => {
        this.ClassSectionList = data.LookupItems;
        this.spinnerService.hide();

    });
};

GetStudent() {
  this.spinnerService.show();
    this.StudentOpeningBalanceModel.StudentID = 0;
    var itemBo = {
        ItemId: this.StudentOpeningBalanceModel.ClassID,
        ItemId2: this.StudentOpeningBalanceModel.SectionID
    };
    this.LookupService.GetLookupsList('Student', JSON.stringify(itemBo)).subscribe((data: any) => {
        this.StudentList = data.LookupItems;
        this.spinnerService.hide();
    });
};


}
