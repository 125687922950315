import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-group-dashboard',
  templateUrl: './group-dashboard.component.html',
  styleUrls: ['./group-dashboard.component.css']
})
export class GroupDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
