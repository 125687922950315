import { Injectable } from '@angular/core';
import { GlobalHelper } from './global.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class LookupService {

  constructor( private globalHelper:GlobalHelper, private httpclient: HttpClient) { }
  
  GetLookupsList(lookupname:any,prm:any):Observable<any>{   
   
    let Parameter='LookupName=' +lookupname  + '&parameter=' +prm; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
   return this.httpclient.post<any>(this.globalHelper.ApiUrl+'Lookup/GetLookupsList?'+encodeURI(Parameter),
   {headers:reqHeader});
 }

}
