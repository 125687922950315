import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TransportService } from 'src/app/services/Transport/transport.service';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StudentModalComponent } from 'src/app/modal/student-modal/student-modal.component';

@Component({
    selector: 'app-fee-payment',
    templateUrl: './fee-payment.component.html',
    styleUrls: ['./fee-payment.component.css']
})
export class FeePaymentComponent implements OnInit {

    
    Integer: any;
    decimal: any;
    Bank: any;
    PaymentModeList: any;
    ComponentList: any;
    ReceiptList: any;
    FeeReceiptModel = {
        ClassID: 0,
        BankID: 0,
        BankAccountID: 0,
        StudentID: 0,
        FirstName: '',
        MiddleName: '',
        LastName: '',
        ClassName: '',
        StreamName: '',
        StudentName: '',
        PaymentModeID: 0,
        PaidAmount: 0,
        FeeAmount: 0,
        Fine: 0,
        OpeningAmount: 0,
        WaiverAmount: 0,
        BankCharges: 0,
        StudentStatus: 0,
        EmailID: '',
        TeacherEmailID: '',
        ReferenceNo: '',
        PaymentDate: '',
        Mobile: '',
        FatherContactCell: '',
        PartialRemarks: '',
        PayingAmount: 0,
        TransportAmount: 0,
        TotalDiscountAmount: 0,
        Remarks: '',
        TotalFeeAmounts: 0,
        ChequeDate: '',
        ChequeNo: '',
        ExcessAmount:0,

    }

    SearchModel = {
        StudentID: 0,
        SectionID: 0,
        CancelStatus: '0',
        ClassID: 0,
        StudentNo: '',
        FromDate: '',
        PaymentModeID: 0,
        ToDate: '',
        CreatedBy: '',
        RecType: ''
    }
    PaymentReceiptList: any;
    StudentList: any;
    tab = 1;
    GeneralPaymentModel = {
        StudentID: '',
        RecType: '',
        PaidByID: ''
    };
    GeneralPaymentList = [];
    FeeReceiptList = [];
    TisCollapsed = true;
    TisCollapsed2 = true;
    TisCollapsed1 = true;
    HidePay = false;
    THidePay = false;
    ParentID: any = "";
    FeeBookNo: any = "";
    StudentNo = "";
    FirstName = '';
    TReceiptNo = '';
    ReceiptNo = '';
    LastName = '';
    MiddleName = '';
    RouteInstallmentList: any;
    FeeComponentList: any;
    InstallmentComponentList = [];
    RouteID: any; RouteLocationID: any; StudentID: any;
    FineRuleList: any;
    month: any;
    PayingFineAlert: any;
    FineAlert: any;
    Submitted: any;
    VAmount: any;
    PaymentAlert: any;
    AmountAlert: any;
    ThidePrint: any;
    hidePrint: any;
    AlertMesg: any;
    BODRegNo = "";
    DetailsStudent: any;
    ListStudentConcession: any;
    InstallmentList: any;
    isCollapsed1: any = true;
    isCollapsed: any = true;
    OtherPaymentHistoryList = [];
    TotalPaidAmounts: any;
    TotalFineAmounts: any;
    IsConcession: any;
    TotalPayableAmount: any;
    TotalPaidAmount: any;
    TotalDueAmount: number;
    CalculatedPaidAmount: any;
    FilterModel = {
        StudentNo: ''
    };
    BankList: any;

    public prmstudent = {
        StudentID: 0,
        ParentID: 0
    }
    constructor(public modalService: NgbModal, private transportService: TransportService, private spinnerService: Ng4LoadingSpinnerService, private financeService: FinanceService, private lookupService: LookupService, public globalHelper: GlobalHelper) { }



    openModal(item) {
        this.prmstudent.StudentID = item.StudentID;
        this.prmstudent.ParentID = item.ParentID;
        const modalRef = this.modalService.open(StudentModalComponent);
        modalRef.componentInstance.prmstudent = this.prmstudent;
        modalRef.result.then((result) => {
            if (result) {
                console.log(result);
            }
        });
        // modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        //   console.log(receivedEntry);
        // })
    }

    GetFineRule() {
        this.lookupService.GetLookupsList('FineRule', '').subscribe((data) => {
            this.FineRuleList = data.LookupItems;

        });
    };
    setTab(tabId) {

        this.tab = tabId;
    };
    GetStudentDetails() {

        this.hidePrint = true;
        this.HidePay = false;
        this.THidePay = false;
        this.ReceiptNo = ''
        this.ChallanNo='';
        this.StudentList = {};
        if ((this.StudentNo != null && this.StudentNo != '' && this.StudentNo != '0') || (this.ParentID != null && this.ParentID != '0' && this.ParentID != '')|| (this.FeeBookNo != null && this.FeeBookNo != '0' && this.FeeBookNo != '') || this.FirstName != '' || this.MiddleName != '' || this.LastName != '') {
            this.spinnerService.show();
            this.AlertMesg = '';

            this.financeService.GetStudentDetails(this.StudentNo, this.BODRegNo, this.ParentID, this.FirstName, this.MiddleName, this.LastName,this.FeeBookNo).subscribe((data) => {
                //  this.globalHelper.CheckActionStatus(data);
                this.spinnerService.hide();


                if (data.webResponse.Status) {
                    this.StudentList = data.StudentList;
                    for (var i = 0; i < this.StudentList.length; i++) {
                        this.StudentList[i].IsActive = false;
                        if (this.StudentNo == data.StudentList[i].StudentNo) {
                            this.GetID(data.StudentList[i]);
                            break;
                        }
                    }

                    this.SearchModel.StudentID = data.StudentList[0].StudentID;
                  
                }
            });
        }
        else {
            this.AlertMesg = 'Please Enter  GR No./Parent ID/First Name/Middle Name/Last Name'
        }
    };
    Reset() {
        this.HidePay = false;
        this.ParentID = '';
        this.FeeBookNo = '';
        this.StudentNo = '';
        this.SearchModel.StudentID = 0;
        this.FirstName = '';
        this.ReceiptNo = '';
        this.ChallanNo='';
        this.LastName = '';
        this.MiddleName = '';
        this.StudentList = [];
        this.FeeComponentList = [];
        this.PaymentReceiptList = [];
        this.OtherPaymentHistoryList = [];
        this.InstallmentComponentList = [];
        this.FeeReceiptModel = {
            ClassID: 0,
            BankID: 0,
            BankAccountID: 0,
            StudentID: 0,
            FirstName: '',
            MiddleName: '',
            LastName: '',
            ClassName: '',
            StreamName: '',
            StudentName: '',
            PaymentModeID: 0,
            PaidAmount: 0,
            FeeAmount: 0,
            Fine: 0,
            OpeningAmount: 0,
            WaiverAmount: 0,
            BankCharges: 0,
            StudentStatus: 0,
            EmailID: '',
            TeacherEmailID: '',
            ReferenceNo: '',
            PaymentDate: '',
            Mobile: '',
            FatherContactCell: '',
            PartialRemarks: '',
            PayingAmount: 0,
            TransportAmount: 0,
            TotalDiscountAmount: 0,
            Remarks: '',
            TotalFeeAmounts: 0,
            ChequeDate: '',
            ChequeNo: '',
            ExcessAmount:0,
        }

    }

    GetBankCharges() {
        this.FeeReceiptModel.BankID=0;
        this.FeeReceiptModel.BankAccountID=0;
        this.FeeReceiptModel.ReferenceNo='';
        var BankCharges = 0;

        for (var i = 0; i < this.PaymentModeList.length; i++) {

            if (this.PaymentModeList[i].ItemId == this.FeeReceiptModel.PaymentModeID) {

                BankCharges = this.PaymentModeList[i].BankCharges;
            }

        }
        this.FeeReceiptModel.BankCharges = BankCharges;
        this.GetTotalWithCharges();

    }

    GetTotalWithCharges() {

        this.FeeReceiptModel.PaidAmount = this.CalculatedPaidAmount + this.FeeReceiptModel.BankCharges;

    }
    GetID(item) {
        if (this.StudentNo == '') {
            this.StudentNo = item.StudentNo;
            this.ParentID = '';
            this.FeeBookNo = '';
            this.FirstName = '';
            this.ReceiptNo = '';
            this.ChallanNo='';
            this.LastName = '';
            this.MiddleName = '';
            this.StudentList = [];
            this.IsBack=false;
            this.GetStudentDetails();
        }
        for (var i = 0; i < this.StudentList.length; i++) {
            this.StudentList[i].IsActive = false;
        }
        item.IsActive = true;
        this.SearchModel.StudentID = item.StudentID;
        this.GeneralPaymentModel.StudentID = item.StudentID;
        this.GeneralPaymentModel.PaidByID = item.StudentID;
        this.GeneralPaymentModel.RecType = 'STUDENT';
        this.GetDueGeneralPayment();
        this.GetDueBookStore();
        this.GetOtherPaymentHistory();
        this.DetailsStudent = item;
        this.FeeReceiptModel = item;
        //   this.FeeReceiptModel.StudentID=item.StudentID;
        //   this.FeeReceiptModel.ClassID = item.ClassID;
        //   this.FeeReceiptModel.Mobile = item.Mobile;
        //   this.FeeReceiptModel.FatherContactCell = item.FatherContactCell;
        //   this.FeeReceiptModel.EmailID = item.EmailID;
        //   this.FeeReceiptModel.TeacherEmailID = item.EmailID;
        //   this.FeeReceiptModel.StudentStatus = item.StudentStatus;
        this.ReceiptNo = '';
        this.ChallanNo='';
        this.FeeReceiptModel.PayingAmount = 0;
        this.FeeReceiptModel.OpeningAmount = 0;
        this.ListStudentConcession = [];
        this.InstallmentComponentList = [];
        this.InstallmentList = [];
        this.FeeComponentList = [];
        this.ComponentList = [];
        this.isCollapsed1 = true;
        this.isCollapsed = true;
        this.GetPaymentReceipt();
        this.AllStudentConcession();
        this.GetFeeInstallmentComponentNew();
        this.GetOpeningBalance();
        this.GetAllPaymentReceipt();
        this.FeeReceiptModel.PaymentModeID =this.SetModel.PaymentModeID !=0?this.SetModel.PaymentModeID:1;
        this.FeeReceiptModel.PaymentDate = this.SetModel.SetPaymentDate !=''?moment(new Date(this.SetModel.SetPaymentDate)).format("DD-MMM-YYYY"):moment(new Date()).format("DD-MMM-YYYY");
        this.FeeReceiptModel.ChequeDate = this.SetModel.ChequeDate !=''?moment(new Date(this.SetModel.ChequeDate)).format("DD-MMM-YYYY"):'';
        this.FeeReceiptModel.FeeAmount = 0;
        this.FeeReceiptModel.BankAccountID = this.SetModel.BankAccountID !=0?this.SetModel.BankAccountID:0;
        this.FeeReceiptModel.BankID = this.SetModel.BankID !=0?this.SetModel.BankID:0;
        this.FeeReceiptModel.TransportAmount = 0;
    }
    ChkSelectInstallment(item, index) {
        var myDate = new Date();
        this.month = moment(myDate).format('MMM');
        if (index <= this.FeeComponentList.length - 1) {
            var pflag = true;
            var i = index - 1;
            if (i >= 0) {
                for (i; i >= 0; i--) {
                    if (this.FeeComponentList[i].IsActive == false && (this.FeeComponentList[i].TotalAmount - this.FeeComponentList[i].DiscountAmount - this.FeeComponentList[i].PaidAmount > 0)) {
                        pflag = false;
                        break;
                    }
                  
                }
               
            }

            var nflag = false;
            if (index <= this.FeeComponentList.length - 1 && index != this.FeeComponentList.length - 1) {
                for (var j = index + 1; j < this.FeeComponentList.length; j++) {
                    if (this.FeeComponentList[j].IsActive)
                        break;

                    if (this.FeeComponentList[j].IsActive == false && (this.FeeComponentList[j].TotalAmount - this.FeeComponentList[j].DiscountAmount - this.FeeComponentList[j].PaidAmount > 0)) {
                        nflag = true;

                        break;
                    }
                }
            }

            if (!pflag) {
               
                item.IsActive = false;
            }
            else if (pflag && nflag || index == this.FeeComponentList.length - 1) {
                
                item.IsActive = item.IsActive;

            }
            else
                item.IsActive = true;
        }
      
        this.GetDueAmount(item, index);
    }
    ChkSelectInstallment1(item, index) {
      
       var myDate = new Date();
       this.month = moment(myDate).format('MMM');
      
       if (index <= this.FeeComponentList.length - 1) {
           var i = index - 1;

           if (i >= 0) {
               for (; i >= 0; i--) {
              
                 if (this.FeeComponentList[i].IsActive == false && (this.FeeComponentList[i].TotalAmount - this.FeeComponentList[i].DiscountAmount - this.FeeComponentList[i].PaidAmount > 0)) {
                      this.FeeComponentList[i].IsActive=true; 
                      this.FeeComponentList[i].IsDisplay=true;    
                      this.IsBack = true;                   
                   }
                   
                   if (this.IsBack && this.FeeComponentList[i].IsActive == true && (this.FeeComponentList[i].TotalAmount - this.FeeComponentList[i].DiscountAmount - this.FeeComponentList[i].PaidAmount > 0)) {
                    this.FeeComponentList[i].IsDisplay=true;    
                                   
                 }
                
                 
               }
             
            
           }

          
           var nflag = false;
           if (index>0 && !this.FeeComponentList[index].IsActive && index <= this.FeeComponentList.length - 1) {
            this.FeeComponentList[index-1].IsDisplay=false; 
            this.FeeComponentList[index].IsActive=false;  
           }
          
           else if (index>0 && index <= this.FeeComponentList.length - 1 && index != this.FeeComponentList.length - 1) {
               for (var j = index + 1; j < this.FeeComponentList.length; j++) {
                   if (this.FeeComponentList[j].IsActive)
                       break;
                   if (this.FeeComponentList[j].IsActive == false && (this.FeeComponentList[j].TotalAmount - this.FeeComponentList[j].DiscountAmount - this.FeeComponentList[j].PaidAmount > 0)) {
                       nflag = true;
                       break;
                   }
               }
           }         
                 
         
       }
      
       this.GetDueAmount(item, index);
   }
    CalculatePayingAmount() {
        var PayingAmount = this.FeeReceiptModel.PayingAmount - this.FeeReceiptModel.OpeningAmount;
        var PartialRemarks = '';
        if (PayingAmount > 0) {
            PayingAmount = PayingAmount + this.FeeReceiptModel.WaiverAmount - this.FeeReceiptModel.Fine;
            if (PayingAmount > 0) {
                for (var i = 0; i < this.FeeComponentList.length; i++) {

                    if (this.FeeComponentList[i].IsActive == true && PayingAmount > 0) {

                        var start = 0;
                        var DueAmount = 0;
                        for (var j = 0; j < this.FeeComponentList[i].ComponentList.length; j++) {

                            if ((PayingAmount >= this.FeeComponentList[i].ComponentList[j].DueAmount) && this.FeeComponentList[i].ComponentList[j].DueAmount != 0) {

                                this.FeeComponentList[i].ComponentList[j].Paid = this.FeeComponentList[i].ComponentList[j].DueAmount;

                                DueAmount = DueAmount + this.FeeComponentList[i].ComponentList[j].DueAmount;
                                if (this.FeeComponentList[i].ComponentList[j].Paid == 0) {
                                    this.FeeComponentList[i].ComponentList[j].WaiverAmount = 0;
                                }
                                else {
                                    this.FeeComponentList[i].ComponentList[j].WaiverAmount = (this.FeeComponentList[i].ComponentList[j].WaiverAmount / (this.FeeComponentList[i].ComponentList[j].Amount == this.FeeComponentList[i].ComponentList[j].WaiverAmount ? 1 : (this.FeeComponentList[i].ComponentList[j].Amount - this.FeeComponentList[i].ComponentList[j].WaiverAmount))) * this.FeeComponentList[i].ComponentList[j].Paid;
                                }
                            }
                            else if (this.FeeComponentList[i].ComponentList[j].DueAmount > PayingAmount) {
                                this.FeeComponentList[i].ComponentList[j].Paid = PayingAmount;
                                DueAmount = DueAmount + PayingAmount;
                                if (this.FeeComponentList[i].ComponentList[j].Paid > 0) {
                                    this.FeeComponentList[i].ComponentList[j].WaiverAmount = (this.FeeComponentList[i].ComponentList[j].WaiverAmount / (this.FeeComponentList[i].ComponentList[j].Amount == this.FeeComponentList[i].ComponentList[j].WaiverAmount ? 1 : (this.FeeComponentList[i].ComponentList[j].Amount - this.FeeComponentList[i].ComponentList[j].WaiverAmount))) * this.FeeComponentList[i].ComponentList[j].Paid;
                                }
                                else {
                                    this.FeeComponentList[i].ComponentList[j].WaiverAmount = 0;
                                }
                                PayingAmount = 0;
                            }

                            PayingAmount = PayingAmount - this.FeeComponentList[i].ComponentList[j].DueAmount;
                        }

                        if (this.FeeComponentList[i].TotalAmount - this.FeeComponentList[i].DiscountAmount == DueAmount) {
                            if (PartialRemarks.length == 0)
                                PartialRemarks = PartialRemarks + this.FeeComponentList[i].Installment
                            else
                                PartialRemarks = PartialRemarks + ',' + this.FeeComponentList[i].Installment
                        }
                        else {
                            if (PartialRemarks.length == 0)
                                PartialRemarks = PartialRemarks + this.FeeComponentList[i].Installment + '(Partial)'
                            else
                                PartialRemarks = PartialRemarks + ',' + this.FeeComponentList[i].Installment + '(Partial)'
                        }
                    }
                }
                this.FeeReceiptModel.PartialRemarks = PartialRemarks;

                return true;
            }
            else {
                this.PayingFineAlert = 'Paying Amount must be greater than fine amount excluding previous due';
                return false;
            }

        }
        return true;
    }
    SaveStudentFeePaymentNew() {
        this.FineAlert = '';
       
        if (this.FeeReceiptModel.PaymentModeID == 0 || this.FeeReceiptModel.PaymentModeID == undefined) {
            this.PaymentAlert = 'Please Select Payment Mode';
            return;

        }

        if ((this.FeeReceiptModel.PaymentModeID == 3 || this.FeeReceiptModel.PaymentModeID == 4) && this.FeeReceiptModel.BankAccountID==0) {
            this.PaymentAlert = 'Please select Receiving Bank';
            return;
        }
        if ((this.FeeReceiptModel.PaymentModeID == 2) && this.FeeReceiptModel.BankID==0) {
            this.PaymentAlert = 'Please select Paying Bank';
            return;
        }
        if (this.FeeReceiptModel.PayingAmount>this.FeeReceiptModel.PaidAmount && (this.TotalDueAmount+this.FeeReceiptModel.Fine+this.FeeReceiptModel.OpeningAmount-this.FeeReceiptModel.PayingAmount)>0) {
            this.PaymentAlert = 'Please select next installment';
            return;

        }

        if (this.CalculatePayingAmount()) {

            //if (this.ValidAmount()) {
            if (this.FeeReceiptModel.OpeningAmount < this.FeeReceiptModel.PayingAmount) {
                this.FeeReceiptModel.OpeningAmount = this.FeeReceiptModel.OpeningAmount;
            }
            else {
                this.FeeReceiptModel.OpeningAmount = this.FeeReceiptModel.PayingAmount;
            }
            if (this.FeeReceiptModel.PaidAmount > 0) {
                this.HidePay = true;               
                this.spinnerService.show()
                this.FeeReceiptModel.ChequeDate = this.FeeReceiptModel.ChequeDate != null && this.FeeReceiptModel.ChequeDate != '' ? moment(this.FeeReceiptModel.ChequeDate).format('DD-MMM-YYYY') : '';
                this.FeeReceiptModel.PaymentDate = this.FeeReceiptModel.PaymentDate != null ? moment(this.FeeReceiptModel.PaymentDate).format('DD-MMM-YYYY') : '';
                this.financeService.SaveStudentFeePaymentNew(JSON.stringify(this.FeeReceiptModel), JSON.stringify(this.FeeComponentList)).subscribe((response) => {
                    this.globalHelper.CheckActionStatus(response);
                    this.spinnerService.hide()

                    if (response.webResponse.Status) {
                        this.AmountAlert = '';
                        this.PayingFineAlert = '';
                        this.FeeReceiptModel.PartialRemarks = '';
                        this.FeeReceiptModel.TransportAmount = 0;
                        this.FeeReceiptModel.TransportAmount = 0;
                        this.ClearFeeDetails();
                        this.ReceiptNo = response.ReceiptNo;
                        this.GetPaymentReceipt();
                        this.GetFeeInstallmentComponentNew();
                        this.GetAllPaymentReceipt();
                        this.GetOpeningBalance();

                    }

                });
            }
        }


    }
    printPage() {

        //window.open('feecomponent');

    }
    PrintFeeReceipt(ReceiptNo, PrintType, CopyType, Cancel) {

        this.hidePrint = false;
        var Template = this.globalHelper.GetLoginToken().FeeTemplate;
        if (Cancel == 'Yes') {
            CopyType = 'C';
        }

        //   var url = "../../../../App/Template/FeeReceipt/" + Template + "?PrintType=FeeReceipt&ReceiptNo=" + ReceiptNo + "&PrintType=" + PrintType + "&CopyType=" + CopyType;
        var url = Template + "?PrintTypes=FeeReceipt&ReceiptNo=" + ReceiptNo + "&PrintType=" + PrintType + "&CopyType=" + CopyType;
        this.globalHelper.OpenPopUpWindow(url, "yes", "950", "1000");
    };
    PrintPre() {
        var divPreRecipt = document.getElementById("divPreRecipt").innerHTML;
        divPreRecipt = divPreRecipt.replace('no record found', '');
        var printWindow = window.open('', '', 'height=400,width=800');

        printWindow.document.write('<html><head><title>' + this.DetailsStudent.FirstName + ' </title>');
        printWindow.document.write('<style type="text/css">');
        printWindow.document.write('.t1{ border-top: 1px solid black;  border-left: 1px solid black;white-space:nowrap;font-family:Calibri;font-size:10px} .t2 { border-right: 1px solid black; border-bottom: 1px solid black;}.t3 { border-right: 1px solid black;border-bottom: 1px solid black; }');
        printWindow.document.write('</style>');
        printWindow.document.write('</head><body onload="window.print()">');


        printWindow.document.write(divPreRecipt);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        // printWindow.print();

    }
    GetFeeReceipt(ReceiptNo) {
        this.spinnerService.show()
        this.financeService.GetFeeReceipt(ReceiptNo).subscribe((response) => {

            this.FeeReceiptList = response.FeeReceiptList;
            this.spinnerService.hide()
            var TotalDiscountAmount = 0;
            var TotalFeeAmounts = 0;
            for (var i = 0; i < this.FeeReceiptList.length; i++) {

                TotalDiscountAmount = TotalDiscountAmount + this.FeeReceiptList[i].DiscountAmount;
                TotalFeeAmounts = TotalFeeAmounts + this.FeeReceiptList[i].Amount;
            }
            this.FeeReceiptModel.TotalDiscountAmount = TotalDiscountAmount;
            this.FeeReceiptModel.TotalFeeAmounts = TotalFeeAmounts;
            this.ReceiptList = this.FeeReceiptList[0];
            var str = this.ReceiptList.PaidAmount.toString().trim();

            var substr = str.split('.');
            this.Integer = substr[0];

            this.decimal = substr.length == 2 ? substr[1] : 0;

            setTimeout(function () {
                this.Print(ReceiptNo);
            }, 300);
        });
    };
    ClearFeeDetails() {
        this.FeeReceiptModel.Remarks = '';
        this.FeeReceiptModel.PayingAmount = 0;
        this.FeeReceiptModel.PaidAmount = 0;
        this.FeeReceiptModel.WaiverAmount = 0;
        this.FeeReceiptModel.Fine = 0;
    }
    GetDueAmount(item, index) {
        var CurrentDate = new Date();
        var year = CurrentDate.getFullYear();
        var month = CurrentDate.getMonth() + 1;
        var day = CurrentDate.getDate();

        var fine = 0;

        for (var i = index; i >= 0; i--) {
            
            if (this.FeeComponentList[i].IsActive && (this.FeeComponentList[i].TotalAmount-this.FeeComponentList[i].DiscountAmount-this.FeeComponentList[i].PaidAmount)>0) {
             
                if (this.FeeComponentList[i].PaidAmount==0) {
                    if (this.FineRuleList[1].IsPerDay) {
                        var dayDiff = 0;
                        dayDiff = this.dateDiff1(this.FeeComponentList[i].DueAlertDate, CurrentDate)
                        if (dayDiff > this.FineRuleList[1].MaxFineDays) {
                            dayDiff = this.FineRuleList[1].MaxFineDays;
                        }

                        if (fine == 0 && dayDiff > 0) {

                            fine = dayDiff * this.FineRuleList[1].FineAmount;
                            dayDiff = 0;
                        }
                        else if (dayDiff > 0) {
                            fine = fine + dayDiff * this.FineRuleList[1].FineAmount;
                            dayDiff = 0;
                        }

                    }
                    else if (this.FineRuleList[1].IsPerWeek) {

                        var dayDiff = 0;
                        let weekDiff = 0;
                        dayDiff = this.dateDiff1(this.FeeComponentList[i].DueAlertDate, CurrentDate)
                        weekDiff = Math.ceil(dayDiff / 7);
                        if (weekDiff > this.FineRuleList[1].MaxFineDays) {
                            weekDiff = this.FineRuleList[1].MaxFineDays;
                        }

                        if (fine == 0 && weekDiff > 0) {
                            fine = weekDiff * this.FineRuleList[1].FineAmount;
                            dayDiff = 0;
                        }
                        else if (weekDiff > 0) {
                            fine = fine + weekDiff * this.FineRuleList[1].FineAmount;
                            dayDiff = 0;
                        }

                    }
                    else if (this.FineRuleList[1].IsPerMonth) {

                        var dayDiff = 0;
                        let monthDiff = 0;
                        dayDiff = this.monthDiff(new Date(this.FeeComponentList[i].DueAlertDate), new Date())
                        monthDiff = dayDiff;
                       
                        if (monthDiff > this.FineRuleList[1].MaxFineDays) {
                            monthDiff = this.FineRuleList[1].MaxFineDays;
                        }

                        if (fine == 0 && monthDiff > 0) {
                            fine = monthDiff * this.FineRuleList[1].FineAmount;
                            dayDiff = 0;
                        }
                        else if (monthDiff > 0) {
                            fine = fine + monthDiff * this.FineRuleList[1].FineAmount;
                            dayDiff = 0;
                        }

                    }
                    else

                        if (this.FineRuleList[1].IsFixed) {
                            if (CurrentDate > new Date(this.FeeComponentList[i].DueAlertDate)) {

                                if (fine == 0) {
                                    fine = this.FineRuleList[1].FineAmount;
                                }
                                else {
                                    fine = fine + this.FineRuleList[1].FineAmount;
                                }
                            }
                        }

                }

            }

        }

        this.FeeReceiptModel.Fine = fine;
       
        this.GetTotalPaidAmount();
    }

    dateDiff1(firstDate, secondDate) {
        firstDate = new Date(firstDate);
        secondDate = new Date(secondDate);
        if (!isNaN(firstDate) && !isNaN(secondDate)) {
            firstDate.setHours(0, 0, 0, 0); //ignore time part
            secondDate.setHours(0, 0, 0, 0); //ignore time part
            var dayDiff = secondDate - firstDate;
            dayDiff = dayDiff / 86400000; // divide by milisec in one day
            return dayDiff;
        }
    }
    monthDiff(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }
    IndexClick = 0;
    IsBack=false;
    GetTotalPayableAmount(item, index) {
        this.ReceiptNo = '';
        this.IndexClick = index;
        this.HidePay = false;
        this.ChkSelectInstallment1(item, index);
        var TotalPayableAmount = 0;
        var TotalDiscountAmount = 0;
        var TransportAmount = 0;

        for (var i = 0; i < this.FeeComponentList.length; i++) {

            if (this.FeeComponentList[i].IsActive) {

                TotalPayableAmount = TotalPayableAmount + (this.FeeComponentList[i].TotalAmount - this.FeeComponentList[i].DiscountAmount - this.FeeComponentList[i].PaidAmount);
                TotalDiscountAmount = TotalDiscountAmount + this.FeeComponentList[i].DiscountAmount;

                for (var j = 0; j < this.FeeComponentList[i].ComponentList.length; j++) {

                    if (this.FeeComponentList[i].ComponentList[j].ComponentTypeID == 5) {
                        TransportAmount = TransportAmount + this.FeeComponentList[i].ComponentList[j].Amount - this.FeeComponentList[i].ComponentList[j].WaiverAmount - this.FeeComponentList[i].ComponentList[j].PaidAmount;
                    }

                }

            }


        }

        this.FeeReceiptModel.TransportAmount = TransportAmount;
        this.FeeReceiptModel.FeeAmount = TotalPayableAmount;
        this.FeeReceiptModel.TotalDiscountAmount = TotalDiscountAmount;

         this.GetTotalPaidAmount();

    }

    ClearSet(){
        
        this.SetModel.SetPaymentDate=moment(new Date(this.FeeReceiptModel.PaymentDate)).format("DD-MMM-YYYY");
        this.SetModel.ChequeDate=this.FeeReceiptModel.ChequeDate!=''?moment(new Date(this.FeeReceiptModel.ChequeDate)).format("DD-MMM-YYYY"):'';
        this.SetModel.PaymentModeID=this.FeeReceiptModel.PaymentModeID;
        this.SetModel.BankID=this.FeeReceiptModel.BankID;
        this.SetModel.BankAccountID=this.FeeReceiptModel.BankAccountID;
      

    }
    
    SetModel={
        SetPaymentDate:'',
        ChequeDate:'',
        PaymentModeID:0,
        BankID:0,
        BankAccountID:0,
        
    }

   
    CheckFineAmount() {

        var CurrentDate = new Date(this.SetModel.SetPaymentDate);
        this.FeeReceiptModel.BankID=this.SetModel.BankID;
        this.FeeReceiptModel.BankAccountID=this.SetModel.BankAccountID;
        this.FeeReceiptModel.PaymentModeID=this.SetModel.PaymentModeID;
        this.FeeReceiptModel.PaymentDate=moment(new Date(this.SetModel.SetPaymentDate)).format("DD-MMM-YYYY");
        this.FeeReceiptModel.ChequeDate=this.SetModel.ChequeDate!=''?moment(new Date(this.SetModel.ChequeDate)).format("DD-MMM-YYYY"):'';
        var fine = 0;

        for (var i = this.IndexClick; i >= 0; i--) {
           
            if (this.FeeComponentList[i].IsActive && (this.FeeComponentList[i].TotalAmount-this.FeeComponentList[i].DiscountAmount-this.FeeComponentList[i].PaidAmount)>0) {
               
                if (this.FeeComponentList[i].PaidAmount==0) {
                    if (this.FineRuleList[1].IsPerDay) {
                        var dayDiff = 0;
                        dayDiff = this.dateDiff1(this.FeeComponentList[i].DueAlertDate, CurrentDate)
                        if (dayDiff > this.FineRuleList[1].MaxFineDays) {
                            dayDiff = this.FineRuleList[1].MaxFineDays;
                        }

                        if (fine == 0 && dayDiff > 0) {

                            fine = dayDiff * this.FineRuleList[1].FineAmount;
                            dayDiff = 0;
                        }
                        else if (dayDiff > 0) {
                            fine = fine + dayDiff * this.FineRuleList[1].FineAmount;
                            dayDiff = 0;
                        }

                    }
                    else if (this.FineRuleList[1].IsPerWeek) {

                        var dayDiff = 0;
                        let weekDiff = 0;
                        dayDiff = this.dateDiff1(this.FeeComponentList[i].DueAlertDate, CurrentDate)
                        weekDiff = Math.ceil(dayDiff / 7);
                        if (weekDiff > this.FineRuleList[1].MaxFineDays) {
                            weekDiff = this.FineRuleList[1].MaxFineDays;
                        }

                        if (fine == 0 && weekDiff > 0) {
                            fine = weekDiff * this.FineRuleList[1].FineAmount;
                            dayDiff = 0;
                        }
                        else if (weekDiff > 0) {
                            fine = fine + weekDiff * this.FineRuleList[1].FineAmount;
                            dayDiff = 0;
                        }

                    }
                    else if (this.FineRuleList[1].IsPerMonth) {
  
                        var dayDiff = 0;
                        let monthDiff = 0;
                        dayDiff = this.monthDiff(new Date(this.FeeComponentList[i].DueAlertDate), new Date(this.SetModel.SetPaymentDate))
                        monthDiff = dayDiff;
                      
                        if (monthDiff > this.FineRuleList[1].MaxFineDays) {
                            monthDiff = this.FineRuleList[1].MaxFineDays;
                        }

                        if (fine == 0 && monthDiff > 0) {
                            fine = monthDiff * this.FineRuleList[1].FineAmount;
                            dayDiff = 0;
                        }
                        else if (monthDiff > 0) {
                            fine = fine + monthDiff * this.FineRuleList[1].FineAmount;
                            dayDiff = 0;
                        }

                    }
                    else

                        if (this.FineRuleList[1].IsFixed) {
                          
                            if (CurrentDate > new Date(this.FeeComponentList[i].DueAlertDate)) {

                                if (fine == 0) {
                                    fine = this.FineRuleList[1].FineAmount;
                                }
                                else {
                                    fine = fine + this.FineRuleList[1].FineAmount;
                                }
                            }
                        }

                }

            }

        }
          
       
        this.FeeReceiptModel.Fine = fine;
       
        this.GetTotalPaidAmount();

    }
    GetAllPaymentReceipt() {
        this.Submitted = true;
        this.spinnerService.show()
        this.financeService.GetAllPaymentReceipt(JSON.stringify(this.SearchModel)).subscribe((response) => {
            this.PaymentReceiptList = response.PaymentReceiptList;
            this.spinnerService.hide()
            this.Submitted = false;

            var TotalPaidAmounts = 0;
            var TotalFineAmounts = 0;
            for (var i = 0; i < this.PaymentReceiptList.length; i++) {
                TotalPaidAmounts = TotalPaidAmounts + this.PaymentReceiptList[i].PaidAmount;
                TotalFineAmounts = TotalFineAmounts + this.PaymentReceiptList[i].Fine;
            }
            this.TotalPaidAmounts = TotalPaidAmounts;
            this.TotalFineAmounts = TotalFineAmounts;

        });
    }
    GetOpeningBalance() {
        var itembo = {
            ItemId: this.FeeReceiptModel.StudentID
        }
        this.lookupService.GetLookupsList('OpeningBalance', JSON.stringify(itembo)).subscribe((data) => {
            if (data.LookupItems.length > 1) {
                this.FeeReceiptModel.Fine = 0;
                this.FeeReceiptModel.FeeAmount = 0;
                this.FeeReceiptModel.OpeningAmount = data.LookupItems[1].OpeningAmount;
                this.GetTotalPaidAmount();
            }
            else {
                this.FeeReceiptModel.OpeningAmount = 0;
            }
        });
    };
    GetTotalPaidAmount() {
        this.FeeReceiptModel.BankCharges = this.FeeReceiptModel.BankCharges == undefined ? 0 : this.FeeReceiptModel.BankCharges;
        this.FeeReceiptModel.PaidAmount = this.FeeReceiptModel.FeeAmount + this.FeeReceiptModel.Fine
            + this.FeeReceiptModel.OpeningAmount - this.FeeReceiptModel.WaiverAmount
      
        this.CalculatedPaidAmount = this.FeeReceiptModel.PaidAmount;
        this.FeeReceiptModel.ExcessAmount = (this.FeeReceiptModel.PayingAmount-this.TotalDueAmount-this.FeeReceiptModel.Fine-this.FeeReceiptModel.OpeningAmount)>0?(this.FeeReceiptModel.PayingAmount-this.TotalDueAmount-this.FeeReceiptModel.Fine-this.FeeReceiptModel.OpeningAmount):0;
       
        if(this.FeeReceiptModel.ExcessAmount==0 || this.FeeReceiptModel.PayingAmount==0){
        this.FeeReceiptModel.PayingAmount = this.FeeReceiptModel.PaidAmount;
        }
       
       
    }
    GetTotalPaidAmount1() {
        this.FeeReceiptModel.BankCharges = this.FeeReceiptModel.BankCharges == undefined ? 0 : this.FeeReceiptModel.BankCharges;
        this.FeeReceiptModel.PaidAmount = this.FeeReceiptModel.FeeAmount + this.FeeReceiptModel.Fine
            + this.FeeReceiptModel.OpeningAmount - this.FeeReceiptModel.WaiverAmount

        this.CalculatedPaidAmount = this.FeeReceiptModel.PaidAmount;
        this.FeeReceiptModel.ExcessAmount = (this.FeeReceiptModel.PayingAmount-this.TotalDueAmount-this.FeeReceiptModel.Fine-this.FeeReceiptModel.OpeningAmount)>0?(this.FeeReceiptModel.PayingAmount-this.TotalDueAmount-this.FeeReceiptModel.Fine-this.FeeReceiptModel.OpeningAmount):0;
        
       
    }
    GetFeeInstallmentComponentNew() {
        this.IsConcession = true;
        this.financeService.GetFeeInstallmentComponentNew(this.FeeReceiptModel.ClassID, this.FeeReceiptModel.StudentID).subscribe((response) => {
            if (response.webResponse.Status) {
                this.FeeComponentList = response.FeeComponentList;

                this.ComponentList = response.ComponentList;
                this.isCollapsed1 = false;
                var TotalPaidAmount = 0;
                var TotalPayableAmount = 0;
                var TotalDueAmount = 0;
                for (var i = 0; i < this.FeeComponentList.length; i++) {

                    TotalPayableAmount = TotalPayableAmount + (this.FeeComponentList[i].TotalAmount - this.FeeComponentList[i].DiscountAmount);
                    TotalPaidAmount = TotalPaidAmount + this.FeeComponentList[i].PaidAmount;                    
                }

                this.TotalPayableAmount = Math.round(TotalPayableAmount).toFixed(3);
                this.TotalPaidAmount = Math.round(TotalPaidAmount).toFixed(3);
                this.TotalDueAmount = TotalPayableAmount-TotalPaidAmount;
                
            }
        });
    };
    AllStudentConcession() {
        this.IsConcession = true;
        this.spinnerService.show();

        this.financeService.StudentConcession(this.FeeReceiptModel.ClassID, this.FeeReceiptModel.StudentID).subscribe((response) => {
            if (response.webResponse.Status) {
                this.ListStudentConcession = response.ListStudentConcession;
                this.InstallmentComponentList = response.FeeComponentList;
                this.InstallmentList = response.InstallmentList;
                this.isCollapsed = true;
                this.spinnerService.hide();

            }

        });
    };
    GetPaymentReceipt() {

        this.Submitted = true;
        this.spinnerService.show();
        this.SearchModel.RecType = 'FEE';

        this.financeService.GetPaymentReceipt(JSON.stringify(this.SearchModel)).subscribe((response) => {
            this.PaymentReceiptList = response.PaymentReceiptList;
            this.Submitted = false;

            var TotalPaidAmounts = 0;
            var TotalFineAmounts = 0;

            for (var i = 0; i < this.PaymentReceiptList.length; i++) {


                TotalPaidAmounts = TotalPaidAmounts + this.PaymentReceiptList[i].PaidAmount;
                TotalFineAmounts = TotalFineAmounts + this.PaymentReceiptList[i].Fine;

            }
            this.TotalPaidAmounts = TotalPaidAmounts;
            this.TotalFineAmounts = TotalFineAmounts;

        });
    }
    GetDueGeneralPayment() {
        this.spinnerService.show()

        this.financeService.GetDueGeneralPayment(JSON.stringify(this.GeneralPaymentModel)).subscribe((response) => {
            if (response.GeneralPaymentList != null && response.GeneralPaymentList.length > 0) {
                this.GeneralPaymentList = response.GeneralPaymentList;
            }

            this.globalHelper.CheckActionStatus(response);
            this.spinnerService.hide()
        });

    };

    GetOtherPaymentHistory() {
        this.spinnerService.show()
        this.financeService.GetOtherPaymentHistory(this.GeneralPaymentModel.StudentID).subscribe((response) => {
            this.OtherPaymentHistoryList = response.OtherPaymentHistoryList;

            this.spinnerService.hide()

        });

    };

    GetDueBookStore() {
        this.spinnerService.show()
        this.transportService.GetDueBookStore(this.GeneralPaymentModel.StudentID).subscribe((data) => {
            this.spinnerService.hide()
            this.FeeReceiptList = data.FeeReceiptList;

        });
    };
    
    GetPaymentMode() {
        this.lookupService.GetLookupsList('PaymentMode', '').subscribe((data) => {
            this.PaymentModeList = data.LookupItems;

        });
    };

    BindBank() {
        this.lookupService.GetLookupsList('BankAll', '').subscribe((response) => {
            this.BankList = response.LookupItems;

        });
    };

    BindBankBranch() {
        this.lookupService.GetLookupsList('Bank', '').subscribe((response) => {
            this.Bank = response.LookupItems;
            // this.FeeReceiptModel.BankAccountID= 0;
        });
    };
    ngOnInit() {
        this.GetFineRule();
        this.GetPaymentMode();
        this.BindBank();
        this.BindBankBranch();
    }

    // Generate Challan
    ChallanNo='';
    SaveStudentFeeChallan() {
        this.FineAlert = '';
        if (this.FeeReceiptModel.PaymentModeID == 0 || this.FeeReceiptModel.PaymentModeID == undefined) {
            this.PaymentAlert = 'Please Select Payment Mode';
            return;

        }

        if (this.CalculatePayingAmount()) {

            //if (this.ValidAmount()) {
            if (this.FeeReceiptModel.OpeningAmount < this.FeeReceiptModel.PayingAmount) {
                this.FeeReceiptModel.OpeningAmount = this.FeeReceiptModel.OpeningAmount;
            }
            else {
                this.FeeReceiptModel.OpeningAmount = this.FeeReceiptModel.PayingAmount;
            }
            if (this.FeeReceiptModel.PaidAmount > 0) {
               
                this.spinnerService.show()
                this.FeeReceiptModel.ChequeDate = this.FeeReceiptModel.ChequeDate != null && this.FeeReceiptModel.ChequeDate != '' ? moment(this.FeeReceiptModel.ChequeDate).format('DD-MMM-YYYY') : '';
                this.FeeReceiptModel.PaymentDate = this.FeeReceiptModel.PaymentDate != null ? moment(this.FeeReceiptModel.PaymentDate).format('DD-MMM-YYYY') : '';
                this.financeService.SaveStudentFeeChallan(JSON.stringify(this.FeeReceiptModel), JSON.stringify(this.FeeComponentList)).subscribe((response) => {
                    this.globalHelper.CheckActionStatus(response);
                    this.spinnerService.hide()
                   
                    if (response.webResponse.Status) {
                        this.ChallanNo = response.ChallanNo;                      
                    }

                });
            }
        }


    }

    // Print Challan

  PrintFeeChallan(ReceiptNo) {
    var Template = this.globalHelper.GetLoginToken().ChallanTemplate;
    var url = Template + "?PrintTypes=FeeReceipt&ReceiptNo=" + ReceiptNo;
    this.globalHelper.OpenPopUpWindow(url, "yes", "950", "1000");
  };

}
