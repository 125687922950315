import { Injectable, Inject } from '@angular/core';
import { WINDOW } from './Window';


@Injectable()
export class CommonService {

    constructor(@Inject(WINDOW) private window: Window) {
    }

    getHostname() : string {
        return this.window.location.hostname;
    }

    
}