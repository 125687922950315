import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as moment from 'moment';

@Component({
  selector: 'app-student-reimbursement',
  templateUrl: './student-reimbursement.component.html',
  styleUrls: ['./student-reimbursement.component.css']
})
export class StudentReimbursementComponent implements OnInit {


  
  StudentReimbursementList:any;
  Count:number=0;
  ClassList:any;
  SectionList:any;
  ClassSectionList:any;
  currentPage = 1;
  pageSize = 20;
  recordCount = 0;
  totalPages = 0;

  constructor(private financeService: FinanceService, private LookupService: LookupService, public globalHelper: GlobalHelper,
    private spinnerService: Ng4LoadingSpinnerService) { }


 

StudentSearchModel = {
    ClassID: 0,
    SectionID: 0,
    StudentNo: '',
    Print:'',
    CurrentIndex:0,
    PageSize:20,
    FromDate:moment(new Date()).format('DD-MMM-YYYY'),
    ToDate:moment(new Date()).format('DD-MMM-YYYY'),
};       

ClearSearch(){
  this.StudentSearchModel.StudentNo = '';
  this.StudentSearchModel.ClassID = 0;
  this.StudentSearchModel.SectionID = 0;
  this.StudentSearchModel.Print='';
  this.StudentSearchModel.FromDate=moment(new Date()).format('DD-MMM-YYYY');
  this.StudentSearchModel.ToDate=moment(new Date()).format('DD-MMM-YYYY');
  this.StudentReimbursementList=[];
  
}
 



GetStudentReimbursement() {
  this.StudentReimbursementList=[];
  this.spinnerService.show();
  this.StudentSearchModel.Print='';
  this.StudentSearchModel.CurrentIndex = this.currentPage;
  this.StudentSearchModel.PageSize = this.pageSize;

  this.StudentSearchModel.FromDate =this.StudentSearchModel.FromDate!=null && this.StudentSearchModel.FromDate!=''?moment(this.StudentSearchModel.FromDate).format('DD-MMM-YYYY'):'';
  this.StudentSearchModel.ToDate =this.StudentSearchModel.ToDate!=null && this.StudentSearchModel.ToDate!=''?moment(this.StudentSearchModel.ToDate).format('DD-MMM-YYYY'):'';
 
    this.financeService.GetStudentReimbursement(JSON.stringify(this.StudentSearchModel)).subscribe((data: any) => {
        this.StudentReimbursementList = data.StudentReimbursementList;
        this.spinnerService.hide();
        this.recordCount = 0;
        if (data.StudentReimbursementList != null && data.StudentReimbursementList.length > 0) {
            this.recordCount = data.StudentReimbursementList[0].RecordCount;
           
        }
       
        this.numberOfPages();

    });
};


  ngOnInit() {
    this.GetClass();
    this.GetStudentReimbursement();
    this.GetSection();
    this.GetClassSection();  
    
  }


  GetClass() {
    this.LookupService.GetLookupsList('Class', '').subscribe((data: any) => {
        this.ClassList = data.LookupItems;

    });
};
PaymentModeList=[];
GetPaymentMode() {
  this.LookupService.GetLookupsList('PaymentMode', '').subscribe((data) => {
      this.PaymentModeList = data.LookupItems;

  });
};
GetSection() {
  this.spinnerService.show();
    this.StudentSearchModel.SectionID = 0;
    this.StudentReimbursementList=[];
    var itemBo = {
        ItemId: this.StudentSearchModel.ClassID

    };

    this.LookupService.GetLookupsList('SectionName', JSON.stringify(itemBo)).subscribe((data: any) => {
        this.SectionList = data.LookupItems;
        this.spinnerService.hide();
    });
};

GetClassSection() {
  this.spinnerService.show();
    this.StudentReimbursementModel.SectionID = 0;
    this.StudentReimbursementModel.StudentID = 0;

    var itemBo = {
        ItemId: this.StudentReimbursementModel.ClassID

    };

    this.LookupService.GetLookupsList('SectionName', JSON.stringify(itemBo)).subscribe((data: any) => {
        this.ClassSectionList = data.LookupItems;
        this.spinnerService.hide();

    });
};

numberOfPages() {
  this.totalPages = Math.ceil(this.recordCount / this.pageSize);
}

nextPage() {
  this.currentPage = this.currentPage + 1;
}

pageSizes() {
  this.currentPage = 1;
  this.recordCount = 0;
  this.totalPages = 0;
}
// GetStudent() {
//   this.spinnerService.show();
//     this.StudentReimbursementModel.StudentID = 0;
//     var itemBo = {
//         ItemId: this.StudentReimbursementModel.ClassID,
//         ItemId2: this.StudentReimbursementModel.SectionID
//     };
//     this.LookupService.GetLookupsList('Student', JSON.stringify(itemBo)).subscribe((data: any) => {
//         this.StudentList = data.LookupItems;
//         this.spinnerService.hide();
//     });
// };

AlertMesg='';
StudentList: any;
FilterModel = {
  ParentID: 0,
  FeeBookNo:'',
  FirstName: '',
  MiddleName:'',
  LastName:'',
  StudentNo: '',  
}

GetStudentDetails() {
    
  if ((this.FilterModel.StudentNo != null && this.FilterModel.StudentNo != '' && this.FilterModel.StudentNo != '0') ||
    (this.FilterModel.ParentID != null && this.FilterModel.ParentID != 0)||
    (this.FilterModel.FeeBookNo != null && this.FilterModel.FeeBookNo != "")  || this.FilterModel.FirstName != '' || this.FilterModel.MiddleName != '' || this.FilterModel.LastName != '') {
    this.spinnerService.show();
    this.AlertMesg = '';
  this.Clear();
    this.financeService.GetStudentDetailsForReimbursement(this.FilterModel.StudentNo, '', this.FilterModel.ParentID, this.FilterModel.FirstName, this.FilterModel.MiddleName, this.FilterModel.LastName,this.FilterModel.FeeBookNo).subscribe((data: any) => {
      this.spinnerService.hide();
      this.StudentList = data.StudentList;    
    

    });
  }
  else {
    this.AlertMesg = 'Please Enter  GR No./First Name/Last Name'
  }
};

ClearFilter() {
  this.FilterModel.StudentNo = '';
  this.FilterModel.ParentID = 0;
  this.FilterModel.FirstName = '';
  this.FilterModel.MiddleName = '';
  this.FilterModel.LastName = '';
  this.FilterModel.FeeBookNo = '';
  this.StudentList=[];
  this.AlertMesg='';
 
}

StudentReimbursementModel = {
  ClassID:0,
  SectionID:0,
  StudentID: 0,
  DebitAmount:0,  
  StudentNo:'',
  PaymentModeID:0,
  ChequeNo:'',
  ChequeDate:'',
  PaymentDate:moment(new Date()).format('DD-MMM-YYYY'),
  Remarks:'',
  
 }

 ValidityState(){
  return this.StudentReimbursementModel.StudentID==0||this.StudentReimbursementModel.PaymentModeID==0||this.StudentReimbursementModel.PaymentDate=='';
 }

 MSGSelect='';
 Submitted=false;
 SaveStudentReimbursement() {
  this.MSGSelect='';
  this.Submitted=true;
  if(!this.ValidityState()){   
    var sum=0
    for(var i=0;i<this.StudentList.length;i++){
      sum=sum+this.StudentList[i].Amount;
    }
    if(this.StudentReimbursementModel.DebitAmount>sum){
      this.MSGSelect='Reimbursement can not be greater than Excess. ';
      return;
    }
   this.spinnerService.show();
   this.Submitted=false;
   this.StudentReimbursementModel.ChequeDate =this.StudentReimbursementModel.ChequeDate!=null && this.StudentReimbursementModel.ChequeDate!=''?moment(this.StudentReimbursementModel.ChequeDate).format('DD-MMM-YYYY'):'';
   this.StudentReimbursementModel.PaymentDate =this.StudentReimbursementModel.PaymentDate!=null?moment(this.StudentReimbursementModel.PaymentDate).format('DD-MMM-YYYY'):'';
  
     this.financeService.SaveStudentReimbursement(JSON.stringify(this.StudentReimbursementModel)).subscribe((data: any) => {
       if (data.webResponse.Status) {
         this.GetStudentReimbursement(); 
         this.GetStudentDetails();     
         this.Clear();   
       }
     
       this.globalHelper.CheckActionStatus(data);
       this.spinnerService.hide();
     });
    }
    else{
      this.MSGSelect='Please select student';
    }
 };
 
 Clear() {
     this.StudentReimbursementModel.StudentNo = '';
     this.StudentReimbursementModel.ClassID = 0;
     this.StudentReimbursementModel.SectionID = 0;
      this.StudentReimbursementModel.DebitAmount = 0;
     this.StudentReimbursementModel.StudentID = 0;
     this.StudentReimbursementModel.PaymentModeID = 0;
     this.StudentReimbursementModel.ChequeNo='';
     this.StudentReimbursementModel.ChequeDate='';
     this.StudentReimbursementModel.PaymentDate=moment(new Date()).format('DD-MMM-YYYY');
     this.StudentReimbursementModel.Remarks='';
     this.MSGSelect='';
     this.Submitted=false;
 }
 

GetID(item) {
   this.StudentReimbursementModel.StudentID = item.StudentID ;  
   this.StudentReimbursementModel.DebitAmount = item.Amount ;  
   if(this.StudentReimbursementModel.StudentID>0){
    this.MSGSelect='';
   }
  
}

SetID(item) {
  this.CancelReimbursementModel.StudentWalletID = item.StudentWalletID ;  
  this.CancelReimbursementModel.DebitAmount=item.DebitAmount ;  
  this.CancelReimbursementModel.PaymentDate=item.PaymentDate ;  
  this.CancelReimbursementModel.Name=item.Name ;  
  this.CancelReimbursementModel.Remarks='';
}

CancelReimbursementModel={
  StudentWalletID:0,
  DebitAmount:0,
  PaymentDate:'',
  Name:'',
  Remarks:'',
}

CancelStudentReimbursement() {
    
  this.spinnerService.show();
  this.CancelReimbursementModel.PaymentDate =this.CancelReimbursementModel.PaymentDate!=null?moment(this.CancelReimbursementModel.PaymentDate).format('DD-MMM-YYYY'):'';
  
    this.financeService.CancelStudentReimbursement(JSON.stringify(this.CancelReimbursementModel)).subscribe((data: any) => {
      if (data.webResponse.Status) {
        this.GetStudentReimbursement();    
         
      }
      this.globalHelper.CheckActionStatus(data);
      this.spinnerService.hide();
    });
};


DownloadStudentReimbursement() {
  this.StudentReimbursementList=[];
  this.spinnerService.show();
  this.StudentSearchModel.Print='XLS';
  this.StudentSearchModel.CurrentIndex = 0;
  this.StudentSearchModel.PageSize = 0;
  this.StudentSearchModel.FromDate =this.StudentSearchModel.FromDate!=null && this.StudentSearchModel.FromDate!=''?moment(this.StudentSearchModel.FromDate).format('DD-MMM-YYYY'):'';
  this.StudentSearchModel.ToDate =this.StudentSearchModel.ToDate!=null && this.StudentSearchModel.ToDate!=''?moment(this.StudentSearchModel.ToDate).format('DD-MMM-YYYY'):'';
 
    this.financeService.GetStudentReimbursement(JSON.stringify(this.StudentSearchModel)).subscribe((data: any) => {
      window.location = data.FilePath;  
        this.spinnerService.hide();

    });
};

}
