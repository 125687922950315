import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TransportService } from 'src/app/services/Transport/transport.service';
import * as moment from 'moment';

@Component({
  selector: 'app-fee-receipt-cancel',
  templateUrl: './fee-receipt-cancel.component.html',
  styleUrls: ['./fee-receipt-cancel.component.css']
})
export class FeeReceiptCancelComponent implements OnInit {
  FilePath: any;
  CancelModel = {
    ReceiptNo: '',
    SectionID: 0,
    CancelRemarks: "",
    CancelStatus: 'N',
    PaymentMode: '',
    FeePaymentID: 0
  }
  SearchModel = {
    StudentID: 0,
    'Print': "",
    SectionID: 0,
    RecType: '0',
    CancelStatus: '0',
    ClassID: 0,
    StudentNo: '',
    FromDate: moment(Date.now()).format("DD-MMM-YYYY"),
    PaymentModeID: 0,
    ToDate: moment(Date.now()).format("DD-MMM-YYYY"),
    CreatedBy: '0'
  }
  ClearSearch() {
    this.SearchModel.ClassID = 0;
    this.SearchModel.SectionID = 0;
    this.SearchModel.StudentID = 0;
    this.SearchModel.PaymentModeID = 0;
    this.SearchModel.CreatedBy = '0';
    this.SearchModel.RecType = '0';
    this.SearchModel.CancelStatus = '0';
    this.SearchModel.StudentNo = '';
    this.PaymentReceiptList = [];
  }
  outerIndex = 0;
  PaymentModeList: any;
  CashierList: any;
  PaymentReceiptList: any;
  TotalPaidAmounts = 0;
  TotalFineAmounts = 0;
  ClassList: any;
  StudentList: any;
  SectionList: any;
  hidePrint: any;
  TotalCancelPaidAmounts = 0;
  TotalCancelFineAmounts = 0;
  TotalExcessPaidAmounts=0;
  TotalCancelExcessAmounts=0;
  constructor(private transportService: TransportService, private spinnerService: Ng4LoadingSpinnerService, private financeService: FinanceService, private lookupService: LookupService, public globalHelper: GlobalHelper) { }

  ClearCancel() {
    this.CancelModel.ReceiptNo = '';
    this.CancelModel.CancelRemarks = '';
  }

  GetReceiptNo(item) {

    this.CancelModel.ReceiptNo = item.ReceiptNo;
    this.CancelModel.FeePaymentID = item.FeePaymentID;

  }


  SaveCancelReceipt() {

    this.spinnerService.show();
    this.financeService.SaveCancelReceipt(JSON.stringify(this.CancelModel)).subscribe((response) => {
      this.globalHelper.CheckActionStatus(response);
      this.GetAllPaymentReceipt();
      this.spinnerService.hide();

    });
  }


  GetPaymentMode() {
    this.lookupService.GetLookupsList('PaymentMode', '').subscribe((data) => {
      this.PaymentModeList = data.LookupItems;

    });
  };
  GetClass() {
    this.lookupService.GetLookupsList('Class', '').subscribe((data) => {
      this.ClassList = data.LookupItems;
    });

  };

  GetStudent() {
    this.SearchModel.StudentID = 0;
    var itembo = {
      ItemId: this.SearchModel.ClassID,
      ItemId2: this.SearchModel.SectionID
    }
    this.lookupService.GetLookupsList('StudentList', JSON.stringify(itembo)).subscribe((data) => {
      this.StudentList = data.LookupItems;


    });
  };

  GetSection() {
    this.SearchModel.SectionID = 0;
    this.SearchModel.StudentID = 0;
    var ItemBo = {
      'ItemId': this.SearchModel.ClassID
    }
    this.lookupService.GetLookupsList('SectionName', JSON.stringify(ItemBo)).subscribe((data) => {
      this.SectionList = data.LookupItems;

    });
  };
  GetCashier() {
    this.lookupService.GetLookupsList('Cashier', '').subscribe((data) => {
      this.CashierList = data.LookupItems;
    });
  };
  Submitted = false;
  GetAllPaymentReceipt() {
    this.Submitted = true;
    if (!this.ValidateDropdownSearch()) {
      this.spinnerService.show();
      this.SearchModel.FromDate = this.SearchModel.FromDate != null ? moment(this.SearchModel.FromDate).format('DD-MMM-YYYY') : '';
      this.SearchModel.ToDate = this.SearchModel.ToDate != null ? moment(this.SearchModel.ToDate).format('DD-MMM-YYYY') : '';

      this.financeService.GetAllPaymentReceipt(JSON.stringify(this.SearchModel)).subscribe((response) => {
        this.PaymentReceiptList = response.PaymentReceiptList;
        this.Submitted = false;
        this.spinnerService.hide();
        var TotalPaidAmounts = 0;
        var TotalFineAmounts = 0;
        var TotalCancelPaidAmounts = 0;
        var TotalCancelFineAmounts = 0;
        var TotalExcessPaidAmounts=0;
        var TotalCancelExcessAmounts=0;

        for (var i = 0; i < this.PaymentReceiptList.length; i++) {
          TotalPaidAmounts = TotalPaidAmounts + this.PaymentReceiptList[i].PaidAmount;
          TotalFineAmounts = TotalFineAmounts + this.PaymentReceiptList[i].Fine;
          TotalExcessPaidAmounts=TotalExcessPaidAmounts+this.PaymentReceiptList[i].ExcessAmount;
          if (this.PaymentReceiptList[i].CancelStatus == 'Yes') {
            TotalCancelPaidAmounts = TotalCancelPaidAmounts + this.PaymentReceiptList[i].PaidAmount;
            TotalCancelFineAmounts = TotalCancelFineAmounts + this.PaymentReceiptList[i].Fine;
            TotalCancelExcessAmounts = TotalCancelExcessAmounts + this.PaymentReceiptList[i].ExcessAmount;
          }
        }
        this.TotalCancelPaidAmounts = TotalCancelPaidAmounts;
        this.TotalCancelFineAmounts = TotalCancelFineAmounts;
        this.TotalCancelExcessAmounts=TotalCancelExcessAmounts;
        this.TotalPaidAmounts = TotalPaidAmounts;
        this.TotalFineAmounts = TotalFineAmounts;
        this.TotalExcessPaidAmounts = TotalExcessPaidAmounts;
        this.search(this.PaymentReceiptList);
      });
    }
  }

  ValidateDropdownSearch() {
    return this.SearchModel.FromDate == "" || this.SearchModel.ToDate == "";

  };
  XLSReport() {


    if (!this.ValidateDropdownSearch()) {

      this.spinnerService.show();
      this.SearchModel.Print = 'XLS';
      this.financeService.GetPaymentReceipt(JSON.stringify(this.SearchModel)).subscribe((response) => {
        if (response.webResponse.Status) {

          this.FilePath = response.FilePath;
          window.location = response.FilePath;

        }
        this.spinnerService.hide();
      });
    }
  }
  PrintFeeReceipt(RecType, ReceiptNo, PrintType, CopyType, Cancel) {

    this.hidePrint = false;
    var Template = this.globalHelper.GetLoginToken().FeeTemplate;
    if (Cancel == 'Yes') {
      let CopyType = 'C';
    }

    //   var url = "../../../../App/Template/FeeReceipt/" + Template + "?PrintType=FeeReceipt&ReceiptNo=" + ReceiptNo + "&PrintType=" + PrintType + "&CopyType=" + CopyType;
    var url = Template + "?PrintType=FeeReceipt&ReceiptNo=" + ReceiptNo + "&PrintType=" + PrintType + "&CopyType=" + CopyType;
    this.globalHelper.OpenPopUpWindow(url, "yes", "950", "1000");
  };
  search(itemList) {

  }
  ngOnInit() {
    this.GetCashier();
    this.GetPaymentMode();
    this.GetClass();
    this.GetSection();
    this.GetStudent();
  }

}
