import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-admissiondocument',
  templateUrl: './admissiondocument.component.html',
  styleUrls: ['./admissiondocument.component.css']
})
export class AdmissionDocumentComponent implements OnInit {

  Submitted: boolean = false;
  DocumentCategoryList: any;
  ListDocument: any;

  constructor(private MasterService: MasterService, private LookupService: LookupService, private globalHelper: GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  AdmissionDocumentModel = {
    AdmissionDocumentID: 0,
    AdmissionDocumentName: '',
    DocumentCategoryID: 0,
    IsActive: true
  };

  Clear() {
    this.AdmissionDocumentModel.AdmissionDocumentID = 0;
    this.AdmissionDocumentModel.DocumentCategoryID = 0;
    this.AdmissionDocumentModel.IsActive = true;
    this.AdmissionDocumentModel.AdmissionDocumentName = '';
    this.Submitted = false;
  };

  GetAllAdmissionDoc = function () {
    this.spinnerService.show();
    this.MasterService.GetAllAdmissionDoc().subscribe((response: any) => {
      this.ListDocument = response.ListDocument;
      this.spinnerService.hide();
    });
  };

  
  ValidateDropdown() {
    return this.AdmissionDocumentModel.DocumentCategoryID == 0;
  };


  SaveAdmissionDoc(valid) {
    this.Submitted = true;
    if (valid && !this.ValidateDropdown()) {
      this.spinnerService.show();
      this.MasterService.SaveAdmissionDoc(JSON.stringify(this.AdmissionDocumentModel)).subscribe((data: any) => {
        if (data.webResponse.Status) {
          this.GetAllAdmissionDoc();
        }
        if (data.webResponse.Status && this.AdmissionDocumentModel.AdmissionDocumentID == 0) {
          this.Clear();
          this.GetAllAdmissionDoc();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
  };

  AdmissionDocMarkActive(item) {
    this.AdmissionDocumentModel.AdmissionDocumentID = item.AdmissionDocumentID;
    this.AdmissionDocumentModel.IsActive = item.IsActive == true ? false : true;
    this.spinnerService.show();
    this.MasterService.AdmissionDocMarkActive(JSON.stringify(this.AdmissionDocumentModel)).subscribe((data: any) => {
      if (data.webResponse.Status) {
        this.Clear();
        this.GetAllAdmissionDoc();
      }
      this.globalHelper.CheckActionStatus(data);
      this.spinnerService.hide();
    });

  };



  GetStatus(IsActive) {
    return IsActive ? 'Yes' : 'No';
  };

  Edit(obj) {

    this.AdmissionDocumentModel.AdmissionDocumentID = obj.AdmissionDocumentID;
    this.AdmissionDocumentModel.DocumentCategoryID = obj.DocumentCategoryID;
    this.AdmissionDocumentModel.AdmissionDocumentName = obj.AdmissionDocumentName;
    this.AdmissionDocumentModel.IsActive = obj.IsActive;


  };


  // Init and Lookup

  ngOnInit() {
    this.GetDocumentCategory();
    this.GetAllAdmissionDoc();
  }

  GetDocumentCategory() {

    this.LookupService.GetLookupsList('DocumentCategory', '').subscribe((data: any) => {
      this.DocumentCategoryList = data.LookupItems;
    });

  }



}
