import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as moment from 'moment';

@Component({
  selector: 'app-excess-reimbursement-report',
  templateUrl: './excess-reimbursement-report.component.html',
  styleUrls: ['./excess-reimbursement-report.component.css']
})
export class ExcessReimbursementReportComponent implements OnInit {



  
  StudentReimbursementList:any;
  Count:number=0;
  ClassList:any;
  SectionList:any;
  ClassSectionList:any;
  currentPage = 1;
  pageSize = 20;
  recordCount = 0;
  totalPages = 0;

  constructor(private financeService: FinanceService, private LookupService: LookupService, public globalHelper: GlobalHelper,
    private spinnerService: Ng4LoadingSpinnerService) { }


 

StudentSearchModel = {
    ClassID: 0,
    SectionID: 0,
    StudentNo: '',
    Print:'',
    CurrentIndex:0,
    PageSize:20,
    FromDate:moment(new Date()).format('DD-MMM-YYYY'),
    ToDate:moment(new Date()).format('DD-MMM-YYYY'),
};       

ClearSearch(){
  this.StudentSearchModel.StudentNo = '';
  this.StudentSearchModel.ClassID = 0;
  this.StudentSearchModel.SectionID = 0;
  this.StudentSearchModel.Print='';
  this.StudentSearchModel.FromDate=moment(new Date()).format('DD-MMM-YYYY');
  this.StudentSearchModel.ToDate=moment(new Date()).format('DD-MMM-YYYY');
  this.StudentReimbursementList=[];
  
}
 



GetReimbursementReport() {
  this.StudentReimbursementList=[];
  this.spinnerService.show();
  this.StudentSearchModel.Print='';
  this.StudentSearchModel.CurrentIndex = this.currentPage;
  this.StudentSearchModel.PageSize = this.pageSize;

  this.StudentSearchModel.FromDate =this.StudentSearchModel.FromDate!=null && this.StudentSearchModel.FromDate!=''?moment(this.StudentSearchModel.FromDate).format('DD-MMM-YYYY'):'';
  this.StudentSearchModel.ToDate =this.StudentSearchModel.ToDate!=null && this.StudentSearchModel.ToDate!=''?moment(this.StudentSearchModel.ToDate).format('DD-MMM-YYYY'):'';
 
    this.financeService.GetReimbursementReport(JSON.stringify(this.StudentSearchModel)).subscribe((data: any) => {
        this.StudentReimbursementList = data.StudentReimbursementList;
        this.spinnerService.hide();
        this.recordCount = 0;
        if (data.StudentReimbursementList != null && data.StudentReimbursementList.length > 0) {
            this.recordCount = data.StudentReimbursementList[0].RecordCount;
           
        }
       
        this.numberOfPages();

    });
};


  ngOnInit() {
    this.GetClass();
    this.GetReimbursementReport();
    this.GetSection();
    
    
  }


  GetClass() {
    this.LookupService.GetLookupsList('Class', '').subscribe((data: any) => {
        this.ClassList = data.LookupItems;

    });
};

GetSection() {
  this.spinnerService.show();
    this.StudentSearchModel.SectionID = 0;
    this.StudentReimbursementList=[];
    var itemBo = {
        ItemId: this.StudentSearchModel.ClassID

    };

    this.LookupService.GetLookupsList('SectionName', JSON.stringify(itemBo)).subscribe((data: any) => {
        this.SectionList = data.LookupItems;
        this.spinnerService.hide();
    });
};



numberOfPages() {
  this.totalPages = Math.ceil(this.recordCount / this.pageSize);
}

nextPage() {
  this.currentPage = this.currentPage + 1;
}

pageSizes() {
  this.currentPage = 1;
  this.recordCount = 0;
  this.totalPages = 0;
}
// GetStudent() {
//   this.spinnerService.show();
//     this.StudentReimbursementModel.StudentID = 0;
//     var itemBo = {
//         ItemId: this.StudentReimbursementModel.ClassID,
//         ItemId2: this.StudentReimbursementModel.SectionID
//     };
//     this.LookupService.GetLookupsList('Student', JSON.stringify(itemBo)).subscribe((data: any) => {
//         this.StudentList = data.LookupItems;
//         this.spinnerService.hide();
//     });
// };



DownloadStudentReimbursement() {
  this.StudentReimbursementList=[];
  this.spinnerService.show();
  this.StudentSearchModel.Print='XLS';
  this.StudentSearchModel.CurrentIndex = 0;
  this.StudentSearchModel.PageSize = 0;
  this.StudentSearchModel.FromDate =this.StudentSearchModel.FromDate!=null && this.StudentSearchModel.FromDate!=''?moment(this.StudentSearchModel.FromDate).format('DD-MMM-YYYY'):'';
  this.StudentSearchModel.ToDate =this.StudentSearchModel.ToDate!=null && this.StudentSearchModel.ToDate!=''?moment(this.StudentSearchModel.ToDate).format('DD-MMM-YYYY'):'';
 
    this.financeService.GetReimbursementReport(JSON.stringify(this.StudentSearchModel)).subscribe((data: any) => {
      window.location = data.FilePath;  
        this.spinnerService.hide();

    });
};

}
