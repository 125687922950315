import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as moment from 'moment';

@Component({
  selector: 'app-payment-mode-maping',
  templateUrl: './payment-mode-maping.component.html',
  styleUrls: ['./payment-mode-maping.component.css']
})

export class PaymentModeMapingComponent implements OnInit {

  Submitted: boolean = false;
  AccountList: any;
  PaymentModeList: any;
  FeeComponentSummaryReportList: any;
  TotalSum: number = 0;
  FilePath: any;

  constructor(private financeService: FinanceService, private LookupService: LookupService, 
    public globalHelper: GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  PaymentModeMapingModel = {
    PaymentModeMapID: 0,
    PaymentModeID: 0,
    PaymentMode: '',
    AccID: 0,
    IsActive: true,
    IsLock: false
  }

  Clear() {
    this.PaymentModeMapingModel.PaymentModeID =0
    this.PaymentModeMapingModel.PaymentModeMapID=0;
    this.PaymentModeMapingModel.AccID= 0;
    this.PaymentModeMapingModel.PaymentMode= '';
    this.PaymentModeMapingModel.IsActive=true;
    this.PaymentModeMapingModel.IsLock= false;
    this.Submitted = false;
    }
   

 
  GetPaymentModeMaping() {
    
    if (true) {
      this.spinnerService.show();
      this.financeService.GetPaymentModeMaping().subscribe((data: any) => {       
        this.spinnerService.hide();
        this.PaymentModeList = data.PaymentModeList;
      });
    }

  };

  GetStatus(IsActive) {
    return IsActive ? 'Yes' : 'No';
  };

  Edit(obj) {

    this.PaymentModeMapingModel.PaymentModeMapID = obj.PaymentModeMapID;
    this.PaymentModeMapingModel.PaymentModeID = obj.PaymentModeID;
    this.PaymentModeMapingModel.PaymentMode = obj.PaymentMode;
    this.PaymentModeMapingModel.AccID = obj.AccID;
    this.PaymentModeMapingModel.IsLock = obj.IsLock;
  };

  VaildateFields() {
    return this.PaymentModeMapingModel.AccID == 0 || this.PaymentModeMapingModel.PaymentModeID == 0;
  }


  SavePaymentModeMaping = function (valid) {

    this.Submitted = true;
    if (valid && !this.VaildateFields()) {

      if (this.PaymentModeMapingModel.IsLock) {
        var r = confirm("Are you sure you want to lock account ");
      }
      else {
        r = true;
      }
      if (r == true) {
        this.spinnerService.show();
        this.financeService.SavePaymentModeMaping(JSON.stringify(this.PaymentModeMapingModel)).subscribe((data: any) => {
          this.spinnerService.hide();
          this.globalHelper.CheckActionStatus(data);
          if (data.webResponse.Status) {
            this.Clear();
            this.GetPaymentModeMaping();
          }
        });
      }
    }
  };

  ngOnInit() {
    this.GetAccount();
    this.GetPaymentModeMaping();

  }

  GetAccount() {
    var itembo = {
      'ItemId': 1
    }
    this.LookupService.GetLookupsList('Acc', JSON.stringify(itembo)).subscribe((data: any) => {
      this.AccountList = data.LookupItems;
       this.AccountList.splice(0,1);
    });
  };

}


