import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


@Component({
  selector: 'app-classgroup',
  templateUrl: './classgroup.component.html',
  styleUrls: ['./classgroup.component.css']
})

export class ClassGroupComponent implements OnInit {


  Submitted: boolean = false;
  ClassGroupList: any;

  constructor(private MasterService: MasterService, private globalHelper: GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  ClassModel = {
    ClassGroupID: 0,
    ClassGroupName: '',
    IsActive: true
  };

  Clear() {
    this.ClassModel.ClassGroupID = 0;
    this.ClassModel.ClassGroupName = '';
    this.ClassModel.IsActive = true;
    this.Submitted = false;
  };

  GetAllClassGroup() {
    this.spinnerService.show();
    this.MasterService.GetAllClassGroup().subscribe((response: any) => {
      this.ClassGroupList = response.ClassGroupList;
      this.spinnerService.hide();
    });
  };


  SaveClassGroup(valid) {
    this.Submitted = true;
    if (valid) {
      this.spinnerService.show();
      this.MasterService.SaveClassGroup(JSON.stringify(this.ClassModel)).subscribe((data: any) => {
        if (data.webResponse.Status) {        
          this.GetAllClassGroup();
        }
        if (data.webResponse.Status && this.ClassModel.ClassGroupID==0) {
          this.Clear();
          this.GetAllClassGroup();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
  };

  ClassGroupMarkActive(item) {
    this.ClassModel.ClassGroupID = item.ClassGroupID;
    this.ClassModel.IsActive = item.IsActive == true ? false : true;
    this.MasterService.ClassGroupMarkActive(JSON.stringify(this.ClassModel)).subscribe((data: any) => {
      if (data.webResponse.Status) {
        this.Clear();
        this.GetAllClassGroup();
      }
      this.globalHelper.CheckActionStatus(data);
    });

  };

  ValidateDropdown() {
    return this.ClassModel.ClassGroupName == '';
  };


  GetStatus(IsActive) {
    return IsActive ? 'Yes' : 'No';
  };

  Edit(obj) {

    this.ClassModel.ClassGroupID = obj.ClassGroupID;
    this.ClassModel.ClassGroupName = obj.ClassGroupName;
    this.ClassModel.IsActive = obj.IsActive;


  };


  // Init and Lookup

  ngOnInit() {
   
    this.GetAllClassGroup();
  }

 
}
