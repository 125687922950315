import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as moment from 'moment'

@Component({
  selector: 'app-fee-installment-setup',
  templateUrl: './fee-installment-setup.component.html',
  styleUrls: ['./fee-installment-setup.component.css']
})
export class FeeInstallmentSetupComponent implements OnInit {
  InstallmentList: any;
  constructor(private financeService: FinanceService, private lookupService: LookupService,
    public globalHelper: GlobalHelper, private spinnerService: Ng4LoadingSpinnerService) { }

  GetDate(str) {
    var currentTime = new Date(str);
    var month = currentTime.getMonth() + 1;
    var day = currentTime.getDate();
    var year = currentTime.getFullYear();
    var date = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
    return date;
  };

  GetFeeInstallmentSchedule() {
    this.spinnerService.show();
    this.financeService.GetFeeInstallmentSchedule(this.FeeModel.InstallmentTypeID).subscribe((response) => {
      this.InstallmentList = response.InstallmentList;
      this.spinnerService.hide();
    });
  };

  SaveFeeInstallmentSchedule() {
    for (var i = 0; i < this.InstallmentList.length; i++) {
      if (this.InstallmentList[i].IsActive && this.InstallmentList[i].AlertDate != null && this.InstallmentList[i].AlertDate != '') {
        this.InstallmentList[i].AlertDate = moment(this.InstallmentList[i].AlertDate).format('DD-MMM-YYYY');
      }
    }
    this.financeService.SaveFeeInstallmentSchedule(this.FeeModel.InstallmentTypeID,JSON.stringify(this.InstallmentList)).subscribe((response) => {
      this.globalHelper.CheckActionStatus(response);

    });

  };



  ngOnInit() {
    this.GetInstallmentType();
    this.GetFeeInstallmentSchedule();
  }

  FeeModel = {
    InstallmentTypeID: 1,
    
  };

  InstallmentTypeList=[];
  GetInstallmentType () {

    this.lookupService.GetLookupsList('InstallmentType', '').subscribe( (data)=> {
        this.InstallmentTypeList = data.LookupItems;
        this.InstallmentTypeList.splice(0,1);
    });
  };
}
