import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-schoollocation',
  templateUrl: './schoollocation.component.html',
  styleUrls: ['./schoollocation.component.css']
})
export class SchoolLocationComponent implements OnInit {

  Submitted: boolean = false; 
  SchoolLocationList: any;
  
  constructor(private MasterService: MasterService, private globalHelper: GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  
  
  SchoolLocationModel = {
    SchoolLocationID: 0,
    SchoolLocationName: '',
    IsActive: true
  };

  Clear() {
    this.SchoolLocationModel.SchoolLocationID = 0;
    this.SchoolLocationModel.SchoolLocationName = '';
    this.SchoolLocationModel.IsActive = true;
    this.Submitted = false;
  };

  GetSchoolLocation = function () {
    this.spinnerService.show();
    this.MasterService.GetSchoolLocation().subscribe((response: any) => {
      this.SchoolLocationList = response.SchoolLocationList;
      this.spinnerService.hide();
    });
  };

  
  SaveSchoolLocation(valid) {
    this.Submitted = true;
    if (valid) {
      this.spinnerService.show();
      this.MasterService.SaveSchoolLocation(JSON.stringify(this.SchoolLocationModel)).subscribe((data: any) => {
        if (data.webResponse.Status) {
          this.GetSchoolLocation();
        }
        if (data.webResponse.Status && this.SchoolLocationModel.SchoolLocationID == 0) {
          this.Clear();
          this.GetSchoolLocation();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
    
  };

  SchoolLocationMarkActive(item) {
    this.SchoolLocationModel.SchoolLocationID = item.SchoolLocationID;
    this.SchoolLocationModel.IsActive = item.IsActive == true ? false : true;
    this.spinnerService.show();
    this.MasterService.SchoolLocationMarkActive(JSON.stringify(this.SchoolLocationModel)).subscribe((data: any) => {
      if (data.webResponse.Status) {
        this.Clear();
        this.GetSchoolLocation();
      }
      this.globalHelper.CheckActionStatus(data);
      this.spinnerService.hide();
    });

  };

  
  Edit(obj) {

    this.SchoolLocationModel.SchoolLocationID = obj.SchoolLocationID;
    this.SchoolLocationModel.SchoolLocationName = obj.SchoolLocationName;
    this.SchoolLocationModel.IsActive = obj.IsActive;

  };


  // Init and Lookup

  ngOnInit() {
   
    this.GetSchoolLocation();
  }



}
