import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalHelper } from '../Common/global.service';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor(private globalHelper:GlobalHelper, private httpclient: HttpClient) { }

  UploadFeeBookStudent(StudentData,objStudent): Observable<any> {

    let formData = new FormData();
    formData.append("StudentData", StudentData);
    formData.append("objStudent", objStudent);
  return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Student/UploadFeeBookStudent', formData);
  }
  UpdateFeeBookStudent(student):Observable<any>{  
    
    let formData = new FormData();
    formData.append("student", student);     
  return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Student/UpdateFeeBookStudent', formData);
}
  UploadStudent(StudentData,objStudent): Observable<any> {

    let formData = new FormData();
    formData.append("StudentData", StudentData);
    formData.append("objStudent", objStudent);
  return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Student/UploadStudent', formData);
  }
  SaveStudentOpeningBalance(StudentData,objStudent): Observable<any> {

    let formData = new FormData();
    formData.append("StudentData", StudentData);
    formData.append("objStudent", objStudent);
  return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Student/UploadStudent', formData);
  }
  UpdateStudent(student):Observable<any>{  
    
      let formData = new FormData();
      formData.append("student", student);     
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Student/UpdateStudent', formData);
  }
  // STUDENT REGISTRATION...........
  SaveStudentRegistration(data, StudentAction): Observable<any> {

    let formData = new FormData();
    formData.append("objStudentRegistration", data);
    formData.append("StudentAction", StudentAction);
  return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Student/SaveStudentRegistration', formData);
  }
  GetEnquiryNo(EnquiryNo):Observable<any>{  
    let param='EnquiryNo=' +EnquiryNo; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Student/GetEnquiryNo?'
      +encodeURI(param),{headers:reqHeader});
  }
  GetParentDetails(ParentID, StaffIdentity):Observable<any>{  
    let param='ParentID=' +ParentID+'&StaffIdentity='+StaffIdentity; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Student/GetParentDetails?'
      +encodeURI(param),{headers:reqHeader});
  }
  GetStudentByApplicationSubmitted(strStudent):Observable<any>{  
    let param='strStudent=' +strStudent; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Student/GetStudentByApplicationSubmitted?'
      +encodeURI(param),{headers:reqHeader});
  }
  SendNotificationForAdmissionTest(StudentList, TestDt, TestTime, TestVenue, DefaultEmailIDList, CC, Subject): Observable<any> {

    let formData = new FormData();
    formData.append("StudentList", StudentList);
    formData.append("TestDt", TestDt);
    formData.append("TestTime", TestTime);
    formData.append("TestVenue", TestVenue);
    formData.append("DefaultEmailIDList", DefaultEmailIDList);
    formData.append("CC", CC);
    formData.append("Subject", Subject);
  return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Student/SendNotificationForAdmissionTest', formData);
  }
  UpdateTestAndVenue(strStudent, TestStudentList): Observable<any> {

    let formData = new FormData();
    formData.append("strStudent", strStudent);
    formData.append("TestStudentList", TestStudentList);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Student/UpdateTestAndVenue', formData);
  }
  TestAction(TestStudentList,StudentAction): Observable<any> {

    let formData = new FormData();
    formData.append("TestStudentList", TestStudentList);
    formData.append("StudentAction", StudentAction);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Student/TestAction', formData);
  }
 
  UpdatePreAdminssionRemarks(Remarks,StudentID):Observable<any>{  
    let param='Remarks=' +Remarks+'&StudentID='+StudentID; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Student/TestAction?'
      +encodeURI(param),{headers:reqHeader});
  }
  GetDocumentByStudentID(StudentID):Observable<any>{  
    let param='StudentID=' +StudentID; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Student/GetDocumentByStudentID?'
      +encodeURI(param),{headers:reqHeader});
  }
  GetStudentProfileByStudentID(StudentID):Observable<any>{  
    let param='StudentID=' +StudentID; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Student/GetStudentProfileByStudentID?'
      +encodeURI(param),{headers:reqHeader});
  }
  SaveRegistrationApplication(objRegistration):Observable<any>{  
    let param='objRegistration=' +objRegistration; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Student/SaveRegistrationApplication?'
      +encodeURI(param),{headers:reqHeader});
  }
  SaveStudentRegistrationFee(strPayment):Observable<any>{  
    let param='strPayment=' +strPayment; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Student/SaveStudentRegistrationFee?'
      +encodeURI(param),{headers:reqHeader});
  }
   //STUDENT ADMISSION DETAILS-------
   GetStudentDetailsByStudentID(StudentID,ParentID):Observable<any>{  
    let param='StudentID=' +StudentID+'&ParentID='+ParentID; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Student/GetStudentDetailsByStudentID?'
      +encodeURI(param),{headers:reqHeader});
  }
  UpdateStudentRouteInstallment(ObjModel,Installmentlist):Observable<any>{  
    // let param='ObjModel=' +ObjModel+'&Installmentlist='+Installmentlist; 
    // var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    // return this.httpclient.post<any>(this.globalHelper.ApiUrl
    //   +'Student/UpdateStudentRouteInstallment?'
    //   +encodeURI(param),{headers:reqHeader});

      let formData = new FormData();
      formData.append("ObjModel", ObjModel); 
      formData.append("Installmentlist", Installmentlist);        
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Transport/UpdateStudentRouteInstallment', formData);
 
  }
  GetFeeInstallmentComponent(ClassID,StudentID):Observable<any>{  
    let param='StudentID=' +StudentID+'&ClassID='+ClassID; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Student/GetFeeInstallmentComponent?'
      +encodeURI(param),{headers:reqHeader});
  }
   // Student Transport 
   GetStudentRouteInstallment(StudentID):Observable<any>{  
    let param='StudentID=' +StudentID; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Transport/GetStudentRouteInstallment?'
      +encodeURI(param),{headers:reqHeader});
  }
  SaveStudentRouteInstallment(objMap,Installmentlist):Observable<any>{  
    let param='objMap=' +objMap+'&Installmentlist='+Installmentlist; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Transport/SaveStudentRouteInstallment?'
      +encodeURI(param),{headers:reqHeader});
  }
  RemoveTransportInstallment(objMap):Observable<any>{  
    let param='objMap=' +objMap; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Transport/RemoveTransportInstallment?'
      +encodeURI(param),{headers:reqHeader});
  }
  SaveTransportRouteLocation(objMap):Observable<any>{  
    let param='objMap=' +objMap; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Transport/SaveTransportRouteLocation?'
      +encodeURI(param),{headers:reqHeader});
  }
  SaveTransport(strTransport,StudentID):Observable<any>{  
    let param='strTransport=' +strTransport+'&StudentID='+StudentID; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Student/SaveTransport?'
      +encodeURI(param),{headers:reqHeader});
  }
  SaveConsession(strConcession,StudentID,ClassID,DiscountRemarks,CreatedOn):Observable<any>{  
    // let param='strConcession=' +strConcession+'&StudentID='+StudentID+
    // 'ClassID'+ClassID; 
    // var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    // return this.httpclient.post<any>(this.globalHelper.ApiUrl
    //   +'Student/SaveConsession?'
    //   +encodeURI(param),{headers:reqHeader});

      let formData = new FormData();
      formData.append("strConcession", strConcession); 
      formData.append("StudentID", StudentID); 
      formData.append("ClassID", ClassID);      
      formData.append('DiscountRemarks',DiscountRemarks); 
      formData.append('CreatedOn',CreatedOn);      
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Student/SaveConcession', formData);
 
  }
  GetStudentConcession(ClassID,StudentID):Observable<any>{  
    let param='ClassID=' +ClassID+'&StudentID='+StudentID; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Student/GetStudentConcession?'
      +encodeURI(param),{headers:reqHeader});
  }

  GetStudentAdhocComponent(StudentID):Observable<any>{  
    let param='StudentID=' +StudentID; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Student/GetStudentAdhocComponent?'
      +encodeURI(param),{headers:reqHeader});
  }
  
  SaveStudentAdhocComponent(strConcession,StudentID,ClassID):Observable<any>{  
      let formData = new FormData();
      formData.append("strConcession", strConcession); 
      formData.append("StudentID", StudentID); 
      formData.append("ClassID", ClassID);             
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Student/SaveStudentAdhocComponent', formData);
 
  }
  SaveStudentCourse(strStudentCourse,StudentID):Observable<any>{  
    let param='strStudentCourse=' +strStudentCourse+'&StudentID='+StudentID; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Student/SaveStudentCourse?'
      +encodeURI(param),{headers:reqHeader});
  }
  SearchStuAdmission(objFilter):Observable<any>{  
    let param='objFilter=' +objFilter; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Student/SearchStuAdmission?'
      +encodeURI(param),{headers:reqHeader});
  }
  SearchStuAdmissionDownload(objFilter):Observable<any>{  
    let param='objFilter=' +objFilter; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Student/SearchStuAdmissionDownload?'
      +encodeURI(param),{headers:reqHeader});
  }
  
  UploadPhoto(PhotoFile,StudentID,StudentAction,DocumentID,Notes): Observable<any> {

    var formData = new FormData();
    formData.append("PhotoFile", PhotoFile);
    formData.append("StudentID", StudentID);
    if (DocumentID != null) {
        formData.append("DocumentID", DocumentID);
    }

    formData.append("StudentAction", StudentAction);
    if (StudentAction == 'StudentDocument') {

        formData.append("Notes", Notes);
    }


  return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Student/UploadPhoto', formData);
}

// STUDENT DETAILS LIST

SearchStudentDetails(objFilter):Observable<any>{  
  let param='objFilter=' +objFilter; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/SearchStudentDetails?'
    +encodeURI(param),{headers:reqHeader});
}

SearchStudentDetailsDownload(objFilter):Observable<any>{  
  let param='objFilter=' +objFilter; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/SearchStudentDetailsDownload?'
    +encodeURI(param),{headers:reqHeader});
}
 // STUDENT COUNT REPORT....
GetSummaryReport(stidentscorecardModel):Observable<any>{  
  let param='stidentscorecardModel=' +stidentscorecardModel; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/GetSummaryReport?'
    +encodeURI(param),{headers:reqHeader});
}
GetStudentAppointment(objModel):Observable<any>{  
  let param='objModel=' +objModel; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Leave/GetStudentAppointment?'
    +encodeURI(param),{headers:reqHeader});
}
UpdateAppointment(objModel):Observable<any>{  
  let param='objModel=' +objModel; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Leave/UpdateAppointment?'
    +encodeURI(param),{headers:reqHeader});
}
GetStudentLeave(objModel):Observable<any>{  
  let param='objModel=' +objModel; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Leave/GetStudentLeave?'
    +encodeURI(param),{headers:reqHeader});
}
UpdateLeave(objModel):Observable<any>{  
  let param='objModel=' +objModel; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Leave/UpdateLeave?'
    +encodeURI(param),{headers:reqHeader});
}
// ISSUE TC..........
GetStudentDetailsByTC(objTC):Observable<any>{  
  let param='objTC=' +objTC; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/GetStudentDetailsByTC?'
    +encodeURI(param),{headers:reqHeader});
}
GetStudentTCPrint(objTC):Observable<any>{  
  let param='objTC=' +objTC; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/GetStudentTCPrint?'
    +encodeURI(param),{headers:reqHeader});
}
GetAllIssueTC(objTC):Observable<any>{  
  let param='objTC=' +objTC; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/GetAllIssueTC?'
    +encodeURI(param),{headers:reqHeader});
}
SaveTCData(objTC):Observable<any>{  
  let param='objTC=' +objTC; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/SaveTCData?'
    +encodeURI(param),{headers:reqHeader});
}
 // STUDENT PROMOTION.......
 GetStudentClassWise(strData):Observable<any>{  
  let param='strData=' +strData; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/GetStudentClassWise?'
    +encodeURI(param),{headers:reqHeader});
}
SaveStudentPromotion(strData, StudentList):Observable<any>{  
 let formData = new FormData();
    formData.append("strData", strData); 
    formData.append("StudentList", StudentList); 
           
  return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Student/SaveStudentPromotion', formData);
}
 // STUDENT TC COUNT......
 GetStudentTCCountReport(stidentscorecardModel):Observable<any>{  
  let param='stidentscorecardModel=' +stidentscorecardModel; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/GetStudentTCCountReport?'
    +encodeURI(param),{headers:reqHeader});
}
GetStudentEnquiryDetails(objEnquiry):Observable<any>{  
  let param='objEnquiry=' +objEnquiry; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/GetStudentEnquiryDetails?'
    +encodeURI(param),{headers:reqHeader});
}
SaveSpecialAchievement(strSpecialAchievement):Observable<any>{  
  let param='strSpecialAchievement=' +strSpecialAchievement; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/SaveSpecialAchievement?'
    +encodeURI(param),{headers:reqHeader});
}
SearchSpecialAchievement(strSpecialAchievement):Observable<any>{  
  let param='strSpecialAchievement=' +strSpecialAchievement; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/SearchSpecialAchievement?'
    +encodeURI(param),{headers:reqHeader});
}
SearchCounselling(strCounselling):Observable<any>{  
  let param='strCounselling=' +strCounselling; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/SearchCounselling?'
    +encodeURI(param),{headers:reqHeader});
}
SaveCounselling(strCounselling):Observable<any>{  
  let param='strCounselling=' +strCounselling; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/SaveCounselling?'
    +encodeURI(param),{headers:reqHeader});
}
SaveDesciplinary(strDesciplinary):Observable<any>{  
  let param='strDesciplinary' +strDesciplinary; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/SaveDesciplinary?'
    +encodeURI(param),{headers:reqHeader});
}
SearchDesciplinary(strDesciplinary):Observable<any>{  
  let param='strDesciplinary' +strDesciplinary; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/SearchDesciplinary?'
    +encodeURI(param),{headers:reqHeader});
}
UpdateParentID(ParentID, StudentID):Observable<any>{  
  let param='ParentID=' +ParentID+'&StudentID='+StudentID; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/UpdateParentID?'
    +encodeURI(param),{headers:reqHeader});
}
GetCategorySummaryReport(strCounselling):Observable<any>{  
 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/GetCategorySummaryReport?'
    ,{headers:reqHeader});
}
GetAllStudentIDCard(objFilter):Observable<any>{  
  let param='objFilter=' +objFilter; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/GetAllStudentIDCard?'
    +encodeURI(param),{headers:reqHeader});
}
    // Promotion Report
  GetPromotionReport11th(stidentscorecardModel):Observable<any>{  
  let param='stidentscorecardModel=' +stidentscorecardModel; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Examination/GetPromotionReport11th?'
    +encodeURI(param),{headers:reqHeader});
}
GetPromotionReportCard(stidentscorecardModel):Observable<any>{  
  let param='stidentscorecardModel=' +stidentscorecardModel; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Examination/GetPromotionReportCard?'
    +encodeURI(param),{headers:reqHeader});
}
GetCCEStudentPromotionRank(stidentscorecardModel):Observable<any>{  
  let param='stidentscorecardModel=' +stidentscorecardModel; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Examination/GetCCEStudentPromotionRank?'
    +encodeURI(param),{headers:reqHeader});
}

// STUDENT ADMISSION REPORT....
GetStudentAdmissionReport(stidentscorecardModel):Observable<any>{  
  let param='stidentscorecardModel=' +stidentscorecardModel; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Student/GetStudentAdmissionReport?'
    +encodeURI(param),{headers:reqHeader});
}
}
