import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from '../admin/admin.component';
import { FeeComponentComponent } from './fee-component/fee-component.component';
import { FeeCollectionReportsComponent } from './fee-collection-reports/fee-collection-reports.component';
import { FeeInstallmentSetupComponent } from './fee-installment-setup/fee-installment-setup.component';
import { FeeStructureComponentComponent } from './fee-structure-component/fee-structure-component.component';
import { FeeStructureInstallmentAmountComponent } from './fee-structure-installment-amount/fee-structure-installment-amount.component';
import { FeePaymentComponent } from './fee-payment/fee-payment.component';
import { FeeReceiptPrintComponent } from './fee-receipt-print/fee-receipt-print.component';
import { ReceiptFormat1Component } from './feereceipt/receipt-format1/receipt-format1.component';
import { ReceiptFormat2Component } from './feereceipt/receipt-format2/receipt-format2.component';
import { FeeReceiptComponent } from './fee-receipt/fee-receipt.component';
import { FeeReceiptCancelComponent } from './fee-receipt-cancel/fee-receipt-cancel.component';
import { FeeCollectionSummaryComponent } from './fee-collection-summary/fee-collection-summary.component';
import { FeeComponentSummaryReportComponent } from './fee-component-summary-report/fee-component-summary-report.component';
import { FeeConsolidatedReportComponent } from './fee-consolidated-report/fee-consolidated-report.component';
import { FeeAdjustmentReportComponent } from './fee-adjustment-report/fee-adjustment-report.component';
import { FeeDefaulterReportsComponent } from './fee-defaulter-reports/fee-defaulter-reports.component';
import { StudentOpeningBalanceComponent } from './student-opening-balance/student-opening-balance.component';
import { FeeDiscountSetupComponent } from './fee-discount-setup/fee-discount-setup.component';
import { PaymentModeMapingComponent } from './payment-mode-maping/payment-mode-maping.component';
import { OnlineTransactionComponent } from './online-transaction/online-transaction.component';
import { FeeChallanComponent } from './fee-challan/fee-challan.component';
import { FeeChallanPrintComponent } from './fee-challan-print/fee-challan-print.component';
import { FeeBookPrintComponent } from './fee-book-print/fee-book-print.component';
import { MonthlyCollectionComponent } from './monthly-collection/monthly-collection.component';
import { StudentReimbursementComponent } from './student-reimbursement/student-reimbursement.component';
import { ExcessReimbursementReportComponent } from './excess-reimbursement-report/excess-reimbursement-report.component';


const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      { path: 'feecomponent', component: FeeComponentComponent, pathMatch: 'full' },
      { path: 'onlinetransaction', component: OnlineTransactionComponent, pathMatch: 'full' },
      { path: 'feecollectionreports', component: FeeCollectionReportsComponent, pathMatch: 'full' },
      { path: 'feeinstallmentsetup', component: FeeInstallmentSetupComponent, pathMatch: 'full' },
      { path: 'feestructurecomponent', component: FeeStructureComponentComponent, pathMatch: 'full' },
      { path: 'feestructureinstallmentamount', component: FeeStructureInstallmentAmountComponent, pathMatch: 'full' },
      { path: 'payment', component: FeePaymentComponent, pathMatch: 'full' },
      { path: 'feereceipt', component: FeeReceiptComponent, pathMatch: 'full' },
      { path: 'feereceiptcancel', component: FeeReceiptCancelComponent, pathMatch: 'full' },
      { path: 'feecollectionsummary', component: FeeCollectionSummaryComponent, pathMatch: 'full' },
      { path: 'feecomponentsummaryreport', component: FeeComponentSummaryReportComponent, pathMatch: 'full' },
      { path: 'feeconsolidatedreport', component: FeeConsolidatedReportComponent, pathMatch: 'full' },
      { path: 'feeadjustmentreport', component: FeeAdjustmentReportComponent, pathMatch: 'full' },
      { path: 'feedefaulterreports', component: FeeDefaulterReportsComponent, pathMatch: 'full' },
      { path: 'studentopeningbalance', component: StudentOpeningBalanceComponent, pathMatch: 'full' },
      { path: 'feediscountsetup', component: FeeDiscountSetupComponent, pathMatch: 'full' },
      { path: 'paymentmodemaping', component: PaymentModeMapingComponent, pathMatch: 'full' },
      { path: 'fee-challan', component: FeeChallanComponent, pathMatch: 'full' },
      { path: 'monthly-collection', component: MonthlyCollectionComponent, pathMatch: 'full' },
      { path: 'student-reimbursement', component: StudentReimbursementComponent, pathMatch: 'full' },
      { path: 'reimbursement-report', component: ExcessReimbursementReportComponent, pathMatch: 'full' },
    ]
  },
  {
    path: '', children: [
      { path: 'receiptformat1', component: ReceiptFormat1Component, pathMatch: 'full' },
      { path: 'receiptformat2', component: ReceiptFormat2Component, pathMatch: 'full' },
      { path: 'fee-challan-print', component: FeeChallanPrintComponent, pathMatch: 'full' },
      { path: 'fee-book-print', component: FeeBookPrintComponent, pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeeRoutingModule { }
