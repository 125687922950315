import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as moment from 'moment'

@Component({
  selector: 'app-fee-collection-reports',
  templateUrl: './fee-collection-reports.component.html',
  styleUrls: ['./fee-collection-reports.component.css']
})
export class FeeCollectionReportsComponent implements OnInit {
  SearchModel = {
    StudentID: 0,
    SectionID: 0,
    SessionID: 0,
    ClassID: 0,
    StudentNo: '',
    FromDate: moment(Date.now()).format("DD-MMM-YYYY"),
    ToDate: '',
    CreatedBy: 0
}
Submitted:boolean;
    sortingOrder = 0;
    groupedItems = [];
    itemsPerPage = 40;
    currentPage = 0;
    query = '';
    pagedItems = [];
    AcademicSessionList:any;
    ClassList:any;
    SectionList:any;
    Class:any;
    Section:any;
    InstallmentList :any;
    FeeCollectionReportsList :any;
    InstallmentCollection :any;
    PaybleHeaderList  :any;
    TotalPayable :any;
    TotalPayableOnDate :any;
    TotalPaidOnDate:any;
    TotalDue :any;
  constructor(private financeService: FinanceService,private lookupService:LookupService,
    private globalHelper :GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

Reset () {
    this.Submitted = false;
    this.SearchModel.ClassID = 0;
    this.SearchModel.SectionID = 0;
    this.SearchModel.StudentNo = '';
};




GetAcademicSession() {
    this.lookupService.GetLookupsList('AcademicSession', '').subscribe( (data)=> {
        this.AcademicSessionList = data.LookupItems;
    });
};
ValidateDropdownSearch() {
    return this.SearchModel.FromDate == '';


};

GetClass () {         
    this.lookupService.GetLookupsList('Class', '').subscribe( (data)=> {
        this.ClassList = data.LookupItems;
    });
};
GetSection () {
    this.SearchModel.SectionID = 0;
    var itemBo = {
        ItemId: this.SearchModel.ClassID

    };
    this.lookupService.GetLookupsList('SectionName', JSON.stringify(itemBo)).subscribe( (data) =>{
        this.SectionList = data.LookupItems;
    });
};
GetFeeCollectionReport () {

    this.Submitted = true;
    if (!this.ValidateDropdownSearch()) {
       
        this.spinnerService.show();
        this.SearchModel.FromDate=moment(this.SearchModel.FromDate).format('DD-MMM-YYYY');
        this.financeService.GetFeeCollectionReport(JSON.stringify(this.SearchModel)).subscribe( (data)=> {
                      
            this.Submitted = false;
            this.spinnerService.hide();
            if (data.webResponse.Status) {
                this.InstallmentList = data.InstallmentList;
                this.FeeCollectionReportsList = data.FeeCollectionReportsList;
                this.InstallmentCollection = data.InstallmentCollection;
                this.PaybleHeaderList = data.PaybleHeaderList;

                
                var TotalPayable = 0;
                var TotalPayableOnDate = 0;
                var TotalPaidOnDate = 0;
                var TotalDue = 0;
                if (this.FeeCollectionReportsList != null) {

                    for (var i = 0; i < this.FeeCollectionReportsList.length; i++) {
                        TotalPayable = TotalPayable + this.FeeCollectionReportsList[i].TotalPayableAmount;
                        TotalPayableOnDate = TotalPayableOnDate + this.FeeCollectionReportsList[i].TotalPayableAmountOnDate;
                        TotalPaidOnDate = TotalPaidOnDate + this.FeeCollectionReportsList[i].TotalPaidAmountOnDate;
                    }
                    TotalDue = TotalPayableOnDate - TotalPaidOnDate;
                    this.TotalPayable = TotalPayable;
                    this.TotalPayableOnDate = TotalPayableOnDate;
                    this.TotalPaidOnDate = TotalPaidOnDate;
                    this.TotalDue = TotalDue;
                }

            }
        });
    }

};



Print  () {

    var divContents = document.getElementById("dvContainer").innerHTML;
    divContents = divContents.replace('no record found', '');
    var printWindow = window.open('', '', 'height=400,width=800');
    printWindow.document.write('<html><head><title>Voucher Receipt </title>');
    printWindow.document.write('<style type="text/css">');
    printWindow.document.write('.t1{ border-top: 1px solid black;  border-left: 1px solid black;white-space:nowrap;font-family:Calibri;font-size:10px} .t2 { border-right: 1px solid black; border-bottom: 1px solid black;}.t3 { border-right: 1px solid black;border-bottom: 1px solid black; }');
    printWindow.document.write('</style>');
    printWindow.document.write('</head><body >');
    printWindow.document.write(divContents);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();

}
ngOnInit () {    
    this.GetAcademicSession();
    this.GetClass();
    this.GetSection();
}

// Download

GetFeeCollectionReportsDownload  () {

    if (this.FeeCollectionReportsList != null) {
        this.JSONToCsvConverterList(this.FeeCollectionReportsList, 'Fee_Collection_Report', true);
    }

};

 JSONToCsvConverterList(JSONData, ReportTitle, ShowLabel) {
    var arrayData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    var CSV = '';
    if (ShowLabel) {
        var row = "";
        row += 'Sr. No.' + ',';
        row += 'GR.No.' + ',';
        row += 'Name' + ',';
        row += 'Class' + ',';
        row += 'Section' + ',';
        for (var j = 0; j < this.InstallmentList.length; j++) {
            row += this.InstallmentList[j].Installment + '(Payable Amt)' + ',';
            row += this.InstallmentList[j].Installment + '(Paid Amt)' + ',';
        }
        row += 'Total Payable Amt' + ',';
        row += 'Payable Amt(as on date)' + ',';
        row += 'Total Paid Amt(as on date)' + ',';
        row += 'Amount Due(as on date)' + ',';
        row = row.slice(0, -1);
        CSV += row + '\r\n';
    }
    for (var i = 0; i < arrayData.length; i++) {
        var sno = i + 1;
        var row = "";
        row += '"' + sno + '",';
        row += '"' + arrayData[i].GRNo + '",';
        row += '"' + arrayData[i].FirstName + ' ' + arrayData[i].MiddleName +' '+ arrayData[i].LastName+ '",';
        row += '"' + arrayData[i].ClassName + '",';
        row += '"' + arrayData[i].SectionName + '",';
        for (var j = 0; j < arrayData[i].AmountList.length; j++) {
            row += arrayData[i].AmountList[j] + ',';
        }
        row += '"' + (arrayData[i].TotalPayableAmount) + '",';
        row += '"' + (arrayData[i].TotalPayableAmountOnDate) + '",';
        row += '"' + (arrayData[i].TotalPaidAmountOnDate) + '",';
        row += '"' + (arrayData[i].TotalPayableAmountOnDate - arrayData[i].TotalPaidAmountOnDate) + '",';
        row.slice(0, row.length - 1);
        CSV += row + '\r\n';
    }
    var row1 = "";
    row1 += '"' + '' + '",';
    row1 += '"' + '' + '",';
    row1 += '"' + '' + '",';
    row1 += '"' + '' + '",';
    row1 += '"' + 'Total' + '",';
    for (var j = 0; j < this.InstallmentCollection.length; j++) {
        row1 += (this.InstallmentCollection[j]) + ',';
    }
    row1 += '"' + (this.TotalPayable) + '",';
    row1 += '"' + (this.TotalPayableOnDate) + '",';
    row1 += '"' + (this.TotalPaidOnDate) + '",';
    row1 += '"' + (this.TotalDue) + '",';
    row.slice(0, row.length - 1);
    CSV += row1 + '\r\n';
    if (CSV == '') {
        alert('Invalid Data');
        return;
    }
    var FileName = ReportTitle;
    var csvData = new Blob([CSV], { type: 'text/csv;charset=utf-8;' });
    var csvURL = window.URL.createObjectURL(csvData);
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', FileName + '.csv');
    tempLink.click();
}






 

}
