import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TransportService } from 'src/app/services/Transport/transport.service';
import * as moment from 'moment';

@Component({
  selector: 'app-fee-collection-summary',
  templateUrl: './fee-collection-summary.component.html',
  styleUrls: ['./fee-collection-summary.component.css']
})
export class FeeCollectionSummaryComponent implements OnInit {
  SearchModel = {
    StudentID: 0,
    SectionID: 0,
    SessionID: 0,
    ClassID: 0,
    StudentNo: '',
    FromDate: moment(Date.now()).format('DD-MMM-YYYY'),
    ToDate: '',
    CreatedBy: 0
}
AcademicSessionList:any;
Submitted:any;
ClassList:any;
SectionList:any;
FeeCollectionSummaryList:any;
TotalPayable = 0;
TotalPaid = 0;
TotalDue = 0;
  constructor(private transportService:TransportService,private spinnerService: Ng4LoadingSpinnerService,private financeService: FinanceService,private lookupService:LookupService,public globalHelper :GlobalHelper) { }
  Reset () {
    this.SearchModel.ClassID = 0;
    this.SearchModel.SectionID = 0;
};

GetAcademicSession  () {
  this.lookupService.GetLookupsList('AcademicSession', '').subscribe( (data)=> {
      this.AcademicSessionList = data.LookupItems;
  });
};
 ValidateDropdownSearch() {
  return this.SearchModel.FromDate == '';


};

GetClass () {
 
  this.lookupService.GetLookupsList('Class', '').subscribe( (data)=> {
      this.ClassList = data.LookupItems;
  });
};
GetSection () {
  this.SearchModel.SectionID = 0;
  var ItemBo = {
      'ItemId': this.SearchModel.ClassID
  }
  this.lookupService.GetLookupsList('SectionName', JSON.stringify(ItemBo)).subscribe( (data)=> {
      this.SectionList = data.LookupItems;

  });
};
GetFeeCollectionSummary  () {
 
  this.Submitted = true;
  if (!this.ValidateDropdownSearch()) {
     this.SearchModel.FromDate=moment(this.SearchModel.FromDate).format('DD-MMM-YYYY');
      this.spinnerService.show();
      this.financeService.GetFeeCollectionSummary(JSON.stringify(this.SearchModel)).subscribe( (data)=> {
          this.globalHelper.CheckActionStatus(data);
          this.spinnerService.hide();
          if (data.webResponse.Status) {
         
              this.FeeCollectionSummaryList = data.FeeCollectionSummaryList;
              var TotalPayable = 0;
              var TotalPaid = 0;
              var TotalDue = 0;
              if (this.FeeCollectionSummaryList != null) {

                  for (var i = 0; i < this.FeeCollectionSummaryList.length; i++) {
                      TotalPayable = TotalPayable + parseFloat(this.FeeCollectionSummaryList[i].PayableAmount);
                      TotalPaid = TotalPaid + parseFloat(this.FeeCollectionSummaryList[i].PaidAmount);
                  }
                  TotalDue = TotalPayable - TotalPaid;
                  this.TotalPayable = this.globalHelper.Decimal(TotalPayable); 
                  this.TotalPaid = this.globalHelper.Decimal(TotalPaid);
                  this.TotalDue = this.globalHelper.Decimal(TotalDue);
              }

              // this.search(this.FeeCollectionSummaryList);

          }
      });
  }

};
  ngOnInit() {
    this.GetClass();
    this.GetSection();
  }


   // Download

   XLSDownload() {

    if (this.FeeCollectionSummaryList != null) {
        this.JSONToCsvConverterList(this.FeeCollectionSummaryList, 'Fee_Collection_Summary', true);
    }

};

JSONToCsvConverterList(JSONData, ReportTitle, ShowLabel) {
    var arrayData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    var CSV = '';
    if (ShowLabel) {
        var row = "";
        row += 'Sr. No.' + ',';
        row += 'Class' + ',';
        row += 'Section' + ',';
        row += 'Payable Amt(as on date)' + ',';
        row += 'Total Paid Amt(as on date)' + ',';
        row += 'Amount Due(as on date)' + ',';
        row = row.slice(0, -1);
        CSV += row + '\r\n';
    }
    for (var i = 0; i < arrayData.length; i++) {
        var sno = i + 1;
        var row = "";
        row += '"' + sno + '",';
        row += '"' + arrayData[i].ClassName + '",';
        row += '"' + arrayData[i].SectionName + '",';
        row += '"' + this.globalHelper.Decimal(arrayData[i].PayableAmount) + '",';
        row += '"' + this.globalHelper.Decimal(arrayData[i].PaidAmount) + '",';
        row += '"' + this.globalHelper.Decimal(arrayData[i].PayableAmount - arrayData[i].PaidAmount) + '",';
        row.slice(0, row.length - 1);
        CSV += row + '\r\n';
    }
    var row1 = "";
    row1 += '"' + '' + '",';
    row1 += '"' + '' + '",';
    row1 += '"' + 'Total' + '",';
    row1 += '"' + this.globalHelper.Decimal(this.TotalPayable) + '",';
    row1 += '"' + this.globalHelper.Decimal(this.TotalPaid) + '",';
    row1 += '"' + this.globalHelper.Decimal(this.TotalDue) + '",';
    row.slice(0, row.length - 1);
    CSV += row1 + '\r\n';
    if (CSV == '') {
        alert('Invalid Data');
        return;
    }
    var FileName = ReportTitle + '_' + moment(Date.now()).format('DD-MMM-YYYY');
    var csvData = new Blob([CSV], { type: 'text/csv;charset=utf-8;' });
    var csvURL = window.URL.createObjectURL(csvData);
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', FileName + '.csv');
    tempLink.click();
}
}
