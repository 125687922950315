import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalHelper } from '../Common/global.service';

@Injectable({
  providedIn: 'root'
})
export class FinanceService {
 constructor( private globalHelper:GlobalHelper, private httpclient: HttpClient) { }

 GetStudentReimbursement(objModel):Observable<any>{ 
  let param='strStudent=' +objModel;  
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetStudentReimbursement?'
    +encodeURI(param),{headers:reqHeader});
}
GetReimbursementReport(objModel):Observable<any>{ 
  let param='strStudent=' +objModel;  
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetReimbursementReport?'
    +encodeURI(param),{headers:reqHeader});
}
GetStudentDetailsForReimbursement(StudentNo, BODRegNo, ParentID, FirstName, MiddleName, LastName,FeeBookNo):Observable<any>{  
  let param='StudentNo=' +StudentNo+'&BODRegNo=' +BODRegNo+'&ParentID=' +ParentID+'&FirstName='
   +FirstName+'&MiddleName=' +MiddleName+'&LastName=' +LastName+'&FeeBookNo=' +FeeBookNo; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetStudentDetailsForReimbursement?'
    +encodeURI(param),{headers:reqHeader});
}
SaveStudentReimbursement(objModel):Observable<any>{ 
  let param='strStudent=' +objModel;  
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/SaveStudentReimbursement?'
    +encodeURI(param),{headers:reqHeader});
}

CancelStudentReimbursement(objModel):Observable<any>{ 
  let param='strStudent=' +objModel;  
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/CancelStudentReimbursement?'
    +encodeURI(param),{headers:reqHeader});
}
 GetMonthlyCollectionReport(objModel):Observable<any>{ 
  let param='strStudent=' +objModel;  
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetMonthlyCollectionReport?'
    +encodeURI(param),{headers:reqHeader});
}
GetMonthlyCollectionSummaryReport(objModel):Observable<any>{ 
  let param='strStudent=' +objModel;  
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetMonthlyCollectionSummaryReport?'
    +encodeURI(param),{headers:reqHeader});
}
 GetConsolidatedReport(objModel):Observable<any>{ 
  let param='objModel=' +objModel;  
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Reports/GetConsolidatedReport?'
    +encodeURI(param),{headers:reqHeader});
}

GetConsolidatedSummary  (objModel):Observable<any>{ 

  let param='objModel=' +objModel;  
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Reports/GetConsolidatedSummary?'
    +encodeURI(param),{headers:reqHeader});
}
CancelConsolidatedReceipt  (objModel) :Observable<any>{ 

  let param='objModel=' +objModel;  
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Reports/CancelConsolidatedReceipt?'
    +encodeURI(param),{headers:reqHeader});
}

 GetFeeCollectionSummary(strStudent):Observable<any>{  
  let param='strStudent=' +strStudent;  
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetFeeCollectionSummary?'
    +encodeURI(param),{headers:reqHeader});
}
 
 GetDueGeneralPayment(strdata):Observable<any>{  
  let param='strGeneralPayment=' +strdata;  
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'GeneralPayment/GetDueGeneralPayment?'
    +encodeURI(param),{headers:reqHeader});
}
 GetPaymentModeMaping():Observable<any>{  
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetPaymentModeMaping',{headers:reqHeader});
}

GetPostFeeDaily(strdata):Observable<any>{  
  let param='objReceipt=' +strdata;  
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetPostFeeDaily?'
    +encodeURI(param),{headers:reqHeader});
}
GetPostModernExchangeFeeDaily(strdata):Observable<any>{  
  let param='objReceipt=' +strdata;  
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetPostModernExchangeFeeDaily?'
    +encodeURI(param),{headers:reqHeader});
}
SavePaymentModeMaping(strdata):Observable<any>{  
  let param='objModel=' +strdata;  
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/SavePaymentModeMaping?'
    +encodeURI(param),{headers:reqHeader});
}

GetFeeInstallmentComponentNew(ClassID,StudentID):Observable<any>{  
  let param='ClassID=' +ClassID+'&StudentID='+StudentID;  
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetFeeInstallmentComponentNew?'
    +encodeURI(param),{headers:reqHeader});
}
SaveStudentFeePaymentNew(strStudent,FeeComponentList):Observable<any>{  
   let formData = new FormData();
    formData.append("strStudent", strStudent);
    formData.append("FeeComponentList", FeeComponentList);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Finance/SaveStudentFeePaymentNew', formData);
}
SaveStudentFeeBook(ClassID,StudentID,FeeBookNo):Observable<any>{  
  let formData = new FormData();
   formData.append("ClassID", ClassID);
   formData.append("StudentID", StudentID);
   formData.append("FeeBookNo", FeeBookNo);
   return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Finance/SaveStudentFeeBook', formData);
}

GetFeeBookPrint(StudentID):Observable<any>{  
  let param='StudentID=' +StudentID; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetFeeBookPrint?'
    +encodeURI(param),{headers:reqHeader});
}
SaveStudentFeeChallan(strStudent,FeeComponentList):Observable<any>{  
  let formData = new FormData();
   formData.append("strStudent", strStudent);
   formData.append("FeeComponentList", FeeComponentList);
   return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Finance/SaveStudentFeeChallan', formData);
}
GetStudentFeeChallan(objReceipt):Observable<any>{  
  let param='objReceipt=' +objReceipt; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetStudentFeeChallan?'
    +encodeURI(param),{headers:reqHeader});
}

GetFeeChallanPrint(ReceiptNo):Observable<any>{  
  let param='ChallanReceiptID=' +ReceiptNo; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetFeeChallanPrint?'
    +encodeURI(param),{headers:reqHeader});
}
ChallanPaymentReceived(objReceipt):Observable<any>{  
  let param='objReceipt=' +objReceipt; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/ChallanPaymentReceived?'
    +encodeURI(param),{headers:reqHeader});
}
CreateRazorOrderStudentFee(strStudent,FeeComponentList):Observable<any>{  
  let formData = new FormData();
   formData.append("strStudent", strStudent);
   formData.append("FeeComponentList", FeeComponentList);
   return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'PG/CreateRazorOrderStudentFee', formData);
}
VerifyRazorOrderPayment(objmodel): Observable<any> {
    
  let formData = new FormData();
  formData.append("strPayment", objmodel);
  return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'PG/VerifyRazorOrderStudentFee', formData);

}
GetStudentAdhocFee(ClassID,StudentID):Observable<any>{  
  let param='ClassID=' +ClassID+'&StudentID='+StudentID; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetStudentAdhocFee?'
    +encodeURI(param),{headers:reqHeader});
}

SaveStudentAdhocFee(strdata,Installment):Observable<any>{  
  let formData = new FormData();
  formData.append("strdata", strdata);
  formData.append("Installment", Installment);
  return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Finance/SaveStudentAdhocFee', formData);
  
}
SaveFeeDiscountSetup(strConsession,DiscountID):Observable<any>{  
  let formData = new FormData();
  formData.append("strConsession", strConsession);
  formData.append("DiscountID", DiscountID);
  return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Finance/SaveFeeDiscountSetup', formData);
  
}
OnlinePaymentReceived(objReceipt):Observable<any>{  
  let param='objReceipt=' +objReceipt; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/OnlinePaymentReceive?'
    +encodeURI(param),{headers:reqHeader});
}
SaveFeeFineAmount(objFine):Observable<any>{  
  let param='objFine=' +objFine; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/SaveFeeFineAmount?'
    +encodeURI(param),{headers:reqHeader});
}
SaveFeeDiscountPriority(DiscountPriority):Observable<any>{  
  let param='DiscountPriority=' +DiscountPriority; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/SaveFeeDiscountPriority?'
    +encodeURI(param),{headers:reqHeader});
}
GetDiscountPriority():Observable<any>{  

  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetDiscountPriority'
    ,{headers:reqHeader});
}
GetFeeDiscountSetup(DiscountID):Observable<any>{  
  let param='DiscountID=' +DiscountID; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetFeeDiscountSetup?'
    +encodeURI(param),{headers:reqHeader});
}
SaveFeeComponent(objModel):Observable<any>{  
  let param='objModel=' +objModel; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/SaveFeeComponent?'
    +encodeURI(param),{headers:reqHeader});
}
GetFeeComponent(objModel):Observable<any>{  
  let param='objModel=' +objModel; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetFeeComponent?'
    +encodeURI(param),{headers:reqHeader});
}
GetFeeCollectionReport(strStudent):Observable<any>{ 
  let param='strStudent=' +strStudent; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetFeeCollectionReport?'
    +encodeURI(param),{headers:reqHeader});
}
GetFeeDefaulterReports(strStudent):Observable<any>{  
  let param='strStudent=' +strStudent; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetFeeDefaulterReports?'
    +encodeURI(param),{headers:reqHeader});
}
GetFeeInstallmentSchedule(InstallmentTypeID):Observable<any>{  
  let param='InstallmentTypeID=' +InstallmentTypeID; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetFeeInstallmentSchedule?' +encodeURI(param),{headers:reqHeader});
}
SaveFeeInstallmentSchedule(InstallmentTypeID,FeeInstallmentSchedule):Observable<any>{  
  let param='InstallmentTypeID=' +InstallmentTypeID +'&FeeInstallmentSchedule=' +FeeInstallmentSchedule; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/SaveFeeInstallmentSchedule?' +encodeURI(param),{headers:reqHeader});
}
GetFeeStructurInstallment(ClassID):Observable<any>{  
  let param='ClassID=' +ClassID; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetFeeStructurInstallment?'
    +encodeURI(param),{headers:reqHeader});
}
GetStudentDetails(StudentNo, BODRegNo, ParentID, FirstName, MiddleName, LastName,FeeBookNo):Observable<any>{  
  let param='StudentNo=' +StudentNo+'&BODRegNo=' +BODRegNo+'&ParentID=' +ParentID+'&FirstName='
   +FirstName+'&MiddleName=' +MiddleName+'&LastName=' +LastName+'&FeeBookNo=' +FeeBookNo; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetStudentDetailsForFeePayment?'
    +encodeURI(param),{headers:reqHeader});
}

GetFeeReceipt(ReceiptNo):Observable<any>{  
  let param='ReceiptNo=' +ReceiptNo; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetFeeReceipt?'
    +encodeURI(param),{headers:reqHeader});
}

GetFeeReceiptFormat1(ReceiptNo):Observable<any>{  
  let param='ReceiptNo=' +ReceiptNo; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetFeeReceiptFormat1?'
    +encodeURI(param),{headers:reqHeader});
}



GetPaymentReceipt(objReceipt):Observable<any>{  
  let param='objReceipt=' +objReceipt; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/SearchPaymentReceipt?'
    +encodeURI(param),{headers:reqHeader});
}
GetOnlineTransaction(objReceipt):Observable<any>{  
  let param='objReceipt=' +objReceipt; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetOnlineTransaction?'
    +encodeURI(param),{headers:reqHeader});
}
GetFeeAdjustmentReport(strStudent):Observable<any>{  
  let param='strStudent=' +strStudent; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetFeeAdjustmentReport?'
    +encodeURI(param),{headers:reqHeader});
}
GetFeeComponentSummaryReport(strStudent):Observable<any>{  
  let param='strStudent=' +strStudent; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetFeeComponentSummaryReport?' +encodeURI(param),{headers:reqHeader});
}
GetAllPaymentReceipt(objReceipt):Observable<any>{  
  let param='objReceipt=' +objReceipt; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetAllPaymentReceipt?'
    +encodeURI(param),{headers:reqHeader});
}
SaveInstallment(FeeComponentList, ClassID):Observable<any>{  
  let formData = new FormData();
  formData.append("ClassID", ClassID);
  formData.append("FeeComponentList", FeeComponentList);
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/SaveInstallment',formData);
}
SaveStudentFeePayment(StrStudent, FeeComponentList):Observable<any>{  
  let param='StrStudent=' +StrStudent+'FeeComponentList'+FeeComponentList; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/SaveStudentFeePayment?'
    +encodeURI(param),{headers:reqHeader});
}
GetFeeStructureDetails():Observable<any>{  
  
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetFeeStructureDetails?'
  ,{headers:reqHeader});
}
// SaveFeeStructure(objList):Observable<any>{  
//   let param='objList=' +objList; 
//   var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
//   return this.httpclient.post<any>(this.globalHelper.ApiUrl
//     +'Finance/SaveFeeStructure?'
//     +encodeURI(param),{headers:reqHeader});
// }
SaveFeeStructure(objList): Observable<any> {

  let formData = new FormData();
  formData.append("objList", objList);
  return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Finance/SaveFeeStructure', formData);
}



GetFeeDetails(studentDetails):Observable<any>{  
  let param='studentDetails=' +studentDetails; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetFeeDetails?'
    +encodeURI(param),{headers:reqHeader});
}
StudentConcession(ClassID, StudentID):Observable<any>{  
  let param='ClassID=' +ClassID+'&StudentID='+StudentID; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetStudentConcession?'
    +encodeURI(param),{headers:reqHeader});
}
GetFeeInstallmentComponent(ClassID, StudentID):Observable<any>{  
  let param='ClassID=' +ClassID+'&StudentID'+StudentID; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetFeeInstallmentComponent?'
    +encodeURI(param),{headers:reqHeader});
}
GetStudents(StudentDetails):Observable<any>{  
  let param='StudentDetails=' +StudentDetails; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetStudents?'
    +encodeURI(param),{headers:reqHeader});
}
SaveCancelReceipt(StrStudent):Observable<any>{  
  let param='StrStudent=' +StrStudent; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/SaveCancelReceipt?'
    +encodeURI(param),{headers:reqHeader});
}
GetStudentOpeningBalance(StudentSearchModel):Observable<any>{  
  let param='StudentSearchModel=' +StudentSearchModel; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetStudentOpeningBalance?'
    +encodeURI(param),{headers:reqHeader});
}
UpdateStudentOpeningBalance(StudentOpeningBalanceList): Observable<any> {
  let formData = new FormData();
  formData.append("StudentOpeningBalanceList", StudentOpeningBalanceList); 
  return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Finance/UpdateStudentOpeningBalance', formData);
}

GetOtherPaymentHistory(StudentID):Observable<any>{  
  let param='StudentID=' +StudentID; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetOtherPaymentHistory?'
    +encodeURI(param),{headers:reqHeader});
}

SaveStudentOpeningBalance(docsdata, objOpeningBalance): Observable<any> {

  let formData = new FormData();
  formData.append("docsdata", docsdata);
  formData.append("objOpeningBalance", objOpeningBalance);


  return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Finance/SaveStudentOpeningBalance', formData);
}

GetFeeReminder(objFilter):Observable<any>{  
  let param='objFilter=' +objFilter; 
  var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  return this.httpclient.post<any>(this.globalHelper.ApiUrl
    +'Finance/GetFeeReminder?'
    +encodeURI(param),{headers:reqHeader});
}

}
