import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { SalService } from 'src/app/Services/sal/sal.service';

@Component({
  selector: 'app-paymentviewmodel',
  templateUrl: './paymentviewmodel.component.html',
  styleUrls: ['./paymentviewmodel.component.css']
})
export class PaymentviewmodelComponent implements OnInit {
  @Input() public PaymentDetails;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();


  Details: any;
  SumTotalTax: any;
  TotalAmount: any;
  constructor(private activeModal: NgbActiveModal, public globalHelper: GlobalHelper,
    private spinnerService: Ng4LoadingSpinnerService, private salservice: SalService) {


  }
  OrganizationCountryID: any;
  OrganizationStateID: any;
  ItemList = [];
  DisplayModel = {
    InvoiceID: 0,
    PageAction: ''
  }
 
  // GetInvoiceProducts() {
  //   this.DisplayModel.PageAction = 'GETINVOICEPRODUCTS';
  //   this.spinnerService.show();
  //   this.salservice.GetInvoiceProducts(JSON.stringify(this.DisplayModel)).subscribe((response: any) => {
  //     this.ItemList = response.OrderProductList;
  //     this.globalHelper.CheckActionStatus(response);
     

  //     this.SumTotalTax = 0;
  //     this.TotalAmount = 0;
  //     for (let index = 0; index < this.ItemList.length; index++) {
  //       this.SumTotalTax = this.SumTotalTax + this.ItemList[index].TaxAmount;
  //       this.TotalAmount = this.TotalAmount + this.ItemList[index].Amount * this.ItemList[index].Qty;
  //     }
  //     this.spinnerService.hide();
  //   });
  // }
  ngOnInit() {
    this.DisplayModel.InvoiceID=this.PaymentDetails.InvoiceID;
  
    this.Details = this.PaymentDetails;
   

  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}
