import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { StudentService } from 'src/app/services/Student/student.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import * as moment from 'moment';
@Component({
    selector: 'app-student-modal',
    templateUrl: './student-modal.component.html',
    styleUrls: ['./student-modal.component.css']
})
export class StudentModalComponent implements OnInit {
    @Input() public prmstudent;
    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    BranchDetails: any;
    AddTransportModel = {
        RouteID: 0,
        RouteLocationID: 0,
        StudentID: 0,
        StudentRouteLocationID: 0,
        StudentFeeStructureID: 0,
    }
    dropdownSettings: any = {};
    selectedItems: any = [];
    RouteLocationInstallment: any;
    StudentTransportModel = {
        RouteID: 0,
        RouteLocationID: 0,
        StudentID: 0,
        Installment: '',
        StartMonth: ''
    }
    ViewLocation = false;
    RouteInstallmentList: any;
    IsUpdate = false;
    //   StudentEnquiryModel:any;
    UpdateParentModel = {
        ParentID: 0,
    }
    StudentEnquiryModel = {
        Address: '',
        SpecialNeed: false,
        EmailID: '',
        HouseName: '',
        FirstName: '',
        Session: "",
        SectionName: "",
        StudentID: 0,
        MiddleName: '',
        LastName: '',
        Gender: '0',
        SessionID: 0,
        DOB: '',
        BODRegNo: '',
        BloodGroupID: 0,
        NationalityID: 0,
        PermanentCountry: '',
        PrevSchool: '',
        ReligionID: 0,
        PrevClassID: 0,
        PermanentCity: '',
        MotherTongue: '',
        BranchID: 0,
        MotherTongueID: 0,
        PlaceOfBirth: '',
        CasteID: 0,
        Religion: '',
        RelationShipID: 0,
        ClassID: 0,
        PresentCountryID: 0,
        BloodGroup: '',
        PassportNo: '',
        StreamID: 0,
        Nationality: '',
        PresentAddress: '',
        PresentStateID: 0,
        PresentCityID: 0,
        Mobile: '',
        PresentZipCode: '',
        PermanentAddress: '',
        PermanentCountryID: 0,
        PermanentStateID: 0,
        PermanentCityID: 0,
        PermanentZipCode: '',
        FatherName: '',
        FatherOccupation: '',
        FatherQualificationID: 0,
        FatherPresentAddress: '',
        FatherPermanentAddress: '',
        FatherCompanyName: '',
        FatherCompanyAddress: '',
        FatherContactHome: '',
        FatherContactCell: '',
        FatherContactOffice: '',
        FatherOfficeEmailID: '',
        FatherPersonalMailID: '',
        MotherName: '',
        MotherOccupation: '',
        MotherQualificationID: 0,
        MotherPresentAddress: '',
        MotherPermanentAddress: '',
        MotherCompanyName: '',
        MotherCompanyAddress: '',
        MotherContactWork: '',
        MotherContactHome: '',
        MotherContactCell: '',
        MotherContactOffice: '',
        MotherOfficeEmailID: '',
        ThirdLanguage: "",
        DECLARATION: false,
        IDCardTypeID: 0,
        IDCardNo: '',
        EnquiryNo: '',
        ParentID: 0,
        CategoryID: '0',
        SpecialCategoryID: '0',
        LocationID: '0',
        FatherPhotoPath: "",
        MotherPhotoPath: "",
        MotherPersonalMailID: "",
        FatherContactWork: "",
        FatherDesignation: "",
        MotherDesignation: "",
        FatherNationality: "",
        MotherNationality: "",
        HouseNo: "",
        WayNo: "",
        ClassName: "",
        HealthCondition: "",
        PermanentState: "",
        StreetName: "",
        Locality: "",
        POBox: "",
        StudentPOBox: '',
        AdmissionDate: '',
        AdmissionGradeID: 0,
        RelationshipID: 0,
        BranchName: "",
        BranchCode: "",
        Count: 0,
        LatestGradeID: 0,
        IsPresentAddress: false,
        PassedSchoolName: '',

        SectionID: 0,
        HouseID: 0,

        StudentNo: '',
        CountryID: 0,


        LastQualificationID: 0,
        PassedSchoolID: 0,

        Email: '',


        RouteID: 0,
        StartMonth: 0,
        Specialattention: "",
        VehicleID: 0,
        Phone: '',
        GaurdianName: '',
        StudentRelationship: '',

        PermanentMobile: '',
        PermanentPhone: '',

        AdmissionRemarks: '',
        RegistrationFee: 0,
        RegistrationDate: '',
        RegistrationNumber: '',
        PaymentMode: 0,
        BusStoppage: 0,
        StudentPhotoPath: '',

        IsWithdraw: false,
        StudentStatus: 0,
        WithDrawalReason: '',
        AdmissionSessionID: 0,

        ScholarshipID: '',

        GRNo: '',
        TotalAmount: 0,


    }

    FilterStudentAdmissionModel = {
        ClassID: 0,
        ParentID: 0,
        Print: "",
        SectionID: 0,
        StudentStatus: 0,
        BranchID: 0,
        SessionID: 0,
        FirstName: '',
        FromDate: '',
        ToDate: '',
        RegistrationNumber: '',
        StudentNo: '',
        CurrentIndex: 0,
        PageSize: 20,
        LocationID: 0,
    }

    isCollapsed = false;
    isCollapsed1 = true;
    isCollapsed2 = true;
    isCollapsed3 = true;
    isCollapsed4 = true;
    isCollapsed5 = true;
    isCollapsed6 = true;
    isCollapsed10 = true;
    isCollapsedCourse = true;
    isCollapsedAdhoc = true;
    BrothersAndSistersList: any;
    IsCopyFatherAdress: any;
    InstallmentLockList: any;
    BranchList: any;

    constructor(
        public activeModal: NgbActiveModal, private financeService: FinanceService, private studentService: StudentService, private spinnerService: Ng4LoadingSpinnerService, private lookupService: LookupService,
        public globalHelper: GlobalHelper
    ) { }


    CheckInstallmentLock(installment) {
        var flag = false;
        for (var i = 0; i < this.InstallmentLockList.length; i++) {
            if (this.InstallmentLockList[i].InstallmentID == installment) {
                flag = this.InstallmentLockList[i].IsLock;
                break;
            }
        }
        return flag;
    }

    GetTotal(StudentFeeDetailsList) {
        var sum = 0;
        for (var i = 0; i < StudentFeeDetailsList.length; i++) {
            sum = sum + StudentFeeDetailsList[i].TotalPaidAmount;
        }
        this.StudentEnquiryModel.TotalAmount = sum;
    }

    GetBranch() {
        this.lookupService.GetLookupsList('Branch', '').subscribe((data) => {
            this.BranchList = data.LookupItems;
        });
    };
    ReligionList: any;
    MotherTongueList: any;
    RelationshipList: any;
    HouseList: any;
    GetReligion() {
        this.lookupService.GetLookupsList('Religion', '').subscribe((data) => {
            this.ReligionList = data.LookupItems;
        });
    }

    GetMotherTongue() {
        this.lookupService.GetLookupsList('MotherTongue', '').subscribe((data) => {
            this.MotherTongueList = data.LookupItems;
        });
    }

    GetRelationship() {
        this.lookupService.GetLookupsList('Relationship', '').subscribe((data) => {
            this.RelationshipList = data.LookupItems;
        });
    }


    GetHouse() {
        this.lookupService.GetLookupsList('HouseWithCount', '').subscribe((data) => {
            this.HouseList = data.LookupItems;
            this.HouseList.splice(0, 1);

        });
    };
    BloodGroupList: any;
    NationalityList: any;
    PresentCountryList: any;
    GetBloodGroup() {
        this.lookupService.GetLookupsList('BloodGroup', '').subscribe((data) => {
            this.BloodGroupList = data.LookupItems;
        });
    };
    GetNationality() {

        this.lookupService.GetLookupsList('Nationality', '').subscribe((data) => {
            this.NationalityList = data.LookupItems;
        });
    };
    PresentStateList: any;
    PresentCityList: any;
    GetPresentCountry() {
        this.lookupService.GetLookupsList('Country', '').subscribe((data) => {
            this.PresentCountryList = data.LookupItems;
        });
    };
    GetPresentState() {
        var ItemBO = {
            ItemId: 483
        }

        this.lookupService.GetLookupsList('State', JSON.stringify(ItemBO)).subscribe((data) => {
            this.PresentStateList = data.LookupItems;
        });
    };
    GetPresentCity() {
        var itembo = {
            'ItemID': this.StudentEnquiryModel.PresentStateID
        }
        this.lookupService.GetLookupsList('City', JSON.stringify(itembo)).subscribe((data) => {
            this.PresentCityList = data.LookupItems;
        });
    };
    PermanentCountryList: any;
    IDCardTypeList: any;
    GetPermanentCountry() {

        this.lookupService.GetLookupsList('Country', '').subscribe((data) => {
            this.PermanentCountryList = data.LookupItems;
        });
    }

    GetIDCardType() {

        this.lookupService.GetLookupsList('IDCardType', '').subscribe((data) => {
            this.IDCardTypeList = data.LookupItems;
        });
    }
    DocumentList: any;
    GetDocument() {
        var itembo = {
            'ItemID': this.StudentEnquiryModel.ClassID
        }
        this.lookupService.GetLookupsList('DocumentName', JSON.stringify(itembo)).subscribe((data) => {
            this.DocumentList = data.LookupItems;
        });
    };





    FeeComponentList1: any;
    ComponentList1: any;
    removeRow(index) {
        this.BrothersAndSistersList.splice(index, 1);
    };
    GetFeeInstallmentComponent() {
        this.IsConcession = true;
        this.FeeComponentList1 = [];
        this.ComponentList1 = [];
        this.studentService.GetFeeInstallmentComponent(this.StudentEnquiryModel.ClassID, this.StudentEnquiryModel.StudentID).subscribe((response) => {

            if (response.webResponse.Status) {
                this.FeeComponentList1 = response.FeeComponentList;
                this.ComponentList1 = response.ComponentList;

            }
        });
    };
    CopyFatherAdress() {
        if (this.IsCopyFatherAdress) {
            this.StudentEnquiryModel.MotherPresentAddress = this.StudentEnquiryModel.FatherPresentAddress;
            this.StudentEnquiryModel.MotherPermanentAddress = this.StudentEnquiryModel.MotherPermanentAddress;
        }
    }

    PrintProfile(item) {
        var url = "printstudentprofile?ReportType=PrintApplication&StudentID=" + item.StudentID;
        this.globalHelper.OpenPopUpWindow(url, "yes", "950", "1000");
    }

    //Adhoc

    GetStudentAdhocFee() {

        this.financeService.GetStudentAdhocFee(this.StudentEnquiryModel.ClassID, this.StudentEnquiryModel.StudentID).subscribe((response) => {
            this.StudentAdhocFeeList = response.StudentAdhocFeeList;

        });
    };
    StudentAdhocFeeList: any;
    loadingAdhoc = true;
    UpdateShow = true;
    SaveStudentAdhocFee() {
        this.loadingAdhoc = true;

        this.financeService.SaveStudentAdhocFee(JSON.stringify(this.StudentEnquiryModel), JSON.stringify(this.StudentAdhocFeeList)).subscribe((response) => {
            this.loadingAdhoc = false;

            this.GetStudentAdhocFee();
            this.AllStudentConcession();
            this.globalHelper.CheckActionStatus(response);
        });

    };
    IsConcession = true;
    ListStudentConcession: any;
    ComponentList: any;
    InstallmentList: any;

    AllStudentConcession() {
        this.IsConcession = true;

        this.studentService.GetStudentConcession(this.StudentEnquiryModel.ClassID, this.StudentEnquiryModel.StudentID).subscribe((response) => {
            this.ListStudentConcession = response.ListStudentConcession;
            this.ComponentList = response.FeeComponentList;
            this.InstallmentList = response.InstallmentList;
            this.InstallmentLockList = response.InstallmentLockList;
            this.DiscountRemarksList=response.DiscountRemarksList; 
        });
    };
    // Update Parent ID

    GetUpdateIcon() {
        this.UpdateShow = true;
    }

    GetUpdateShow() {
        this.UpdateShow = false;

    }


    loadingParentID = false;
    IsExist: any;
    ParentIDExist: any;
    ParentID: any;
    UpdateParentID() {
        this.loadingParentID = true;

        this.studentService.UpdateParentID(this.UpdateParentModel.ParentID, this.StudentEnquiryModel.StudentID).subscribe((response) => {
            this.IsExist = response.IsExist;
            if (this.IsExist) {
                this.ParentIDExist = this.UpdateParentModel.ParentID + ' Parent ID Does not exist';
            }
            this.globalHelper.CheckActionStatus(response);
            this.UpdateShow = false;
            this.loadingParentID = false;

            if (response.webResponse.Status) {
                this.ParentID = this.UpdateParentModel.ParentID;

                this.GetStudentDetailsByStudentID();

            }

        });

    }
    loadingSearch = true;
    ListStuAdmission: any;

    GetCategory() {

        this.lookupService.GetLookupsList('Category', '').subscribe((data) => {
            this.CategoryList = data.LookupItems;


        });

    };
    CategoryList: any;
    ClassList: any;
    GetClass() {

        this.lookupService.GetLookupsList('Class', '').subscribe((data) => {
            this.ClassList = data.LookupItems;
            this.GetSectionGrade();

        });

    };
    PermanentStateList: any;

    GetPermanentState() {
        var ItemBO = {
            ItemId: this.StudentEnquiryModel.PermanentCountryID
        }

        this.lookupService.GetLookupsList('State', JSON.stringify(ItemBO)).subscribe((data) => {
            this.PermanentStateList = data.LookupItems;
        });
    };
    PermanentCityList: any;
    GetPermanentCity() {
        var itembo = {
            ItemId: this.StudentEnquiryModel.PermanentStateID
        }
        this.lookupService.GetLookupsList('City', JSON.stringify(itembo)).subscribe((data) => {
            this.PermanentCityList = data.LookupItems;
        });
    };

    RouteList: any;
    GetAllRoute() {
        this.lookupService.GetLookupsList('RouteName', '').subscribe((data) => {
            this.RouteList = data.LookupItems;
        });
    };
    LocationList: any;
    GetAllLocation(RouteID) {

        var itembo = {
            ItemId: RouteID
        }
        this.lookupService.GetLookupsList('RouteLocation', JSON.stringify(itembo)).subscribe((data) => {
            this.LocationList = data.LookupItems;
        });
    };
    AcademicSession: any;
    GetSession() {
        this.lookupService.GetLookupsList('AcademicSession', '').subscribe((data) => {
            this.AcademicSession = data.LookupItems;
        });
    };
    SectionSearchList: any;
    GetSectionSearch() {
        this.FilterStudentAdmissionModel.SectionID = 0;
        var ItemBo = {
            'ItemId': this.FilterStudentAdmissionModel.ClassID
        }
        this.lookupService.GetLookupsList('SectionName', JSON.stringify(ItemBo)).subscribe((data) => {
            this.SectionSearchList = data.LookupItems;

        });
    };

    GetSection() {
        var ItemBo = {
            'ItemId': this.StudentEnquiryModel.ClassID
        }
        this.lookupService.GetLookupsList('SectionName', JSON.stringify(ItemBo)).subscribe((data) => {
            this.SectionSearchList = data.LookupItems;

        });
    };
    SectionGradeList: any;
    GetSectionGrade() {
        var ItemBo = {
            'ItemId': this.StudentEnquiryModel.ClassID
        }
        this.lookupService.GetLookupsList('SectionGrade', JSON.stringify(ItemBo)).subscribe((data) => {
            this.SectionGradeList = data.LookupItems;
            this.SectionGradeList.splice(0, 1);

        });
    };
    StudentStatusList: any;
    GetStudentStatus() {

        this.lookupService.GetLookupsList('StudentStatus', '').subscribe((data) => {
            this.StudentStatusList = data.LookupItems;

        });
    };
    StudentLocationList: any; CourseList: any;
    GetLocation() {

        this.lookupService.GetLookupsList('Location', '').subscribe((data) => {
            this.StudentLocationList = data.LookupItems;

        });
    };
    StudentDocumentList: any
    StudentView: any;
    GetStudentDetailsByStudentID() {
        this.loadingSearch = true;
        this.studentService.GetStudentProfileByStudentID(this.StudentEnquiryModel.StudentID).subscribe((data) => {
            this.StudentView = data.StudentViewList;
            this.StudentEnquiryModel = this.StudentView.StudentDetails;
            this.StudentEnquiryModel.AdmissionDate = moment(this.StudentView.StudentDetails.AdmissionDate).format("DD-MMM-YYYY")
            this.GetAcademicSession();
            this.GetClass();
            this.StudentEnquiryModel.SessionID = this.StudentView.StudentDetails.SessionID;
            this.StudentEnquiryModel.SpecialNeed = this.StudentView.StudentDetails.SpecialNeed;
            this.StudentEnquiryModel.AdmissionSessionID = this.StudentView.StudentDetails.AdmissionSessionID;
            this.StudentEnquiryModel.ClassID = this.StudentView.StudentDetails.ClassID.toString().trim();
            this.StudentEnquiryModel.AdmissionGradeID = this.StudentView.StudentDetails.AdmissionGradeID.toString().trim();
            this.StudentEnquiryModel.LatestGradeID = this.StudentView.StudentDetails.LatestGradeID.toString().trim();
            this.StudentEnquiryModel.PrevClassID = this.StudentView.StudentDetails.PrevClassID.toString().trim();
            this.StudentEnquiryModel.SpecialCategoryID = this.StudentView.StudentDetails.SpecialCategoryID.toString().trim();
            this.StudentEnquiryModel.LocationID = this.StudentView.StudentDetails.LocationID.toString().trim();
            this.StudentEnquiryModel.CategoryID = this.StudentView.StudentDetails.CategoryID.toString().trim();
            this.StudentEnquiryModel.NationalityID = this.StudentView.StudentDetails.NationalityID;
            this.StudentEnquiryModel.ReligionID = this.StudentView.StudentDetails.ReligionID;
            this.StudentEnquiryModel.SectionID = this.StudentView.StudentDetails.SectionID.toString().trim();
            this.StudentEnquiryModel.RelationshipID = this.StudentView.StudentDetails.RelationShipID;
            this.StudentEnquiryModel.StudentPhotoPath = this.StudentView.StudentDetails.StudentPhotoPath;
            this.StudentEnquiryModel.IDCardTypeID = this.StudentView.StudentDetails.IDCardTypeID.toString().trim();
            this.StudentEnquiryModel.IDCardNo = this.StudentView.StudentDetails.IDCardNo;
            this.StudentEnquiryModel.ScholarshipID = this.StudentView.StudentDetails.ScholarshipID;
            this.StudentEnquiryModel.HouseID = this.StudentView.StudentDetails.HouseID.toString().trim();
            this.GetFeeInstallmentComponent();
            this.StudentEnquiryModel.PresentCountryID = this.StudentView.StudentDetails.PresentCountryID;

            this.GetPresentState();

            this.StudentEnquiryModel.PresentStateID = this.StudentView.StudentDetails.PresentStateID;
            this.StudentEnquiryModel.ParentID = this.StudentView.StudentDetails.ParentID;
            this.StudentEnquiryModel.Email = this.StudentView.StudentDetails.Email;
            this.StudentEnquiryModel.DOB = this.StudentView.StudentDetails.DOB != null && this.StudentView.StudentDetails.DOB != '' ? moment(this.StudentView.StudentDetails.DOB).format('DD-MMM-YYYY') : '';
            this.StudentEnquiryModel.FirstName = this.StudentView.StudentDetails.FirstName;
            this.StudentEnquiryModel.StudentStatus = this.StudentView.StudentDetails.StudentStatus.toString().trim();
            this.StudentEnquiryModel.BranchName = this.StudentView.StudentDetails.BranchName;
            this.StudentEnquiryModel.BranchCode = this.StudentView.StudentDetails.BranchCode;
            this.StudentEnquiryModel.StudentNo = this.StudentView.StudentDetails.StudentNo;
            this.StudentEnquiryModel.GRNo = this.StudentView.StudentDetails.GRNo;
            this.StudentEnquiryModel.WithDrawalReason = this.StudentView.StudentDetails.WithDrawalReason;
            if (this.StudentEnquiryModel.StudentStatus == 5 || this.StudentEnquiryModel.StudentStatus == 9 || this.StudentEnquiryModel.StudentStatus == 10) {
                this.StudentEnquiryModel.IsWithdraw = true;
            }
            this.GetSection();

            this.GetDocument();
            this.StudentDocumentList = this.StudentView.StudentDocumentList;
            this.BrothersAndSistersList = this.StudentView.StudentSeblingList;
            this.GetPresentCity();
            this.StudentEnquiryModel.PresentCityID = this.StudentView.StudentDetails.PresentCityID;
            this.CourseList = data.CourseList;
            this.AllStudentConcession();
            this.GetStudentAdhocFee();
            this.IsUpdate = true;

            this.loadingSearch = false;
        });
    };


    SetStudentID(item) {

        this.GetStudentViewByStudentID(item.StudentID, item.BranchID);
    }

    GetStudentViewByStudentID(StudentID, BranchID) {
        this.studentService.GetStudentDetailsByStudentID(StudentID, 0).subscribe((response) => {
            this.BranchDetails = response.BranchDetails
            this.StudentView = response.StudentViewList;
            this.StudentEnquiryModel.AdmissionDate = moment(this.StudentView.StudentDetails.AdmissionDate).format('DD-MMM-YYYY');

            this.StudentEnquiryModel = this.StudentView.StudentDetails;
            this.StudentEnquiryModel.ThirdLanguage = this.StudentView.StudentDetails.ThirdLanguage;
            this.StudentEnquiryModel.HealthCondition = this.StudentView.StudentDetails.HealthCondition;
            this.StudentEnquiryModel.Session = this.StudentView.StudentDetails.Session;
            this.StudentEnquiryModel.SectionName = this.StudentView.StudentDetails.SectionName;
            this.StudentEnquiryModel.Specialattention = this.StudentView.StudentDetails.Specialattention;
            this.StudentEnquiryModel.Address = this.StudentView.StudentDetails.Address;
            this.StudentEnquiryModel.ClassID = this.StudentView.StudentDetails.ClassID.toString().trim();
            this.StudentEnquiryModel.PrevClassID = this.StudentView.StudentDetails.PrevClassID.toString().trim();
            this.StudentEnquiryModel.PresentStateID = this.StudentView.StudentDetails.PresentStateID;
            this.StudentEnquiryModel.ParentID = this.StudentView.StudentDetails.ParentID;
            this.StudentEnquiryModel.RelationShipID = this.StudentView.StudentDetails.RelationShipID;
            this.StudentEnquiryModel.EmailID = this.StudentView.StudentDetails.EmailID;
            this.StudentEnquiryModel.HouseID = this.StudentView.StudentDetails.HouseID.toString().trim();
            this.StudentEnquiryModel.DOB = moment(this.StudentView.StudentDetails.DOB).format('DD-MMM-YYYY');
            this.StudentEnquiryModel.PresentCityID = this.StudentView.StudentDetails.PresentCityID;
            this.StudentEnquiryModel.LocationID = this.StudentView.StudentDetails.LocationID.toString().trim();
            this.StudentEnquiryModel.StudentPOBox = this.StudentView.StudentDetails.StudentPOBox;
            this.StudentEnquiryModel.ClassName = this.StudentView.StudentDetails.ClassName;
            this.GetFeeInstallmentComponent();
            this.StudentDocumentList = this.StudentView.StudentDocumentList;
            this.CourseList = this.StudentView.CourseDetailsList;
            this.StudentEnquiryModel.IDCardTypeID = this.StudentView.StudentDetails.IDCardTypeID.toString().trim();

            this.StudentEnquiryModel.IDCardNo = this.StudentView.StudentDetails.IDCardNo;

            this.BrothersAndSistersList = this.StudentView.StudentSeblingList;



            if (this.StudentView.ParentsDetails != null) {
                this.StudentEnquiryModel.FatherName = this.StudentView.ParentsDetails.FatherName;
                this.StudentEnquiryModel.FatherNationality = this.StudentView.ParentsDetails.FatherNationality;
                this.StudentEnquiryModel.MotherNationality = this.StudentView.ParentsDetails.MotherNationality;
                this.StudentEnquiryModel.FatherCompanyAddress = this.StudentView.ParentsDetails.FatherCompanyAddress;
                this.StudentEnquiryModel.FatherCompanyName = this.StudentView.ParentsDetails.FatherCompanyName;
                this.StudentEnquiryModel.FatherContactCell = this.StudentView.ParentsDetails.FatherContactCell;
                this.StudentEnquiryModel.FatherContactHome = this.StudentView.ParentsDetails.FatherContactHome;
                this.StudentEnquiryModel.FatherContactWork = this.StudentView.ParentsDetails.FatherContactWork;
                this.StudentEnquiryModel.FatherDesignation = this.StudentView.ParentsDetails.FatherDesignation;
                this.StudentEnquiryModel.FatherOccupation = this.StudentView.ParentsDetails.FatherOccupation;
                this.StudentEnquiryModel.FatherOfficeEmailID = this.StudentView.ParentsDetails.FatherOfficeEmailID;
                this.StudentEnquiryModel.FatherPermanentAddress = this.StudentView.ParentsDetails.FatherPermanentAddress;
                this.StudentEnquiryModel.FatherPersonalMailID = this.StudentView.ParentsDetails.FatherPersonalMailID;
                this.StudentEnquiryModel.FatherPhotoPath = this.StudentView.ParentsDetails.FatherPhotoPath;
                this.StudentEnquiryModel.FatherPresentAddress = this.StudentView.ParentsDetails.FatherPresentAddress;

                this.StudentEnquiryModel.MotherName = this.StudentView.ParentsDetails.MotherName;
                this.StudentEnquiryModel.MotherCompanyAddress = this.StudentView.ParentsDetails.MotherCompanyAddress;
                this.StudentEnquiryModel.MotherCompanyName = this.StudentView.ParentsDetails.MotherCompanyName;
                this.StudentEnquiryModel.MotherContactCell = this.StudentView.ParentsDetails.MotherContactCell;

                this.StudentEnquiryModel.MotherOccupation = this.StudentView.ParentsDetails.MotherOccupation;
                this.StudentEnquiryModel.PermanentAddress = this.StudentView.ParentsDetails.PermanentAddress;
                this.StudentEnquiryModel.MotherPhotoPath = this.StudentView.ParentsDetails.MotherPhotoPath;

                this.StudentEnquiryModel.FatherPhotoPath = this.StudentView.ParentsDetails.FatherPhotoPath;
                this.StudentEnquiryModel.MotherPhotoPath = this.StudentView.ParentsDetails.MotherPhotoPath;
                this.CourseList = response.CourseList;
                this.StudentEnquiryModel.Count == 2;

            }

        });
    }



    ValidateDropdown() {
        return this.StudentEnquiryModel.Gender == '0' || this.StudentEnquiryModel.SectionID == 0 || this.StudentEnquiryModel.ClassID == 0 || this.StudentEnquiryModel.StudentStatus == 0;
    };
    CopyPresentAddress() {

        // var ddlPresentState = document.getElementById('ddlPresentState');
        // var PresentState = ddlPresentState.options[ddlPresentState.selectedIndex].text;
        // var ddlPresentCity = document.getElementById('ddlPresentCity');
        // var PresentCity = ddlPresentCity.options[ddlPresentCity.selectedIndex].text;
        if (this.StudentEnquiryModel.IsPresentAddress) {
            this.StudentEnquiryModel.PermanentCountryID = this.StudentEnquiryModel.PresentCountryID;
            // this.StudentEnquiryModel.PermanentState = PresentState;
            // this.StudentEnquiryModel.PermanentCity = PresentCity;
            this.StudentEnquiryModel.PermanentAddress = this.StudentEnquiryModel.PresentAddress;
            this.StudentEnquiryModel.PermanentZipCode = this.StudentEnquiryModel.PresentZipCode;
        }
        else {
            this.StudentEnquiryModel.PermanentCountryID = 0;
            this.StudentEnquiryModel.PermanentState = '';
            this.StudentEnquiryModel.PermanentCity = '';
            this.StudentEnquiryModel.PermanentAddress = '';
            this.StudentEnquiryModel.PermanentZipCode = '';

        }

    };

    SubmittedBasicDetails = false;
    SubmittedParents = false;
    loading1 = false;
    SaveStudentRegistration(valid) {

        this.SubmittedBasicDetails = true;
        if (valid && !this.ValidateDropdown()) {
            this.loading1 = true;
            this.StudentEnquiryModel.DOB = moment(this.StudentEnquiryModel.DOB).format('DD-MMM-YYYY');
            this.StudentEnquiryModel.AdmissionDate = moment(this.StudentEnquiryModel.AdmissionDate).format('DD-MMM-YYYY');
            this.studentService.SaveStudentRegistration(JSON.stringify(this.StudentEnquiryModel), 'StudentDetails').subscribe((response) => {

                this.loading1 = false;


                this.globalHelper.CheckActionStatus(response);
                this.StudentEnquiryModel.StudentID = response.StudentID;
                this.AllStudentConcession();

            });
        }
    }

    loadingParents = false;
    SaveParentsDetails(valid) {

        this.SubmittedParents = true;
        if (valid) {
            this.loadingParents = true;
            this.studentService.SaveStudentRegistration(JSON.stringify(this.StudentEnquiryModel), 'ParentsDetails').subscribe((response) => {

                this.loadingParents = false;

                this.globalHelper.CheckActionStatus(response);
                this.StudentEnquiryModel.StudentID = response.StudentID;

            });
        }
    }



    GetStudentID(obj) {

        this.isCollapsed = false;
        this.isCollapsed1 = true;
        this.isCollapsed2 = true;
        this.isCollapsed3 = true;
        this.isCollapsed4 = true;
        this.isCollapsed5 = true;
        this.isCollapsed6 = true;
        this.isCollapsed10 = true;
        this.isCollapsedCourse = true;
        this.isCollapsedAdhoc = true;
        this.StudentTransportModel.RouteID = 0;
        this.StudentTransportModel.RouteLocationID = 0;
        this.ViewLocation = false;
        this.RouteInstallmentList = [];
        this.IsUpdate = false;
        this.SubmittedBasicDetails = false;
        this.SubmittedParents = false;
        this.UpdateShow = false;
        this.StudentEnquiryModel.IsWithdraw = false;
        this.StudentEnquiryModel.WithDrawalReason = '';
        this.StudentEnquiryModel.StudentStatus = 0;
        this.StudentEnquiryModel.StudentID = obj.StudentID;
        this.UpdateParentModel.ParentID = obj.ParentID;
        this.AddTransportInstallment();
        this.GetStudentRouteInstallment();
        this.GetStudentDetailsByStudentID();
        this.GetStudentAdhocComponent();
    }


    ValidateOldStudent() {
        return this.StudentEnquiryModel.ClassID == 0 || this.StudentEnquiryModel.SectionID == 0
    };

    Reset() {
        this.SubmittedBasicDetails = false;
        // this.StudentEnquiryModel = [];
        this.StudentTransportModel.RouteID = 0;
        this.StudentTransportModel.RouteLocationID = 0;
    }

    AcademicSessionList: any;
    GetAcademicSession() {
        var itembo = {
            'BranchID': this.StudentEnquiryModel.BranchID
        }

        this.lookupService.GetLookupsList('AcademicSession', JSON.stringify(itembo)).subscribe((data) => {
            this.AcademicSessionList = data.LookupItems;
        });
    };


    StudentDocumentModel = {
        DocumentID: "0",
        Notes: "",

    };

    loadingButtonSubmit = false;
    SaveApplicationGranted() {
        this.SubmittedParents = true;
        this.loadingButtonSubmit = true;
        this.studentService.SaveStudentRegistration(JSON.stringify(this.StudentEnquiryModel), 'ApplicationGranted').subscribe((response) => {

            // $('#btnClose').click();
            this.loadingButtonSubmit = false;
            this.globalHelper.CheckActionStatus(response);
            this.StudentEnquiryModel.StudentID = response.StudentID;


        });

    }
    SpecialCategoryList: any;
    GetSpecialCategory() {

        this.lookupService.GetLookupsList('SpecialCategory', '').subscribe((data) => {
            this.SpecialCategoryList = data.LookupItems;


        });

    };


    SelectModel: any;


    SelectAll() {
        if (this.SelectModel.Active) {
            for (var i = 0; i < this.RouteInstallmentList.length; i++) {
                this.RouteInstallmentList[i].IsActive = true;
            }
        }
        if (this.SelectModel.Active == false) {
            for (var i = 0; i < this.RouteInstallmentList.length; i++) {
                this.RouteInstallmentList[i].IsActive = false;
            }
        }
    }
    ChangeSelect(item) {
        var Count = 0;
        for (var i = 0; i < this.RouteInstallmentList.length; i++) {
            if (this.RouteInstallmentList[i].IsActive) {
                Count = Count + 1;
            }
        }

        if (Count == this.RouteInstallmentList.length) {
            this.SelectModel.Active = true;
        }
        else {
            this.SelectModel.Active = false;
        }
    }
    BusList: any;
    GetAllBusNo() {
        var itembo = {
            'ItemId': this.StudentTransportModel.RouteID
        }
        this.lookupService.GetLookupsList('BusName', JSON.stringify(itembo)).subscribe((data) => {
            this.BusList = data.LookupItems;
        });
    };
    MonthNameList = [];
    GetMonthList() {
        this.lookupService.GetLookupsList('MonthName', '').subscribe((data) => {
            var MonthList = data.LookupItems;
            for (var i = 0; i < MonthList.length; i++) {
                var item = {
                    ItemId: MonthList[i].ItemId,
                    ItemName: MonthList[i].ItemName,
                    Ticked: false
                };
                this.MonthNameList.push(item);
            }
        });
    };




    GetCommaSeperatedMonth() {
        var MonthID = '';
        var MonthNameList = this.MonthNameList;
        for (var i = 0; i < MonthNameList.length; i++) {
            if (MonthNameList[i].Ticked && MonthID == '') {
                MonthID = MonthNameList[i].ItemName;
            }
            else if (MonthNameList[i].Ticked) {
                MonthID = MonthID + "," + MonthNameList[i].ItemName;
            }
        }
        this.StudentTransportModel.StartMonth = MonthID;
    }
    Fill = true;
    SaveTransport() {
        this.GetCommaSeperatedMonth();

        this.studentService.SaveTransport(JSON.stringify(this.StudentTransportModel), this.StudentEnquiryModel.StudentID).subscribe((response) => {
            this.Fill = true;

            this.globalHelper.CheckActionStatus(response);
            this.StudentTransportModel.StudentID = response.StudentID;

        })

    };

    StudentFeeDetailsList: any;
    loading = false;
    DiscountRemarksList:any;
    DiscountRemarks:any;
    CreatedOn:any=moment(new Date()).format('DD-MMM-YYYY');
    valid = true;
    SaveConsession() {
        if(this.CreatedOn==null || this.CreatedOn=='')
        {
            alert('Please select Date')
           return false;
        }
        if(this.DiscountRemarks==null || this.DiscountRemarks=='')
        {
            alert('Please enter discount remarks.')
           return false;
        }

        this.valid = true;
        var FeeComponentList = this.ComponentList;
        for (var i = 0; i < FeeComponentList.length; i++) {
            for (var j = 0; j < FeeComponentList[i].InstallmentList.length; j++) {
                if ((Math.round(FeeComponentList[i].InstallmentList[j].FeeAmount * 1000) / 1000 - Math.round(FeeComponentList[i].InstallmentList[j].DiscountAmount * 1000) / 1000) < (Math.round(FeeComponentList[i].InstallmentList[j].Amount * 1000) / 1000+Math.round(FeeComponentList[i].InstallmentList[j].WaiverAmount * 1000) / 1000)) {
                    this.valid = false;
                    break;
                }
            }
        }

        if (this.valid) {
            this.spinnerService.show();
            this.studentService.SaveConsession(JSON.stringify(this.ComponentList), this.StudentEnquiryModel.StudentID, this.StudentEnquiryModel.ClassID,this.DiscountRemarks,this.CreatedOn).subscribe((response) => {
                this.Fill = true;
                this.spinnerService.hide();
                this.globalHelper.CheckActionStatus(response);
                this.StudentFeeDetailsList = response.StudentFeeDetailsList;
                this.AllStudentConcession();
                this.GetFeeInstallmentComponent();
            })
        }
        else {

        }
    };








    Clear() {
        this.IsUpdate = false;
        this.StudentTransportModel.RouteID = 0;
        this.FilterStudentAdmissionModel = {
            ClassID: 0,
            ParentID: 0,
            Print: "",
            SectionID: 0,
            StudentStatus: 0,
            BranchID: 0,
            SessionID: 0,
            FirstName: '',
            FromDate: '',
            ToDate: '',
            RegistrationNumber: '',
            StudentNo: '',
            CurrentIndex: 0,
            PageSize: 20,
            LocationID: 0,
        }

        this.LocationList = {};
        this.isCollapsed = false;
        this.isCollapsed1 = true;
        this.isCollapsed2 = true;
        this.isCollapsed3 = true;
        this.isCollapsed4 = true;
        this.isCollapsed5 = true;
        this.isCollapsed6 = true;
        this.isCollapsed10 = true;
        this.isCollapsedCourse = true;
    };

    FilePath: any;


    SelectedFileForStudentUpload: any;
    selectFileforStudentUpload(file) {
        this.SelectedFileForStudentUpload = file[0];
    };
    SelectedFileForFatherUpload: any;
    selectFileforFatherUpload(file) {
        this.SelectedFileForFatherUpload = file[0];
    };
    SelectedFileForMotherUpload: any;
    selectFileforMotherUpload(file) {
        this.SelectedFileForMotherUpload = file[0];
    };
    SelectedFileForDocumentUpload: any;
    selectFileforDocumentUpload(file) {
        this.SelectedFileForDocumentUpload = file[0];
    };
    StudentPhotoUpload() {
        this.studentService.UploadPhoto(this.SelectedFileForStudentUpload, this.StudentEnquiryModel.StudentID, 'StudentPhoto', '', '').subscribe((response) => {
            this.globalHelper.CheckActionStatus(response);
            this.GetStudentDetailsByStudentID();
        })
    }
    FatherPhotoUpload() {
        this.studentService.UploadPhoto(this.SelectedFileForFatherUpload, this.StudentEnquiryModel.StudentID, 'FatherPhoto', '', '').subscribe((response) => {
            this.globalHelper.CheckActionStatus(response);
            this.GetStudentDetailsByStudentID();
        })

    }
    MotherPhotoUpload() {
        this.studentService.UploadPhoto(this.SelectedFileForMotherUpload, this.StudentEnquiryModel.StudentID, 'MotherPhoto', '', '').subscribe((response) => {
            this.globalHelper.CheckActionStatus(response);
            this.GetStudentDetailsByStudentID();
        })

    }

    DocumentUpload() {
        this.studentService.UploadPhoto(this.SelectedFileForDocumentUpload, this.StudentEnquiryModel.StudentID, 'StudentDocument', this.StudentDocumentModel.DocumentID, this.StudentDocumentModel.Notes).subscribe((response) => {
            this.globalHelper.CheckActionStatus(response);
            this.GetStudentDetailsByStudentID()
        })

    }

    removeDocument(obj) {
        this.StudentDocumentModel.DocumentID = obj.DocumentID;
        this.studentService.UploadPhoto('', this.StudentEnquiryModel.StudentID, 'RemoveDocument', this.StudentDocumentModel.DocumentID, '').subscribe((response) => {
            this.globalHelper.CheckActionStatus(response);
            this.GetStudentDetailsByStudentID();
        })
    }
    coursemsg = '';


    sortingOrder = 0;

    groupedItems = [];
    itemsPerPage = 40;

    query = '';
    PagingInit() {
        this.sortingOrder = 0;

        this.groupedItems = [];
        this.itemsPerPage = 40;

        this.query = '';
    }


    Print() {

        if (this.FilterStudentAdmissionModel.ClassID != 0) {
            var url = "studentlistingreport?ReportType=PrintStudentList&ClassID=" + this.FilterStudentAdmissionModel.ClassID + "&SectionID=" + this.FilterStudentAdmissionModel.SectionID + "&SessionID=" + this.FilterStudentAdmissionModel.SessionID + "&StudentStatus=" + this.FilterStudentAdmissionModel.StudentStatus + "&ParentID=" + this.FilterStudentAdmissionModel.ParentID + "&StudentNo=" + this.FilterStudentAdmissionModel.StudentNo + "&RegistrationNumber=" + this.FilterStudentAdmissionModel.RegistrationNumber + "&FirstName=" + this.FilterStudentAdmissionModel.FirstName;
            this.globalHelper.OpenPopUpWindow(url, "yes", "950", "1000");
        }
        else {
            alert('please select Class')
        }
    };




    // };







    GetStudentRouteInstallment() {

        this.studentService.GetStudentRouteInstallment(this.StudentEnquiryModel.StudentID).subscribe((response) => {
            this.RouteInstallmentList = response.RouteInstallmentList;


        });

    };
    RouteLocationInstallmentList: any;
    AddTransportInstallment() {

        var itembo = {
            'ItemId': this.StudentEnquiryModel.StudentID
        }

        this.lookupService.GetLookupsList('TransportInstallment', JSON.stringify(itembo)).subscribe((data) => {
            this.RouteLocationInstallmentList = data.LookupItems;
            this.RouteLocationInstallmentList.splice(0, 1);
        });

    }

    GetCommaInstallment() {

        var Installments = '';
        var InstallmentList = this.RouteLocationInstallment;
        for (var i = 0; i < InstallmentList.length; i++) {
            if (Installments == '') {
                Installments = InstallmentList[i].ItemId;
            }
            else {
                Installments = Installments + "," + InstallmentList[i].ItemId;
            }
        }
        this.StudentTransportModel.Installment = Installments;


    }

    InstallmentAlert1 = '';
    SubmittedTransport = true;
    SaveTransportRouteLocation() {

        this.InstallmentAlert1 = '';
        this.SubmittedTransport = true;

        if (!this.ValidityTransport()) {
            this.GetCommaInstallment();
            if (this.StudentTransportModel.Installment == '') {
                this.InstallmentAlert1 = 'Please Select Installment';
                return;
            }

            this.StudentTransportModel.StudentID = this.StudentEnquiryModel.StudentID;

            this.studentService.SaveTransportRouteLocation(JSON.stringify(this.StudentTransportModel)).subscribe((response) => {

                this.SubmittedTransport = false;
                if (response.webResponse.Status) {
                    this.AddTransportInstallment();
                    this.GetStudentRouteInstallment();
                    this.ClearRouteLocation();
                    this.RouteLocationInstallment = [];
                }
                this.globalHelper.CheckActionStatus(response);
            });
        }

    }

    ClearRouteLocation() {
        this.SubmittedTransport = false;
        this.ViewLocation = false;
        this.InstallmentAlert1 = '';

        this.AddTransportModel.RouteID = 0;
        this.AddTransportModel.RouteLocationID = 0;

        for (var i = 0; i < this.RouteLocationInstallmentList.length; i++) {
            this.RouteLocationInstallmentList[i].Ticked = false;
        }
    };
    InstallmentAdd = false;
    InstallmentAlert = '';
    Cancel() {
        this.InstallmentAdd = false;
        this.InstallmentAlert = '';
    }
    RLocationID: any;
    GetLocationID(item) {

        this.InstallmentAdd = true;
        this.RLocationID = item.RouteLocationID;
        this.AddTransportModel.RouteLocationID = item.RouteLocationID;
        this.AddTransportInstallment();

    }

    ClearInstallment() {
        this.InstallmentAlert = '';
    }


    ViewAddLocation() {
        this.ViewLocation = true;
        this.StudentTransportModel.RouteID = 0;
        this.StudentTransportModel.RouteLocationID = 0;
    }


    ValidityTransport() {
        return this.StudentTransportModel.RouteLocationID == 0;
    }
    LocationInstallmentList: any;
    loadingTransport = false;
    SaveStudentRouteInstallment() {
        this.InstallmentAlert = '';
        this.AddTransportModel.StudentID = this.StudentEnquiryModel.StudentID;
        this.LocationInstallmentList = [];
        if (this.RouteLocationInstallmentList != null) {
            for (var i = 0; i < this.RouteLocationInstallmentList.length; i++) {

                if (this.RouteLocationInstallmentList[i].IsActive) {
                    var itemInstallment = {
                        'InstallmentID': this.RouteLocationInstallmentList[i].ItemId
                    }
                    this.LocationInstallmentList.push(itemInstallment);
                }
            }
        }
        if (this.LocationInstallmentList.length == 0) {
            this.InstallmentAlert = 'Please Select Installment';
            return;
        }
        this.loadingTransport = true;

        this.studentService.SaveStudentRouteInstallment(JSON.stringify(this.AddTransportModel), JSON.stringify(this.LocationInstallmentList)).subscribe((response) => {
            this.loadingTransport = false;
            this.SubmittedTransport = false;
            if (response.webResponse.Status) {
                this.GetStudentRouteInstallment();
                this.AddTransportInstallment();
                this.InstallmentAdd = false;
                this.RLocationID = 0;
            }
            this.globalHelper.CheckActionStatus(response);
        });


    };

    loadingUpdateTransport = false;
    UpdateStudentRouteInstallment() {
        if (this.RouteInstallmentList != null && this.RouteInstallmentList.length > 0) {
            for (var i = 0; i < this.RouteInstallmentList.length; i++) {
                if (this.RouteInstallmentList[i].ChildList != null && this.RouteInstallmentList[i].ChildList.length > 0) {
                    for (var j = 0; j < this.RouteInstallmentList[i].ChildList.length; j++) {
                        if (this.RouteInstallmentList[i].ChildList[j].DiscountAmount > this.RouteInstallmentList[i].ChildList[j].Amount) {
                            return;

                        }
                    }
                }
            }
        }
        this.loadingUpdateTransport = true;

        this.studentService.UpdateStudentRouteInstallment(JSON.stringify(this.StudentEnquiryModel), JSON.stringify(this.RouteInstallmentList)).subscribe((response) => {
            this.loadingUpdateTransport = false;
            if (response.webResponse.Status) {
                this.GetStudentRouteInstallment();
            }
            this.globalHelper.CheckActionStatus(response);
        });

    };

    loadingDeleteTransport = false;
    RemoveTransportInstallment(itemChild) {
        this.loadingDeleteTransport = true;
        this.AddTransportModel.StudentFeeStructureID = itemChild.StudentFeeStructureID;
        this.AddTransportModel.StudentRouteLocationID = itemChild.StudentRouteLocationID;
        this.AddTransportModel.StudentID = this.StudentEnquiryModel.StudentID;
        if (confirm("Are you sure to delete  the Installment")) {

            this.studentService.RemoveTransportInstallment(JSON.stringify(this.AddTransportModel)).subscribe((response) => {
                this.loadingDeleteTransport = false;
                this.SubmittedTransport = false;
                if (response.webResponse.Status) {
                    this.GetStudentRouteInstallment();
                    this.AddTransportInstallment();
                }
                this.globalHelper.CheckActionStatus(response);
            });

        }
        return false;

    }





    ngOnInit() {
        this.GetSpecialCategory();
        this.GetCategory();
        this.GetLocation();
        this.PagingInit();
        this.GetStudentStatus();
        this.GetClass();
        this.GetSession();
        this.GetMonthList();
        this.GetHouse();
        this.GetIDCardType();
        // this.GetDocument();
        this.GetBranch();
        this.GetMotherTongue();
        this.GetRelationship();
        this.GetReligion();
        this.GetBloodGroup();
        this.GetNationality();
        this.GetPresentCountry();
        this.GetPermanentCountry();
        this.GetAllRoute();
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'ItemId',
            textField: 'ItemName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: false
        };

        this.GetStudentID(this.prmstudent)
    }

    passBack() {
        this.passEntry.emit(this.prmstudent);
        this.activeModal.close(this.prmstudent);
    }

    // Student Adhoc
    AdhocComponentList: any
    GetStudentAdhocComponent() {
        this.spinnerService.show();
        this.studentService.GetStudentAdhocComponent(this.StudentEnquiryModel.StudentID).subscribe((response) => {
            this.AdhocComponentList = response.AdhocComponentList;
            this.spinnerService.hide();
        });
    };


    SaveStudentAdhocComponent() {
        this.spinnerService.show();
        this.studentService.SaveStudentAdhocComponent(JSON.stringify(this.AdhocComponentList), this.StudentEnquiryModel.StudentID, this.StudentEnquiryModel.ClassID).subscribe((response) => {
            this.Fill = true;
            this.spinnerService.hide();
            this.globalHelper.CheckActionStatus(response);
            this.GetStudentAdhocComponent();
        })
    };
}
