import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalHelper } from '../Common/global.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor(private globalHelper: GlobalHelper, private httpclient: HttpClient) { }

  GetDashboardDetails(objModel): Observable<any> {
    let formData = new FormData();
    formData.append("objdata", objModel);   
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Layout/GetDashboardDetails', formData);
  }
  GetDashboardStaffAttendance(objModel): Observable<any> {
    let formData = new FormData();
    formData.append("objdata", objModel);   
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Layout/GetDashboardStaffAttendance', formData);
  }
  GetDashboardStudentAttendance(objModel): Observable<any> {
    let formData = new FormData();
    formData.append("objdata", objModel);   
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Layout/GetDashboardStudentAttendance', formData);
  }
  GetDashboardGenderReport(objModel): Observable<any> {
    let formData = new FormData();
    formData.append("objdata", objModel);   
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Layout/GetDashboardGenderReport', formData);
  }
  GetNoticeDetails(): Observable<any> {
    let formData = new FormData();
    
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Layout/GetNoticeDetails', formData);
  }
  GetEvents(): Observable<any> {
    let formData = new FormData();
       
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Layout/GetEvents', formData);
  }
  GetCirculars(): Observable<any> {
    let formData = new FormData();
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Layout/GetCirculars', formData);
  }
  GetHolidays(): Observable<any> {
    let formData = new FormData();
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Layout/GetHolidays', formData);
  }
}
