import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.css']
})
export class DesignationComponent implements OnInit {

  Submitted = false;
  DesignationList: any;
  
  constructor(private MasterService: MasterService, private globalHelper: GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }
  
  DesignationSearchModel = {
   
    DesignationName: '',
    
           
  };

  GetAllDesignation = function () {
    this.spinnerService.show();
    this.MasterService.GetAllDesignation(JSON.stringify(this.DesignationSearchModel)).subscribe((response: any) => {
      this.DesignationList = response.DesignationList;
      this.spinnerService.hide();
    });
  };
  
  DesignationModel = {
    DesignationID: 0,          
    DesignationName: '',
    IsActive:true,
  };

  Clear() {
    this.DesignationModel.DesignationID = 0;
    this.DesignationModel.DesignationName = '';   
    this.DesignationModel.IsActive = true;
    this.Submitted = false;
  };

 


  SaveDesignation(valid) {
    this.Submitted = true;

    if (valid) { 
      this.spinnerService.show();
      this.MasterService.SaveDesignation(JSON.stringify(this.DesignationModel)).subscribe((data: any) => {
        if (data.webResponse.Status) {
          this.GetAllDesignation();
        }
        if (data.webResponse.Status && this.DesignationModel.DesignationID== 0) {
          this.Clear();
          this.GetAllDesignation();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
    
  };

  DesignationMarkActive(item) {
    this.DesignationModel.DesignationID = item.DesignationID;
    this.DesignationModel.IsActive = item.IsActive == true ? false : true;
    this.spinnerService.show();
    this.MasterService.DesignationMarkActive(JSON.stringify(this.DesignationModel)).subscribe((data: any) => {
      if (data.webResponse.Status) {
        this.Clear();
        this.GetAllDesignation();
      }
      this.globalHelper.CheckActionStatus(data);
      this.spinnerService.hide();
    });

  };

  Edit(obj) {

    this.DesignationModel.DesignationID = obj.DesignationID;
    this.DesignationModel.DesignationName = obj.DesignationName;
    this.DesignationModel.IsActive = obj.IsActive;


  };


  // Init and Lookup

  ngOnInit() {
    this.GetAllDesignation();
  }
 

}
