import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TransportService } from 'src/app/services/Transport/transport.service';
import * as moment from 'moment';

@Component({
  selector: 'app-online-transaction',
  templateUrl: './online-transaction.component.html',
  styleUrls: ['./online-transaction.component.css']
})
export class OnlineTransactionComponent implements OnInit {

  constructor(private transportService: TransportService, private spinnerService: Ng4LoadingSpinnerService, private financeService: FinanceService, private lookupService: LookupService, public globalHelper: GlobalHelper) { }
  SearchModel = {
    StudentID: 0,
    SectionID: 0,
    CancelStatus: 'N',
    ClassID: 0,
    StudentNo: '',
    Mobile:'',
    FromDate:  moment(new Date()).format("DD-MMM-YYYY"),
    PaymentMode: '',
    ToDate:  moment(new Date()).format("DD-MMM-YYYY"),
    CreatedBy: '0'
}
ClassList:any;
GetClass  () {

    this.lookupService.GetLookupsList('Class', '').subscribe((data) => {
        this.ClassList = data.LookupItems;
    });

};
ReceivedModel={
  Remarks:"",
  OnlineReceiptID:"",
  ChequeNo:""
};
Received  (item) {
    this.ReceivedModel = item;
}
OnlineTransactionList:any;
Submitted = false;
OnlinePaymentReceived  () { 
    this.spinnerService.show();   
    this.financeService.OnlinePaymentReceived(JSON.stringify(this.ReceivedModel)).subscribe((response) => {
        this.OnlineTransactionList = response.OnlineTransactionList;
        this.Submitted = false; 
        this.spinnerService.hide();     
       this.GetOnlineTransaction();
    });
}
StudentList:any;
GetStudent  () {
    this.SearchModel.StudentID = 0;
    var itembo = {
        ItemId: this.SearchModel.ClassID,
        ItemId2: this.SearchModel.SectionID
    }
    this.lookupService.GetLookupsList('Student', JSON.stringify(itembo)).subscribe((data) => {
        this.StudentList = data.LookupItems;


    });
};
SectionList:any;
GetSection  () {
    this.SearchModel.SectionID = 0;
    this.SearchModel.StudentID = 0;
    var ItemBo = {
        'ItemId': this.SearchModel.ClassID
    }
    this.lookupService.GetLookupsList('SectionName', JSON.stringify(ItemBo)).subscribe((data) => {
        this.SectionList = data.LookupItems;

    });
};



GetOnlineTransaction  () {
    this.SearchModel.FromDate=this.SearchModel.FromDate==""?"":moment(this.SearchModel.FromDate).format("DD-MMM-YYYY");
    this.SearchModel.ToDate=this.SearchModel.ToDate==""?"":moment(this.SearchModel.ToDate).format("DD-MMM-YYYY");
    this.Submitted = true;
    this.spinnerService.show();
 this.financeService.GetOnlineTransaction(JSON.stringify(this.SearchModel)).subscribe((response) => {
            this.OnlineTransactionList = response.OnlineTransactionList;
            this.Submitted = false;
            this.spinnerService.hide();
      
        });
    
}


  ngOnInit() {
    this.GetClass();
    this.GetSection();
    this.GetStudent();
    
  }

  GetBasic(item){

    return JSON.stringify(item)

 }

}
