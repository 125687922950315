import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MasterRoutingModule } from './master-routing.module';
import { BranchProfileComponent } from './branchprofile/branchprofile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminModule } from '../admin/admin.module';
import { CityComponent } from './city/city.component';
import { ClassComponent } from './class/class.component';
import { ClassGroupComponent } from './classgroup/classgroup.component';
import { SectionComponent } from './section/section.component';
import { ClassSectionMapComponent } from './classsectionmap/classsectionmap.component';
import { CountryComponent } from './country/country.component';
import { DepartmentComponent } from './department/department.component';
import { DesignationComponent } from './designation/designation.component';
import { AdmissionDocumentComponent } from './admissiondocument/admissiondocument.component';
import { AdmissionOpenComponent } from './admissionopen/admissionopen.component';
import { StudentLocationComponent } from './studentlocation/studentlocation.component';
import { SpecialCategoryComponent } from './specialcategory/specialcategory.component';
import { StateComponent } from './state/state.component';
import { QualificationComponent } from './qualification/qualification.component';
import { RoomComponent } from './room/room.component';
import { TestVenueComponent } from './testvenue/testvenue.component';
import { HouseComponent } from './house/house.component';
import { IdCardTypeComponent } from './idcardtype/idcardtype.component';
import { SubjectComponent } from './subject/subject.component';
import { SchoolLocationComponent } from './schoollocation/schoollocation.component';
import { BsDatepickerModule } from 'ngx-bootstrap';


@NgModule({
  declarations: [BranchProfileComponent, CityComponent, ClassComponent, ClassGroupComponent, SectionComponent,
    ClassSectionMapComponent,CountryComponent,DepartmentComponent,DesignationComponent,
    AdmissionDocumentComponent,AdmissionOpenComponent,StudentLocationComponent,
    SpecialCategoryComponent,StateComponent,QualificationComponent,RoomComponent,
    TestVenueComponent,HouseComponent,IdCardTypeComponent,SubjectComponent, SchoolLocationComponent],
 
    imports: [
    CommonModule,
    MasterRoutingModule,    
    AdminModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot()
    
  ]
})
export class MasterModule { }
