import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css']
})
export class CountryComponent implements OnInit {

  Submitted: boolean = false;
  CountryList: any;
 
  constructor(private MasterService: MasterService,private globalHelper:GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  CountryModel = {
    CountryID: 0,
    CountryName: '',
    IsActive: true,
    Nationality:'',
   
  };

  Clear() {
    this.CountryModel.CountryID = 0;
    this.CountryModel.CountryName = '';
    this.CountryModel.Nationality = '';
    this.CountryModel.IsActive = true;
    this.Submitted = false;
  };

  GetCountry = function () {
    this.spinnerService.show();
    this.MasterService.GetCountry().subscribe((response: any) => {
      this.CountryList = response.CountryList; 
      this.spinnerService.hide();    
    });
  };

  SaveCountry(valid) {
    this.Submitted = true;
    if (valid) {
      this.spinnerService.show();
      this.MasterService.SaveCountry(JSON.stringify(this.CountryModel)).subscribe((data: any) => {
        if (data.webResponse.Status) {        
          this.GetCountry();
        }
        if (data.webResponse.Status && this.CountryModel.CountryID==0) {
          this.Clear();
          this.GetCountry();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
  };
 
  GetStatus(IsActive) {
    return IsActive ? 'Yes' : 'No';
  };

  Edit(obj) {

    this.CountryModel.CountryID = obj.CountryID;
    this.CountryModel.CountryName = obj.CountryName;
    this.CountryModel.Nationality = obj.Nationality;
    this.CountryModel.IsActive = obj.IsActive;


  };


  // Init and Lookup

  ngOnInit() {
    this.GetCountry();
    
  }

  

  }

 


