import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-admissionopen',
  templateUrl: './admissionopen.component.html',
  styleUrls: ['./admissionopen.component.css']
})
export class AdmissionOpenComponent implements OnInit {

  Submitted: boolean = false;
  SessionList: any;
  ClassList: any;
  
  constructor(private MasterService: MasterService, private LookupService: LookupService,private globalHelper:GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  ClassModel = {
    ClassID: 0,   
  };

  GetSession = function () {   
    this.spinnerService.show();
    this.MasterService.GetSession().subscribe((response: any) => {
      this.SessionList = response.SessionList;     
      this.spinnerService.hide();
    });
  };


  SaveAdmissionOpen() {
    this.Submitted = true;      
    this.spinnerService.show();
      this.MasterService.SaveAdmissionOpen(JSON.stringify(this.SessionList)).subscribe((data: any) => {       
        if (data.webResponse.Status) {
          this.GetSession();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    
  };


  ngOnInit() {
    this.GetSession();   
  }
   
 
}
