import { Component, Input, OnInit, Output ,EventEmitter} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import { GlobalHelper } from 'src/app/services/Common/global.service';
import { SalService } from 'src/app/Services/sal/sal.service';

@Component({
  selector: 'app-invoiceviewmodel',
  templateUrl: './invoiceviewmodel.component.html',
  styleUrls: ['./invoiceviewmodel.component.css']
})
export class InvoiceviewmodelComponent implements OnInit {

  
  @Input() public InvoiceDetails;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
 
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  ItemList:any;
  Details:any;
  SumTotalTax:any;
  TotalAmount:any;
  constructor(private activeModal: NgbActiveModal,  public globalHelper: GlobalHelper, 
    
    private salservice: SalService, private spinnerService: Ng4LoadingSpinnerService) {
   

   }
   OrganizationCountryID:any;
   OrganizationStateID:any;
   DisplayModel = {
    InvoiceID: 0,
  }
 
  GetInvoiceItem() {
   this.spinnerService.show();
    this.salservice.GetInvoiceItem(JSON.stringify(this.DisplayModel)).subscribe((response: any) => {
      this.ItemList = response.ItemList;
        
      this.SumTotalTax=0;
      this.TotalAmount=0;
      for (let index = 0; index < this.ItemList.length; index++) {
        
        this.SumTotalTax=this.SumTotalTax+this.ItemList[index].TaxAmount;
        this.TotalAmount=this.TotalAmount+this.ItemList[index].Amount*this.ItemList[index].Qty;
      }
      this.spinnerService.hide();
      this.globalHelper.CheckActionStatus(response);
    });
  }
  ngOnInit() {
    this.DisplayModel.InvoiceID = this.InvoiceDetails.InvoiceID;
    this.Details = this.InvoiceDetails;    
    this.GetInvoiceItem();
  }
 

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}
