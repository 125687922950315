import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Component({
  selector: 'app-fee-structure-component',
  templateUrl: './fee-structure-component.component.html',
  styleUrls: ['./fee-structure-component.component.css']
})
export class FeeStructureComponentComponent implements OnInit {
  FeeStructureComponentModel = {
    FeeComponentId: 0,
    MainComponentName: '',
    FeeReceivableAccountId: 0,
    FeeComponentTypeID: 0,
    IsActive: false
}
ComponentList :any;
ClassList :any;
AmountClassWise :any;
  constructor(private spinnerService: Ng4LoadingSpinnerService,private financeService: FinanceService,private lookupService:LookupService,public globalHelper :GlobalHelper) { }


GetFeeStructureDetails () {
  this.spinnerService.show();
    this.financeService.GetFeeStructureDetails().subscribe( (response) =>{
        this.ComponentList = response.ComponentList;
        this.ClassList = response.ClassList;
        this.AmountClassWise = response.AmountClassWise;       
        this.spinnerService.hide();
    });
};
// sdfsf

SaveFeeStructure () {
  this.spinnerService.show();
    this.financeService.SaveFeeStructure(JSON.stringify(this.ComponentList)).subscribe( (response) =>{
        this.globalHelper.CheckActionStatus(response);
      
        if (response.webResponse.Status) {
            this.GetFeeStructureDetails();
            this.spinnerService.hide();
        }
    });

};



  ngOnInit() {
    this.GetFeeStructureDetails();
  }

}
