import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.css']
})

export class CityComponent implements OnInit {


  Submitted: boolean = false;
  CountryList: any;
  StateList: any;
  CityList: any;

  constructor(private MasterService: MasterService, private LookupService: LookupService,private globalHelper:GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  CityModel = {
    CityID: 0,
    CountryID: 0,
    StateID: 0,
    CityName: '',
    StateName: '',
    IsActive: true
  };

  Clear() {
    this.CityModel.CityID = 0;
    this.CityModel.CountryID = 0;
    this.CityModel.StateID = 0;
    this.CityModel.CityName = '';
    this.CityModel.IsActive = true;
    this.Submitted = false;
  };

  GetAllCity = function () {
    this.spinnerService.show();
    this.MasterService.GetAllCity().subscribe((response: any) => {
      this.CityList = response.CityList;    
      this.spinnerService.hide(); 
    });
  };

  SaveCity(valid) {
    this.Submitted = true;
    if (!this.ValidateDropdown()) {
      this.spinnerService.show();
      this.MasterService.SaveCity(JSON.stringify(this.CityModel)).subscribe((data: any) => {
      
        if (data.webResponse.Status) {        
          this.GetAllCity();
        }
        if (data.webResponse.Status && this.CityModel.CityID==0) {
          this.Clear();
          this.GetAllCity();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
  };
  ValidateDropdown() {
    return this.CityModel.StateID == 0 || this.CityModel.CountryID == 0
  };


  GetStatus(IsActive) {
    return IsActive ? 'Yes' : 'No';
  };

  Edit(obj) {

    this.CityModel.CityID = obj.CityID;
    this.CityModel.CountryID = obj.CountryID.toString().trim();
    this.GetBindBranchState(this.CityModel.CountryID);
    this.CityModel.StateID = obj.StateID;
    this.CityModel.CityName = obj.CityName;
    this.CityModel.IsActive = obj.IsActive;


  };


  // Init and Lookup

  ngOnInit() {
    this.GetBindCountry();
    this.GetAllCity();
  }

  GetBindCountry() {

    this.LookupService.GetLookupsList('Country', '').subscribe((data: any) => {
      this.CountryList = data.LookupItems;
     
    });

  }

  GetBindBranchState(CountryID: any) {
    let Itembo = {
      'ItemId': CountryID
    }
    this.LookupService.GetLookupsList('State', JSON.stringify(Itembo)).subscribe((data: any) => {
      this.StateList = data.LookupItems;
    });

  }

}
