import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.css']
})
export class SubjectComponent implements OnInit {

 
  Submitted: boolean = false;
  SubjectTypeList: any;
  SubjectCategoryList: any;
  SubjectList: any;

  constructor(private MasterService: MasterService, private LookupService: LookupService, private globalHelper: GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  SubjectSearchModel = {
   
            CourseCode: '',
            CourseName: '',
           
  };

  SearchAllSubject = function () {
    this.spinnerService.show();
    this.MasterService.SearchAllSubject(this.SubjectSearchModel.CourseCode,this.SubjectSearchModel.CourseName).subscribe((response: any) => {
      this.SubjectList = response.SubjectList;
      this.spinnerService.hide();
    });
  };
  SubjectModel = {
    CourseID: 0,
            CourseCode: '',
            CourseName: '',
            SubjectTypeID: 0,
            SubjectCategoryID: 0,
            IsThirdLanguage:false,
            IsSecondLanguage:false,
            IsActive: true
  };

  Clear() {
    this.SubjectModel.CourseID = 0;
    this.SubjectModel.SubjectTypeID = 0;
    this.SubjectModel.SubjectCategoryID = 0;
    this.SubjectModel.CourseCode = '';
    this.SubjectModel.CourseName = '';
    this.SubjectModel.IsThirdLanguage = false;
    this.SubjectModel.IsSecondLanguage = false;
    this.SubjectModel.IsActive = true;
    this.Submitted = false;
  };

 


  SaveSubject(valid) {
    this.Submitted = true;
    if (valid) {
      this.spinnerService.show();
      this.MasterService.SaveSubject(JSON.stringify(this.SubjectModel)).subscribe((data: any) => {
        if (data.webResponse.Status) {
          this.SearchAllSubject();
        }
        if (data.webResponse.Status && this.SubjectModel.CourseID == 0) {
          this.Clear();
          this.SearchAllSubject();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
  };

  SubjectMarkActive(item) {
    this.SubjectModel.CourseID = item.CourseID;
    this.SubjectModel.IsActive = item.IsActive == true ? false : true;
    this.spinnerService.show();
    this.MasterService.SubjectMarkActive(JSON.stringify(this.SubjectModel)).subscribe((data: any) => {
      if (data.webResponse.Status) {
        this.Clear();
        this.SearchAllSubject();
      }
      this.globalHelper.CheckActionStatus(data);
      this.spinnerService.hide();
    });

  };

  GetStatus(IsActive) {
    return IsActive ? 'Yes' : 'No';
  };


  Edit(obj) {

    this.SubjectModel.CourseID = obj.CourseID;
    this.SubjectModel.SubjectTypeID = obj.SubjectTypeID;
    this.SubjectModel.SubjectCategoryID = obj.SubjectCategoryID;
    this.SubjectModel.CourseCode = obj.CourseCode;
    this.SubjectModel.CourseName = obj.CourseName;
    this.SubjectModel.IsThirdLanguage = obj.IsThirdLanguage;
    this.SubjectModel.IsSecondLanguage = obj.IsSecondLanguage;
    this.SubjectModel.IsActive = obj.IsActive;


  };


  // Init and Lookup

  ngOnInit() {
    this.GetSubjectType();
    this.GetSubjectCategory();
    this.SearchAllSubject();
  }

  GetSubjectType() {

    this.LookupService.GetLookupsList('SubjectType', '').subscribe((data: any) => {
      this.SubjectTypeList = data.LookupItems;
    });

  }
  GetSubjectCategory() {

    this.LookupService.GetLookupsList('SubjectCategory', '').subscribe((data: any) => {
      this.SubjectCategoryList = data.LookupItems;
    });

  }


}

