import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-bootstrap';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { GroupDashboardComponent } from './group-dashboard/group-dashboard.component';
import { StudentDashboardComponent } from './student-dashboard/student-dashboard.component';

@NgModule({
  declarations: [AdminComponent, DashboardComponent,GroupDashboardComponent, MainDashboardComponent, StudentDashboardComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    NgbModule
    ,CarouselModule.forRoot(),
   
  ],
  exports:[],
})
export class AdminModule { }
