import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-house',
  templateUrl: './house.component.html',
  styleUrls: ['./house.component.css']
})
export class HouseComponent implements OnInit {

  Submitted: boolean = false; 
  HouseList: any;
  HouseWardenList:any;
  
  constructor(private MasterService: MasterService, private LookupService: LookupService, private globalHelper: GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  
  
  HouseModel = {
    HouseID: 0,
            HouseName: '',
            HouseCharter: '',
            HouseWardenID: 0,
            IsActive: true
  };

  Clear() {
    this.HouseModel.HouseID = 0;
    this.HouseModel.HouseName = '';
    this.HouseModel.HouseCharter = '';
    this.HouseModel.HouseWardenID = 0;
    this.HouseModel.IsActive = true;
    this.Submitted = false;
  };

  GetAllHouse = function () {
    this.spinnerService.show();
    this.MasterService.GetAllHouse().subscribe((response: any) => {
      this.HouseList = response.HouseList;
      this.spinnerService.hide();
    });
  };

  
  SaveHouse(valid) {
    this.Submitted = true;
    if (valid) {
      this.spinnerService.show();
      this.MasterService.SaveHouse(JSON.stringify(this.HouseModel)).subscribe((data: any) => {
       
        if (data.webResponse.Status && this.HouseModel.HouseID == 0) {
          this.Clear();
          this.GetAllHouse();        
        }
        if (data.webResponse.Status) {
          this.GetAllHouse();         
        }
      
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
    
  };

  HouseMarkActive(item) {
    this.HouseModel.HouseID = item.HouseID;
    this.HouseModel.IsActive = item.IsActive == true ? false : true;
    this.spinnerService.show();
    this.MasterService.HouseMarkActive(JSON.stringify(this.HouseModel)).subscribe((data: any) => {
      if (data.webResponse.Status) {
        this.Clear();
        this.GetAllHouse();
      }
      this.globalHelper.CheckActionStatus(data);
      this.spinnerService.hide();
    });

  };

  
  Edit(obj) {

    this.HouseModel.HouseID = obj.HouseID;
    this.HouseModel.HouseName = obj.HouseName;
    this.HouseModel.HouseCharter = obj.HouseCharter;
    this.HouseModel.HouseWardenID = obj.HouseWardenID;
    this.HouseModel.IsActive = obj.IsActive;

  };


  // Init and Lookup

  ngOnInit() {
    this.GetAllWarden();
    this.GetAllHouse();
  }

  GetAllWarden() {

    this.LookupService.GetLookupsList('StaffName', '').subscribe((data: any) => {
      this.HouseWardenList = data.LookupItems;
    });
  }

}
