import { Component, OnInit } from '@angular/core';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-fee-component',
  templateUrl: './fee-component.component.html',
  styleUrls: ['./fee-component.component.css']
})
export class FeeComponentComponent implements OnInit {
  FeeComponentModel = {
    FeeComponentID: 0,
    ComponentName:'',
    AccID: 0,
    ComponentTypeID: 0,
    DisplayOrder: 0,
    IsActive: true,
    Applicable: '0',
    
}
FormModel: FormGroup;
AccountList:any;
ComponentTypeList:any;
Submitted:boolean=false;
ComponentList:any;
  constructor(private financeService: FinanceService,private lookupService:LookupService,
    private globalHelper :GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) {

   

   }


  
  
//Reset All Fields

Clear() {
    this.FeeComponentModel.FeeComponentID = 0;
    this.FeeComponentModel.ComponentName = '';
    this.FeeComponentModel.AccID = 0;
    this.FeeComponentModel.ComponentTypeID = 0;
    this.FeeComponentModel.DisplayOrder = 0;
    this.FeeComponentModel.IsActive = true;
    this.FeeComponentModel.Applicable = '0';
    this.Submitted = false;
    
};

// Drop-Down binding

GetAccount() {
    var itembo = {
        'ItemId': 1
    }
    this.lookupService.GetLookupsList('FeeAcc', JSON.stringify(itembo)).subscribe((data:any) =>{
        this.AccountList = data.LookupItems;
    });
}


GetComponent() {
    this.lookupService.GetLookupsList('ComponentTypeType', '').subscribe((data:any) =>{
        this.ComponentTypeList = data.LookupItems;
        
    });
};

// Validation

 VaildateFields()
{
    return this.FeeComponentModel.ComponentTypeID == 0 || this.FeeComponentModel.Applicable == '0'||
    this.FeeComponentModel.ComponentName == ''||this.FeeComponentModel.DisplayOrder == 0;
}


   SaveFeeComponent () {
    
    this.Submitted = true;
    if (!this.VaildateFields()) {
       
       this.financeService.SaveFeeComponent(JSON.stringify(this.FeeComponentModel)).subscribe( (response)=> {
            
            this.globalHelper.CheckActionStatus(response);
            if (response.webResponse.Status) {
                this.Clear();
                this.SearchFeeComponent();
            }
        });
    }
};
FeeComponentMarkActive (obj) {
    this.FeeComponentModel.FeeComponentID = obj.FeeComponentID;
    this.FeeComponentModel.ComponentName = obj.ComponentName;
    this.FeeComponentModel.AccID = obj.AccID;
    this.FeeComponentModel.ComponentTypeID = obj.ComponentTypeID;
    this.FeeComponentModel.DisplayOrder = obj.DisplayOrder;
    this.FeeComponentModel.IsActive = obj.IsActive == true ? false : true;
    this.FeeComponentModel.Applicable = obj.Applicable;
    this.financeService.SaveFeeComponent(JSON.stringify(this.FeeComponentModel)).subscribe( (response)=> {
       
       this.globalHelper.CheckActionStatus(response);
        if (response.webResponse.Status) {
            this.Clear();
            this.SearchFeeComponent();
        }
    });
}


SearchFeeComponent() {
    this.spinnerService.show();
    this.financeService.GetFeeComponent(JSON.stringify(this.FeeComponentModel)).subscribe( (response)=> {
        this.ComponentList = response.ComponentList;
        this.spinnerService.hide();
  
    });
};

Edit = function (obj) {
    
    this.FeeComponentModel.FeeComponentID = obj.FeeComponentID;
    this.FeeComponentModel.ComponentName = obj.ComponentName;
    this.FeeComponentModel.AccID = obj.AccID;
    this.FeeComponentModel.ComponentTypeID = obj.ComponentTypeID;
    this.FeeComponentModel.DisplayOrder = obj.DisplayOrder;
    this.FeeComponentModel.IsActive = obj.IsActive;
    this.FeeComponentModel.Applicable = obj.Applicable;
};

ngOnInit(){
    this.SearchFeeComponent();
    this.GetAccount();
    this.GetComponent();
};
 
}
