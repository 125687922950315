import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


@Component({
  selector: 'app-class',
  templateUrl: './class.component.html',
  styleUrls: ['./class.component.css']
})

export class ClassComponent implements OnInit {

 
  Submitted: boolean = false; 
  ClassGroupList: any;
  ClassList: any;

  constructor(private MasterService: MasterService, private LookupService: LookupService, private globalHelper: GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  
  
  ClassModel = {
    ClassID: 0,
    ClassGroupID: 0,
    ClassName: '',
    DisplayOrder: 0,
    ClassDesc: '',
    ClassCode: '',
    IsActive: true
  };

  Clear() {
    this.ClassModel.ClassID = 0;
    this.ClassModel.ClassGroupID = 0;
    this.ClassModel.DisplayOrder = 0;
    this.ClassModel.ClassName = '';
    this.ClassModel.ClassDesc = '';
    this.ClassModel.ClassCode = '';
    this.ClassModel.IsActive = true;
    this.Submitted = false;
  };

  SearchAllClass = function () {
    this.spinnerService.show();
    this.MasterService.SearchAllClass().subscribe((response: any) => {
      this.ClassList = response.ClassList;
      this.spinnerService.hide();
    });
  };

  ValidDropDown(){
    return this.ClassModel.ClassGroupID==0;
  }
  SaveClass(valid) {
    this.Submitted = true;
    if (valid && !this.ValidDropDown()) {
      this.spinnerService.show();
      this.MasterService.SaveClass(JSON.stringify(this.ClassModel)).subscribe((data: any) => {
        if (data.webResponse.Status) {
          this.SearchAllClass();
        }
        if (data.webResponse.Status && this.ClassModel.ClassID == 0) {
          this.Clear();
          this.SearchAllClass();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
    
  };

  ClassMarkActive(item) {
    this.ClassModel.ClassID = item.ClassID;
    this.ClassModel.IsActive = item.IsActive == true ? false : true;
    this.MasterService.ClassMarkActive(JSON.stringify(this.ClassModel)).subscribe((data: any) => {
      if (data.webResponse.Status) {
        this.Clear();
        this.SearchAllClass();
      }
      this.globalHelper.CheckActionStatus(data);
    });

  };

  ValidateDropdown() {
    return this.ClassModel.ClassGroupID == 0;
  };


  GetStatus(IsActive) {
    return IsActive ? 'Yes' : 'No';
  };

  Edit(obj) {

    this.ClassModel.ClassID = obj.ClassID;
    this.ClassModel.ClassGroupID = obj.ClassGroupID;
    this.ClassModel.DisplayOrder = obj.DisplayOrder;
    this.ClassModel.ClassName = obj.ClassName;
    this.ClassModel.ClassDesc = obj.ClassDesc;
    this.ClassModel.ClassCode = obj.ClassCode;
    this.ClassModel.IsActive = obj.IsActive;


  };


  // Init and Lookup

  ngOnInit() {
   
    this.GetClassGroup();
    this.SearchAllClass();
  }

  GetClassGroup() {

    this.LookupService.GetLookupsList('ClassGroup', '').subscribe((data: any) => {
      this.ClassGroupList = data.LookupItems;
    });

  }



}
