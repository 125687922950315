import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-qualification',
  templateUrl: './qualification.component.html',
  styleUrls: ['./qualification.component.css']
})
export class QualificationComponent implements OnInit {

  Submitted: boolean = false;
  QualificationList: any;
  
  constructor(private MasterService: MasterService, private globalHelper: GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  QualificationModel = {
   
    QualificationId: 0,
    QualificationName: '',
    IsActive: true
           
  };

  QualificationSearchModel = {
    QualificationName: '',
              
  };

  GetAllQualification = function () {
    this.spinnerService.show();
    this.MasterService.GetAllQualification(JSON.stringify(this.QualificationSearchModel)).subscribe((response: any) => {
      this.QualificationList = response.QualificationList;
      this.spinnerService.hide();
    });
  };
  

  Clear() {
    this.QualificationModel.QualificationId = 0;
    this.QualificationModel.QualificationName = '';   
    this.QualificationModel.IsActive = true;
    this.Submitted = false;
  };

 


  SaveQualification(valid) {
    this.Submitted = true;
    if (valid) {
      this.spinnerService.show();
      this.MasterService.SaveQualification(JSON.stringify(this.QualificationModel)).subscribe((data: any) => {
        if (data.webResponse.Status) {
          this.GetAllQualification();
        }
        if (data.webResponse.Status && this.QualificationModel.QualificationId == 0) {
          this.Clear();
          this.GetAllQualification();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
  };

  QualificationMarkActive(item) {
    this.QualificationModel.QualificationId = item.QualificationId;
    this.QualificationModel.QualificationName = item.QualificationName;
    this.QualificationModel.IsActive = item.IsActive == true ? false : true;
    this.spinnerService.show();
    this.MasterService.QualificationMarkActive(JSON.stringify(this.QualificationModel)).subscribe((data: any) => {
      if (data.webResponse.Status) {
        this.Clear();
        this.GetAllQualification();
      }
      this.globalHelper.CheckActionStatus(data);
      this.spinnerService.hide();
    });

  };

 

  Edit(obj) {

    this.QualificationModel.QualificationId = obj.QualificationId;
    this.QualificationModel.QualificationName = obj.QualificationName;   
    this.QualificationModel.IsActive = obj.IsActive;


  };


  // Init and Lookup

  ngOnInit() {
    this.GetAllQualification();  
   
  } 

}