import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { HttpClient } from '@angular/common/http';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


@Component({
  selector: 'app-branchprofile',
  templateUrl: './branchprofile.component.html',
  styleUrls: ['./branchprofile.component.css']
})
export class BranchProfileComponent implements OnInit {

  BranchModel: any;
  CountryList: any;
  StateList: any;
  CityList: any;
  BankList: any;
  selectedLogo: any;
  selectedPrintLogo: any;
  selectedBoardLogo: any;
  selectedPrincipalLogo: any;
  ApiUrl:string;

  constructor(private MasterService: MasterService, private LookupService: LookupService, private httpClient: HttpClient, public globalHelper: GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) {

   this.ApiUrl=globalHelper.ApiUrl;

   }



  GetBranchDetails() {
    this.spinnerService.show();
    this.MasterService.GetBranchDetails().subscribe((data: any) => {
      this.BranchModel = data.BranchList[0];
      this.GetBindBranchState(this.BranchModel.CountryID);
      this.GetBindBranchCity(this.BranchModel.StateID);
      this.spinnerService.hide();
    });

  }


  Update() {
   this.spinnerService.show();
    this.MasterService.UpdateBranchProfile(JSON.stringify(this.BranchModel), this.selectedLogo, this.selectedPrintLogo, this.selectedBoardLogo, this.selectedPrincipalLogo).subscribe((data: any) => {
     this.globalHelper.CheckActionStatus(data);
      this.GetBranchDetails();
      this.spinnerService.hide();
    });

  }

  // Upload Image

  onUploadLogo(event: any) {
    this.selectedLogo = event.target.files[0];
  }


  onUploadPrintLogo(event: any) {
    this.selectedPrintLogo = event.target.files[0];
  }


  onUploadBoardLogo(event: any) {
    this.selectedBoardLogo = event.target.files[0];
  }


  onUploadPrincipalLogo(event: any) {
    this.selectedPrincipalLogo = event.target.files[0];
  }

  // Init and Lookup

  ngOnInit() {
    this.GetBindCountry();
    this.GetBranchDetails();
  }

  GetBindCountry() {

    this.LookupService.GetLookupsList('Country', '').subscribe((data: any) => {
      this.CountryList = data.LookupItems;
    });

  }

  GetBindBranchState(CountryID: any) {
    let Itembo = {
      'ItemId': CountryID
    }
    this.LookupService.GetLookupsList('State', JSON.stringify(Itembo)).subscribe((data: any) => {
      this.StateList = data.LookupItems;
    });

  }

  GetBindBranchCity(StateID: any) {
    let Itembo = {
      'ItemId': StateID
    }
    this.LookupService.GetLookupsList('City', JSON.stringify(Itembo)).subscribe((data: any) => {
      this.CityList = data.LookupItems;
    });

  }


}
