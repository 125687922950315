import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from '../admin/admin.component';
import { BranchProfileComponent } from './branchprofile/branchprofile.component';
import { CityComponent } from './city/city.component';
import { ClassComponent } from './class/class.component';
import { ClassGroupComponent } from './classgroup/classgroup.component';
import { SectionComponent } from './section/section.component';
import { ClassSectionMapComponent } from './classsectionmap/classsectionmap.component';
import { AdmissionDocumentComponent } from './admissiondocument/admissiondocument.component';
import { AdmissionOpenComponent } from './admissionopen/admissionopen.component';
import { SubjectComponent } from './subject/subject.component';
import { IdCardTypeComponent } from './idcardtype/idcardtype.component';
import { DepartmentComponent } from './department/department.component';
import { DesignationComponent } from './designation/designation.component';
import { StudentLocationComponent } from './studentlocation/studentlocation.component';
import { HouseComponent } from './house/house.component';
import { TestVenueComponent } from './testvenue/testvenue.component';
import { RoomComponent } from './room/room.component';
import { QualificationComponent } from './qualification/qualification.component';
import { CountryComponent } from './country/country.component';
import { StateComponent } from './state/state.component';
import { SpecialCategoryComponent } from './specialcategory/specialcategory.component';
import { SchoolLocationComponent } from './schoollocation/schoollocation.component';
const routes: Routes = [
  { 
    path: '', 
    component: AdminComponent,
    children: [
      { path: 'branchprofile', component:BranchProfileComponent, pathMatch: 'full'},
      { path: 'admissionopen', component:AdmissionOpenComponent, pathMatch: 'full'},
      { path: 'classgroup', component:ClassGroupComponent, pathMatch: 'full'},
      { path: 'class', component:ClassComponent, pathMatch: 'full'},
      { path: 'section', component:SectionComponent, pathMatch: 'full'},
      { path: 'classsectionmap', component:ClassSectionMapComponent, pathMatch: 'full'},
      { path: 'subject', component:SubjectComponent, pathMatch: 'full'},
      { path: 'admissiondocument', component:AdmissionDocumentComponent, pathMatch: 'full'},
      { path: 'idcardtype', component:IdCardTypeComponent, pathMatch: 'full'},
      { path: 'department', component:DepartmentComponent, pathMatch: 'full'},
      { path: 'designation', component:DesignationComponent, pathMatch: 'full'},
      { path: 'studentlocation', component:StudentLocationComponent, pathMatch: 'full'},
      { path: 'house', component:HouseComponent, pathMatch: 'full'},
      { path: 'testvenue', component:TestVenueComponent, pathMatch: 'full'},
      { path: 'room', component:RoomComponent, pathMatch: 'full'},
      { path: 'qualification', component:QualificationComponent, pathMatch: 'full'},
      { path: 'country', component:CountryComponent, pathMatch: 'full'},
      { path: 'state', component:StateComponent, pathMatch: 'full'},
      { path: 'city', component:CityComponent, pathMatch: 'full'},
      { path: 'specialcategory', component:SpecialCategoryComponent, pathMatch: 'full'},
      { path: 'schoollocation', component:SchoolLocationComponent, pathMatch: 'full'},
     
     
    ]
}
  
 ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MasterRoutingModule { }
