import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as moment from 'moment';

@Component({
  selector: 'app-fee-component-summary-report',
  templateUrl: './fee-component-summary-report.component.html',
  styleUrls: ['./fee-component-summary-report.component.css']
})
export class FeeComponentSummaryReportComponent implements OnInit {

  Submitted: boolean = false;
  FeeComponentSummaryReportList: any;
  TotalSum: number = 0;
  FilePath: any;

  constructor(private financeService: FinanceService, private LookupService: LookupService, public globalHelper: GlobalHelper,
    private spinnerService: Ng4LoadingSpinnerService) { }


  SearchModel = {
    FromDate: moment(new Date()).format('DD-MMM-YYYY'),
    ToDate: moment(new Date()).format('DD-MMM-YYYY'),
    Print: '',
  };


  ClearSearch(){
    this.SearchModel.FromDate=moment(new Date()).format('DD-MMM-YYYY');
    this.SearchModel.ToDate=moment(new Date()).format('DD-MMM-YYYY');
    this.SearchModel.Print='';
  }

  ValidateDropdownSearch() {
    return this.SearchModel.FromDate == '' || this.SearchModel.ToDate == '';
  };

  GetFeeComponentSummaryReport() {
    this.Submitted = true;
    this.FeeComponentSummaryReportList=[];
    if (!this.ValidateDropdownSearch()) {
      this.SearchModel.FromDate=moment(this.SearchModel.FromDate).format('DD-MMM-YYYY');
      this.SearchModel.ToDate=moment(this.SearchModel.ToDate).format('DD-MMM-YYYY');
      this.spinnerService.show();
      this.financeService.GetFeeComponentSummaryReport(JSON.stringify(this.SearchModel)).subscribe((data: any) => {
        this.Submitted = false;
        this.spinnerService.hide();
        this.FeeComponentSummaryReportList = data.FeeComponentSummaryReportList;
        if (data.webResponse.Status) {         
          this.TotalSum = 0;
          for (var i = 0; i < this.FeeComponentSummaryReportList.length; i++) {
            this.TotalSum = this.TotalSum + this.FeeComponentSummaryReportList[i].Amount;
          }
        }
      });
    }

  };


  GetFeeComponentSummaryReportDownload() {
    this.Submitted = true;
    if (!this.ValidateDropdownSearch()) {
      this.SearchModel.Print = 'P';
      this.spinnerService.show();
      this.SearchModel.FromDate=moment(this.SearchModel.FromDate).format('DD-MMM-YYYY');
      this.SearchModel.ToDate=moment(this.SearchModel.ToDate).format('DD-MMM-YYYY');
      this.financeService.GetFeeComponentSummaryReport(JSON.stringify(this.SearchModel)).subscribe((data: any) => {
        this.Submitted = false;
        this.spinnerService.hide();
        if (data.webResponse.Status) {
          this.FilePath = data.FilePath;
          window.location = data.FilePath;
        }
      });

    }

  };

  ngOnInit() {

  }


}

