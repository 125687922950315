import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeeRoutingModule } from './fee-routing.module';
import { FeeComponentComponent } from './fee-component/fee-component.component';
import { AdminModule } from '../admin/admin.module';
import { FormsModule } from '@angular/forms';
import { FeeCollectionReportsComponent } from './fee-collection-reports/fee-collection-reports.component';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FeeInstallmentSetupComponent } from './fee-installment-setup/fee-installment-setup.component';
import { FeeStructureComponentComponent } from './fee-structure-component/fee-structure-component.component';
import { FeeStructureInstallmentAmountComponent } from './fee-structure-installment-amount/fee-structure-installment-amount.component';
import { FeePaymentComponent } from './fee-payment/fee-payment.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FeeReceiptPrintComponent } from './fee-receipt-print/fee-receipt-print.component';
import { ReceiptFormat1Component } from './feereceipt/receipt-format1/receipt-format1.component';
import { ReceiptFormat2Component } from './feereceipt/receipt-format2/receipt-format2.component';
import { FeeReceiptComponent } from './fee-receipt/fee-receipt.component';
import { FeeReceiptCancelComponent } from './fee-receipt-cancel/fee-receipt-cancel.component';
import { FeeCollectionSummaryComponent } from './fee-collection-summary/fee-collection-summary.component';
import { FeeComponentSummaryReportComponent } from './fee-component-summary-report/fee-component-summary-report.component';
import { FeeConsolidatedReportComponent } from './fee-consolidated-report/fee-consolidated-report.component';
import { FeeAdjustmentReportComponent } from './fee-adjustment-report/fee-adjustment-report.component';
import { FeeDefaulterReportsComponent } from './fee-defaulter-reports/fee-defaulter-reports.component';
import { StudentOpeningBalanceComponent } from './student-opening-balance/student-opening-balance.component';
import { FeeDiscountSetupComponent } from './fee-discount-setup/fee-discount-setup.component';
import { PaymentModeMapingComponent } from './payment-mode-maping/payment-mode-maping.component';
import { OnlineTransactionComponent } from './online-transaction/online-transaction.component';
import { FeeChallanComponent } from './fee-challan/fee-challan.component';
import { FeeChallanPrintComponent } from './fee-challan-print/fee-challan-print.component';
import { FeeBookPrintComponent } from './fee-book-print/fee-book-print.component';
import { MonthlyCollectionComponent } from './monthly-collection/monthly-collection.component';
import { StudentReimbursementComponent } from './student-reimbursement/student-reimbursement.component';
import { ExcessReimbursementReportComponent } from './excess-reimbursement-report/excess-reimbursement-report.component';


@NgModule({
  declarations: [FeeComponentComponent, FeeCollectionReportsComponent, FeeInstallmentSetupComponent, FeeStructureComponentComponent, 
    FeeStructureInstallmentAmountComponent, FeePaymentComponent, FeeReceiptPrintComponent, 
    ReceiptFormat1Component, ReceiptFormat2Component, FeeReceiptComponent, 
    FeeReceiptCancelComponent, FeeCollectionSummaryComponent, FeeComponentSummaryReportComponent, 
    FeeConsolidatedReportComponent, FeeAdjustmentReportComponent, FeeDefaulterReportsComponent, 
    StudentOpeningBalanceComponent, FeeDiscountSetupComponent, PaymentModeMapingComponent,
    OnlineTransactionComponent, FeeChallanComponent, FeeChallanPrintComponent,
     FeeBookPrintComponent, MonthlyCollectionComponent, StudentReimbursementComponent, 
     ExcessReimbursementReportComponent],
     
  imports: [
    CommonModule,
    FeeRoutingModule,
    AdminModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    CollapseModule.forRoot(),
    TabsModule.forRoot()
   
  ]
})
export class FeeModule { }
