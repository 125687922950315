import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.css']
})
export class StateComponent implements OnInit {

  Submitted: boolean = false;
  CountryList: any;
  StateList: any;
 

  constructor(private MasterService: MasterService, private LookupService: LookupService,private globalHelper:GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  StateModel = {
    CountryID: 0,
    StateID: 0,
    StateName: '',
    IsActive: true,
  };

  Clear() {
    this.StateModel.StateID = 0;
    this.StateModel.CountryID = 0;
    this.StateModel.StateName = '';
    this.StateModel.IsActive = true;
    this.Submitted = false;
  };

  StateBind = function () {
    this.spinnerService.show();
    this.MasterService.StateBind().subscribe((response: any) => {
      this.StateList = response.StateList;  
      this.spinnerService.hide();   
    });
  };

  ValidateDropdown() {
    return  this.StateModel.CountryID == 0
  };

  SaveState(valid) {
    this.Submitted = true;
    if (valid && !this.ValidateDropdown()) {
      this.spinnerService.show();
      this.MasterService.SaveState(JSON.stringify(this.StateModel)).subscribe((data: any) => {
        if (data.webResponse.Status) {        
          this.StateBind();
        }
        if (data.webResponse.Status && this.StateModel.StateID==0) {
          this.Clear();
          this.StateBind();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
  };
 

  GetStatus(IsActive) {
    return IsActive ? 'Yes' : 'No';
  };

  Edit(obj) {

    this.StateModel.StateID = obj.StateID;
    this.StateModel.CountryID = obj.CountryID;
    this.StateModel.StateName = obj.StateName;
    this.StateModel.IsActive = obj.IsActive;


  };


  // Init and Lookup

  ngOnInit() {
    this.GetBindCountry();
    this.StateBind();
  }

  GetBindCountry() {

    this.LookupService.GetLookupsList('Country', '').subscribe((data: any) => {
      this.CountryList = data.LookupItems;
     
    });

  }

 

}
