import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.css']
})
export class RoomComponent implements OnInit {

  Submitted: boolean = false;
  RoomList: any;
  
  constructor(private MasterService: MasterService, private globalHelper: GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  RoomModel = {
   
    RoomID: 0,
    RoomName: '',
    Description:'',
    IsActive: true
           
  };

  GetAllRoom = function () {
    this.spinnerService.show();
    this.MasterService.GetAllRoom().subscribe((response: any) => {
      this.RoomList = response.RoomList;
      this.spinnerService.hide();
    });
  };
  

  Clear() {
    this.RoomModel.RoomID = 0;
    this.RoomModel.RoomName = '';  
    this.RoomModel.Description=''; 
    this.RoomModel.IsActive = true;
    this.Submitted = false;
  };

 


  SaveRoom(valid) {
    this.Submitted = true;
    if (valid) {
      this.spinnerService.show();
      this.MasterService.SaveRoom(JSON.stringify(this.RoomModel)).subscribe((data: any) => {
        if (data.webResponse.Status) {
          this.GetAllRoom();
        }
        if (data.webResponse.Status && this.RoomModel.RoomID == 0) {
          this.Clear();
          this.GetAllRoom();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
  };

  RoomMarkActive(item) {
    this.RoomModel.RoomID = item.RoomID;
    this.RoomModel.IsActive = item.IsActive == true ? false : true;
    this.spinnerService.show();
    this.MasterService.RoomMarkActive(JSON.stringify(this.RoomModel)).subscribe((data: any) => {
      if (data.webResponse.Status) {
        this.Clear();
        this.GetAllRoom();
      }
      this.globalHelper.CheckActionStatus(data);
      this.spinnerService.hide();
    });

  };

 

  Edit(obj) {

    this.RoomModel.RoomID = obj.RoomID;
    this.RoomModel.RoomName = obj.RoomName;   
    this.RoomModel.Description=obj.Description;
    this.RoomModel.IsActive = obj.IsActive;


  };


  // Init and Lookup

  ngOnInit() {
    this.GetAllRoom();
  }

 

}