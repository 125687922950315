import { Component, OnInit } from '@angular/core';

import { GlobalHelper } from 'src/app/services/Common/global.service';
import { LayoutService } from 'src/app/services/layout/layout.service';
import * as moment  from 'moment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {interval, Observable} from 'rxjs';
import {map, share} from 'rxjs/operators';
import { LookupService } from 'src/app/services/Common/lookup.service';

@Component({
  selector: 'app-student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.css']
})
export class StudentDashboardComponent implements OnInit {
  LoginToken:any;  
  PG:string; 
 clock:any;
constructor(private spinnerService: Ng4LoadingSpinnerService,public globalHelper:GlobalHelper,private lookupservice: LookupService,private layoutService:LayoutService) { 
  setInterval(() =>{
    // const currentDate = new Date();
    this.clock = new Date();
     }, 1000);
   }
    
   myInterval = 1500;
   activeSlideIndex = 0;
  
   slides = [
     {image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/1.jpg',text:"Name"},
     {image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/2.jpg',text:"Name1"},
     {image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/3.jpg',text:"Name3"}
    ];


      // tickInterval = 1000 //ms
      // clock:any;
      //  tick  () {
      //     this.clock = Date.now() // get the current time
      //     setTimeout(this.tick, this.tickInterval); // reset the timer
      // }

      

      CircluarDetails:any;
      ViewCircular  (item) {
          this.CircluarDetails = item;
          setTimeout( () =>{
              this.PrintCircular();
          }, 300);
      }

      PrintCircular  () {
          var divPreRecipt = document.getElementById("divCircular").innerHTML;
          divPreRecipt = divPreRecipt.replace('no record found', '');
          var printWindow = window.open('', '', 'height=400,width=800');
          printWindow.document.write('<html><head><title>' + this.CircluarDetails.Title + ' </title>');
          printWindow.document.write('<style type="text/css">@page {size:landscape}');
          printWindow.document.write('.t1{ border-top: 1px solid black;  border-left: 1px solid black;white-space:nowrap;font-family:Calibri;font-size:10px} .t2 { border-right: 1px solid black; border-bottom: 1px solid black;}.t3 { border-right: 1px solid black;border-bottom: 1px solid black; }');
          printWindow.document.write('</style>');
          printWindow.document.write('</head><body>');
          printWindow.document.write(divPreRecipt);
          printWindow.document.write('</body></html>');
          printWindow.document.close();
      }


    


       SearchModel = {
          ToDate: moment(new Date()).format('DD-MMM-YYYY'),
      }
      DashboardDetails:any;
      DashboardAccess:any;
      StaffBirthDayList:any;
      StudentBirthDayList:any;
      GetDashboardDetails  () {
         this.spinnerService.show()
          this.layoutService.GetDashboardDetails(JSON.stringify(this.SearchModel)).subscribe( (data)=> {
              var DashboardView = data.DashboardView;
              this.DashboardDetails = DashboardView.DashboardDetails;
              this.DashboardAccess = DashboardView.DashboardAccess;
              this.StaffBirthDayList = DashboardView.StaffBirthDayList;
              this.StudentBirthDayList = DashboardView.StudentBirthDayList;
              this.spinnerService.hide()
          });
      }


      StaffAttendanceList:any;
      TotalAbsent = 0;
      TotalPersent = 0;
      TotalAbsentFemale = 0;
      TotalAbsentMale = 0;
      TotalPersentFemale = 0;
      TotalPersentMale = 0;
      MTotal= 0;
      FTotal = 0;
      CTotal = 0;
      GetDashboardStaffAttendance  () {
          this.layoutService.GetDashboardStaffAttendance(JSON.stringify(this.FiterModel)).subscribe( (data)=> {
              this.StaffAttendanceList = data.StaffAttendanceList;
              this.TotalAbsent = data.TotalAbsent
              this.TotalPersent = data.TotalPersent
              this.TotalAbsentFemale = data.TotalAbsentFemale
              this.TotalAbsentMale = data.TotalAbsentMale
              this.TotalPersentFemale = data.TotalPersentFemale
              this.TotalPersentMale = data.TotalPersentMale
              this.MTotal = data.MTotal
              this.FTotal = data.FTotal
              this.CTotal = data.CTotal
              // this.ChartStaffAttendance()
          });
      }

    // ChartStaffAttendance  () {
    //       this.EmployeeCategoryList = [];
    //       this.MaleList = [];
    //       this.FemaleList = [];
    //       var volume = [];
    //       $('#column-chart2').remove();
    //       $('#chart-container2').append('<canvas id="column-chart2"  height="300" ><canvas>');
    //       var ctx = $("#column-chart2");
    //       for (var i = 0; i < this.StaffAttendanceList.length; i++) {
    //           this.EmployeeCategoryList.push(this.StaffAttendanceList[i].EmployeeCategory);
    //           this.MaleList.push(this.StaffAttendanceList[i].CountMale)
    //           this.FemaleList.push(this.StaffAttendanceList[i].CountFemale)
    //           if (this.StaffAttendanceList[i].CountMale) {
    //           }

    //           if (this.StaffAttendanceList[i].CountMale == null) {
    //               volume.push(0);
    //           } else {
    //               volume.push(this.StaffAttendanceList[i].CountMale);
    //           }
    //       }

    //       var MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    //       var color = Chart.helpers.color;
    //       //var horizontalBarChartData = {
    //       //    labels: '',
    //       //    datasets: [{
    //       //        label: 'Dataset 1',
    //       //        backgroundColor: color(window.chartColors.red).alpha(0.5).rgbString(),
    //       //        borderColor: window.chartColors.red,
    //       //        borderWidth: 1,
    //       //        data: [
    //       //            111,

    //       //            123
    //       //        ]
    //       //    }, {
    //       //        label: 'Dataset 2',
    //       //        backgroundColor: color(window.chartColors.blue).alpha(0.5).rgbString(),
    //       //        borderColor: window.chartColors.blue,
    //       //        data: [
    //       //            112,

    //       //            124
    //       //        ]
    //       //    }]

    //       //};
    //       //var chartOptions = {
    //       //    elements: {
    //       //        rectangle: {
    //       //            borderWidth: 2,
    //       //            borderColor: 'rgb(0, 255, 0)',
    //       //            borderSkipped: 'bottom'
    //       //        }
    //       //    },
    //       //    responsive: true,
    //       //    maintainAspectRatio: false,
    //       //    responsiveAnimationDuration: 500,
    //       //    legend: {
    //       //        position: 'top',
    //       //    },
    //       //    scales: {
    //       //        xAxes: [{
    //       //            display: true,
    //       //            gridLines: {
    //       //                color: "#f3f3f3",
    //       //                drawTicks: false,
    //       //            },
    //       //            scaleLabel: {
    //       //                display: true,
    //       //            }
    //       //        }],
    //       //        yAxes: [{
    //       //            display: true,
    //       //            gridLines: {
    //       //                color: "#f3f3f3",
    //       //                drawTicks: false,
    //       //            },
    //       //            scaleLabel: {
    //       //                display: true,
    //       //            }
    //       //        }]
    //       //    },
    //       //    title: {
    //       //        display: false,
    //       //        text: ''
    //       //    }
    //       //};
    //       var chartData = {
    //           labels: this.EmployeeCategoryList,
    //           //datasets: [{
    //           //    label: "Day Wise Water Dispensed",
    //           //    data: volume,
    //           //    backgroundColor: "#673AB7",
    //           //    hoverBackgroundColor: "rgba(103,58,183,.9)",
    //           //    borderColor: "transparent"
    //           //}],
    //           datasets: [{
    //               label: 'Male',
    //               backgroundColor: "#778CF5",
    //               borderColor: "#778CF5",
    //               borderWidth: 1,
    //               data: this.MaleList
    //           }, {
    //               label: 'Female',
    //               backgroundColor: "#F577A1  ",
    //               borderColor: "transparent",
    //               data: this.FemaleList
    //           }]
    //       };
    //       var config = {
    //           type: 'horizontalBar',
    //           options: '',
    //           data: chartData
    //       };
    //       var lineChart = new Chart(ctx, config);
    //   }


      // Student Attendance
      StudentAttendanceList:any;
      GetDashboardStudentAttendance  () {
          this.layoutService.GetDashboardStudentAttendance(JSON.stringify(this.FiterModel)).subscribe( (data)=> {
              this.StudentAttendanceList = data.StudentAttendanceList;
              this.TotalAbsent = data.TotalAbsent
              this.TotalPersent = data.TotalPersent
              this.TotalAbsentFemale = data.TotalAbsentFemale
              this.TotalAbsentMale = data.TotalAbsentMale
              this.TotalPersentFemale = data.TotalPersentFemale
              this.TotalPersentMale = data.TotalPersentMale
              this.MTotal = data.MTotal
              this.FTotal = data.FTotal
              this.CTotal = data.CTotal
              // this.ChartStudentAttendance()
          });
      }

      // this.ChartStudentAttendance  () {
      //     this.ClassGroupList = [];
      //     this.MaleList = [];
      //     this.FemaleList = [];
      //     var volume = [];
      //     $('#column-chartStdAttendance').remove();
      //     $('#chart-containerStdAttendance').append('<canvas id="column-chartStdAttendance"  height="300" ><canvas>');
      //     var ctx = $("#column-chartStdAttendance");
      //     for (var i = 0; i < this.StudentAttendanceList.length; i++) {
      //         this.ClassGroupList.push(this.StudentAttendanceList[i].ClassGroupName);
      //         this.MaleList.push(this.StudentAttendanceList[i].CountMale)
      //         this.FemaleList.push(this.StudentAttendanceList[i].CountFemale)
      //         if (this.StudentAttendanceList[i].CountMale) {
      //         }

      //         if (this.StudentAttendanceList[i].CountMale == null) {
      //             volume.push(0);
      //         } else {
      //             volume.push(this.StudentAttendanceList[i].CountMale);
      //         }
      //     }

      //     var MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      //     var color = Chart.helpers.color;
      //     //var horizontalBarChartData = {
      //     //    labels: '',
      //     //    datasets: [{
      //     //        label: 'Dataset 1',
      //     //        backgroundColor: color(window.chartColors.red).alpha(0.5).rgbString(),
      //     //        borderColor: window.chartColors.red,
      //     //        borderWidth: 1,
      //     //        data: [
      //     //            111,

      //     //            123
      //     //        ]
      //     //    }, {
      //     //        label: 'Dataset 2',
      //     //        backgroundColor: color(window.chartColors.blue).alpha(0.5).rgbString(),
      //     //        borderColor: window.chartColors.blue,
      //     //        data: [
      //     //            112,

      //     //            124
      //     //        ]
      //     //    }]

      //     //};
      //     var chartOptions = {
      //         elements: {
      //             rectangle: {
      //                 borderWidth: 2,
      //                 borderColor: 'rgb(0, 255, 0)',
      //                 borderSkipped: 'bottom'
      //             }
      //         },
      //         responsive: true,
      //         maintainAspectRatio: false,
      //         responsiveAnimationDuration: 500,
      //         legend: {
      //             position: 'top',
      //         },
      //         scales: {
      //             xAxes: [{
      //                 display: true,
      //                 gridLines: {
      //                     color: "#f3f3f3",
      //                     drawTicks: false,
      //                 },
      //                 scaleLabel: {
      //                     display: true,
      //                 }
      //             }],
      //             yAxes: [{
      //                 display: true,
      //                 gridLines: {
      //                     color: "#f3f3f3",
      //                     drawTicks: false,
      //                 },
      //                 scaleLabel: {
      //                     display: true,
      //                 }
      //             }]
      //         },
      //         title: {
      //             display: false,
      //             text: ''
      //         }
      //     };
      //     var chartData = {
      //         labels: ['Boys', 'Girls'],
      //         //datasets: [{
      //         //    label: "Day Wise Water Dispensed",
      //         //    data: volume,
      //         //    backgroundColor: "#673AB7",
      //         //    hoverBackgroundColor: "rgba(103,58,183,.9)",
      //         //    borderColor: "transparent"
      //         //}],
      //         datasets: [{
      //             label: 'Persent',
      //             backgroundColor: "#778CF5",
      //             borderColor: "#778CF5",
      //             borderWidth: 1,
      //             data: [this.TotalPersentMale, this.TotalPersentFemale]
      //         }, {
      //             label: 'Absent',
      //             backgroundColor: "#F577A1  ",
      //             borderColor: "transparent",
      //             data: [this.TotalAbsentMale, this.TotalAbsentFemale]
      //         }]
      //     };
      //     var config = {
      //         type: 'horizontalBar',
      //         options: chartOptions,
      //         data: chartData
      //     };
      //     var lineChart = new Chart(ctx, config);
      // }

      //  Student Gender Report 

      FiterModel = {
          FromDate: moment(new Date()).format('DD-MMM-YYYY'),
      };

      GenderReportList:any;
      GetDashboardGenderReport  () {
          this.layoutService.GetDashboardGenderReport(JSON.stringify(this.FiterModel)).subscribe( (data)=> {
              this.GenderReportList = data.GenderReportList;
              this.MTotal = data.MTotal
              this.FTotal = data.FTotal
              this.CTotal = data.CTotal
              if (this.GenderReportList != null && this.GenderReportList.length > 0) {
                  // this.GenderChart();
              }
              
          });
      }


      // this.GenderChart  () {
       
      //     this.ClassGroupList = [];
      //     this.MaleList = [];
      //     this.FemaleList = [];
      //     var volume = [];
      //     $('#column-chartGender').remove();
      //     $('#chart-containerGender').append('<canvas id="column-chartGender"  height="300" ><canvas>');
      //     var ctx = $("#column-chartGender");
      //     for (var i = 0; i < this.GenderReportList.length; i++) {
      //         this.ClassGroupList.push(this.GenderReportList[i].ClassGroupName);
      //         this.MaleList.push(this.GenderReportList[i].CountMale)
      //         this.FemaleList.push(this.GenderReportList[i].CountFemale)
      //         if (this.GenderReportList[i].CountMale) {
      //         }

      //         if (this.GenderReportList[i].CountMale == null) {
      //             volume.push(0);
      //         } else {
      //             volume.push(this.GenderReportList[i].CountMale);
      //         }
      //     }
        
      //     var MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      //     var color = Chart.helpers.color;
 
      //     var chartOptions = {
      //         elements: {
      //             rectangle: {
      //                 borderWidth: 2,
      //                 borderColor: 'rgb(0, 255, 0)',
      //                 borderSkipped: 'bottom'
      //             }
      //         },
      //         responsive: true,
      //         maintainAspectRatio: false,
      //         responsiveAnimationDuration: 500,
      //         legend: {
      //             position: 'top',
      //         },
      //         scales: {
      //             xAxes: [{
      //                 display: true,
      //                 gridLines: {
      //                     color: "#f3f3f3",
      //                     drawTicks: false,
      //                 },
      //                 scaleLabel: {
      //                     display: true,
      //                 }
      //             }],
      //             yAxes: [{
      //                 display: true,
      //                 gridLines: {
      //                     color: "#f3f3f3",
      //                     drawTicks: false,
      //                 },
      //                 scaleLabel: {
      //                     display: true,
      //                 }
      //             }]
      //         },
      //         title: {
      //             display: false,
      //             text: ''
      //         }
      //     };
      //     var chartData = {
      //         labels: this.ClassGroupList,
      //         //datasets: [{
      //         //    label: "Day Wise Water Dispensed",
      //         //    data: volume,
      //         //    backgroundColor: "#673AB7",
      //         //    hoverBackgroundColor: "rgba(103,58,183,.9)",
      //         //    borderColor: "transparent"
      //         //}],
      //         datasets: [{
      //             label: 'Boy',
      //             backgroundColor: "#778CF5",
      //             borderColor: "#778CF5",
      //             borderWidth: 1,
      //             data: this.MaleList
      //         }, {
      //             label: 'Girl',
      //             backgroundColor: "#F577A1  ",
      //             borderColor: "transparent",
      //             data: this.FemaleList
      //         }]
      //     };
      //     var config = {
      //         type: 'horizontalBar',
      //         options: chartOptions,
      //         data: chartData
      //     };
      //     var lineChart = new Chart(ctx, config);
      // }

      NoticeDetails:any;
      GetNoticeDetails () {
         this.layoutService.GetNoticeDetails().subscribe( (data)=> {
             var result = data;
             this.NoticeDetails = result.NoticeDetails;
         });
      }
      EventDetails:any
      GetEvents () {
         this.layoutService.GetEvents().subscribe( (data)=> {
             var result = data;
             this.EventDetails = result.EventDetails;
         });
      }
      CircularDetails:any;
      GetCirculars () {
         this.layoutService.GetCirculars().subscribe( (data)=> {
             var result = data;
             this.CircularDetails = result.CircularDetails;
         });
      }
      HolidayDetails:any;
      GetHolidays () {
          this.layoutService.GetHolidays().subscribe( (data)=> {
              var result = data;
              this.HolidayDetails = result.HolidayDetails;
          });
       }
  
      

ngOnInit() {    

//     this.PG="http://localhost:44397";
  this.GetDashboardDetails();
 this. GetNoticeDetails();
 this.GetEvents ();
 this.GetCirculars();
}



}

