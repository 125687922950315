
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { TransportService } from 'src/app/services/Transport/transport.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Component({
  selector: 'app-fee-book-print',
  templateUrl: './fee-book-print.component.html',
  styleUrls: ['./fee-book-print.component.css']
})
export class FeeBookPrintComponent implements OnInit {
  StudentID = '';
  PrintType = '';
  CopyType = '';
  LogoPath = '';
  Details: any;
  Integer: any;
  decimal: any;
  itemsPerPage = 10;
  pagedItems = [];
  StudentReport = [];
  ComponentList: any;
  constructor(private activatedRoute: ActivatedRoute, private transportService: TransportService, private spinnerService: Ng4LoadingSpinnerService, private financeService: FinanceService, private lookupService: LookupService, public globalHelper: GlobalHelper) {
    this.activatedRoute.queryParams.subscribe(params => {

      this.StudentID = params['StudentID'];
      this.PrintType = params['PrintType'];
      this.CopyType = params['CopyType'];
      this.LogoPath = params['LogoPath'];
      this.GetFeeChallanPrint(this.StudentID)
    });

  }
  GetFeeChallanPrint(StudentID) {
    this.spinnerService.show()
    this.financeService.GetFeeBookPrint(StudentID).subscribe((response) => {
      this.Details = response.Details;
      this.spinnerService.hide()
      // var str = (this.globalHelper.Decimal(this.Details.Amount)).toString().trim();
      // var substr = str.split('.');
      // this.Integer = substr[0];
      // this.decimal = (substr.length == 2 ? substr[1] : 0);

      setTimeout(function () {
        window.print();
      }, 100);
    });
  };
  // search(itemlist) {

  //   for (var i = 0; i < itemlist.length; i++) {
  //     if (i % this.itemsPerPage === 0) {
  //       this.pagedItems[Math.floor(i / this.itemsPerPage)] = [itemlist[i]];
  //     } else {
  //       this.pagedItems[Math.floor(i / this.itemsPerPage)].push(itemlist[i]);
  //     }
  //   }

  // }
  ngOnInit() {

  }

}