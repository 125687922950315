import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-studentlocation',
  templateUrl: './studentlocation.component.html',
  styleUrls: ['./studentlocation.component.css']
})
export class StudentLocationComponent implements OnInit {

  Submitted: boolean = false;
  LocationList: any;

  constructor(private MasterService: MasterService, private globalHelper: GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  LocationModel = {
    LocationID: 0,
            LocationName: '',
            IsActive: true
  };

  Clear() {
    this.LocationModel.LocationID = 0;
    this.LocationModel.LocationName = '';
    this.LocationModel.IsActive = true;
    this.Submitted = false;
  };

  GetLocation = function () {
    this.spinnerService.show();
    this.MasterService.GetLocation(JSON.stringify(this.LocationModel)).subscribe((response: any) => {
      this.LocationList = response.LocationList;
      this.spinnerService.hide();
    });
  };


  SaveLocation(valid) {
    this.Submitted = true;
    if (valid) {
      this.spinnerService.show();
      this.MasterService.SaveLocation(JSON.stringify(this.LocationModel)).subscribe((data: any) => {
        if (data.webResponse.Status) {        
          this.GetLocation();
        }
        if (data.webResponse.Status && this.LocationModel.LocationID==0) {
          this.Clear();
          this.GetLocation();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
  };

  LocationMarkActive(item) {
    this.LocationModel.LocationID = item.LocationID;
    this.LocationModel.IsActive = item.IsActive == true ? false : true;
    this.spinnerService.show();
    this.MasterService.LocationMarkActive(JSON.stringify(this.LocationModel)).subscribe((data: any) => {
      if (data.webResponse.Status) {
        this.Clear();
        this.GetLocation();
      }
      this.globalHelper.CheckActionStatus(data);
      this.spinnerService.hide();
    });

  };

 
  Edit(obj) {

    this.LocationModel.LocationID = obj.LocationID;
    this.LocationModel.LocationName = obj.LocationName;
    this.LocationModel.IsActive = obj.IsActive;


  };


  // Init and Lookup

  ngOnInit() {
    this.GetLocation();
  }


}

