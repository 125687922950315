import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TransportService } from 'src/app/services/Transport/transport.service';
import * as moment from 'moment';

@Component({
  selector: 'app-fee-challan',
  templateUrl: './fee-challan.component.html',
  styleUrls: ['./fee-challan.component.css']
})
export class FeeChallanComponent implements OnInit {

  constructor(private transportService: TransportService, private spinnerService: Ng4LoadingSpinnerService, private financeService: FinanceService, private lookupService: LookupService, public globalHelper: GlobalHelper) { }
  SearchModel = {
    StudentID: 0,
    SectionID: 0,
    CancelStatus: 'N',
    ClassID: 0,
    StudentNo: '',
    Mobile: '',
    FromDate: moment(new Date()).format("DD-MMM-YYYY"),
    PaymentMode: '',
    ToDate: moment(new Date()).format("DD-MMM-YYYY"),
    CreatedBy: '0'
  }
  ClassList: any;
  GetClass() {

    this.lookupService.GetLookupsList('Class', '').subscribe((data) => {
      this.ClassList = data.LookupItems;
    });

  };
  PaymentModeList=[];
  GetPaymentMode() {
    this.lookupService.GetLookupsList('PaymentMode', '').subscribe((data) => {
        this.PaymentModeList = data.LookupItems;

    });
};
Bank=[];
BindBankBranch() {
  this.lookupService.GetLookupsList('Bank', '').subscribe((response) => {
      this.Bank = response.LookupItems;
      
  });
};
BankList=[];
BindBank() {
  this.lookupService.GetLookupsList('BankAll', '').subscribe((response) => {
      this.BankList = response.LookupItems;

  });
};
  ReceivedModel = {
    Remarks: "",
    ChallanReceiptID: "",
    ChequeNo: "",
    ChequeDate:'',
    PaymentModeID:0,
    BankAccountID:0,
    BankID:0,
    BankCharges:0,
  };
  Received(item) {
    this.ReceivedModel = item;
  }

  GetBankCharges() {

    var BankCharges = 0;

    for (var i = 0; i < this.PaymentModeList.length; i++) {

        if (this.PaymentModeList[i].ItemId == this.ReceivedModel.PaymentModeID) {

            BankCharges = this.PaymentModeList[i].BankCharges;
        }

    }
    this.ReceivedModel.BankCharges = BankCharges;
   
}

  ValidityState(){
    return this.ReceivedModel.PaymentModeID==0;
  }
  ChallanTransactionList: any;
  Submitted = false;
  ChallanPaymentReceived() {
    this.Submitted=true;
    if(!this.ValidityState()){
    this.spinnerService.show();
    this.Submitted=false;
    this.financeService.ChallanPaymentReceived(JSON.stringify(this.ReceivedModel)).subscribe((response) => {
      this.spinnerService.hide();
      this.GetStudentFeeChallan();
    });
  }
  }
  StudentList: any;
  GetStudent() {
    this.SearchModel.StudentID = 0;
    var itembo = {
      ItemId: this.SearchModel.ClassID,
      ItemId2: this.SearchModel.SectionID
    }
    this.lookupService.GetLookupsList('Student', JSON.stringify(itembo)).subscribe((data) => {
      this.StudentList = data.LookupItems;


    });
  };
  SectionList: any;
  GetSection() {
    this.SearchModel.SectionID = 0;
    this.SearchModel.StudentID = 0;
    var ItemBo = {
      'ItemId': this.SearchModel.ClassID
    }
    this.lookupService.GetLookupsList('SectionName', JSON.stringify(ItemBo)).subscribe((data) => {
      this.SectionList = data.LookupItems;

    });
  };



  GetStudentFeeChallan() {
    this.SearchModel.FromDate = this.SearchModel.FromDate == "" ? "" : moment(this.SearchModel.FromDate).format("DD-MMM-YYYY");
    this.SearchModel.ToDate = this.SearchModel.ToDate == "" ? "" : moment(this.SearchModel.ToDate).format("DD-MMM-YYYY");
    this.Submitted = true;
    this.spinnerService.show();
    this.financeService.GetStudentFeeChallan(JSON.stringify(this.SearchModel)).subscribe((response) => {
      this.ChallanTransactionList = response.ChallanTransactionList;
      this.Submitted = false;
      this.spinnerService.hide();

    });

  }


  ngOnInit() {
    this.GetClass();
    this.GetSection();
    this.GetStudent();
    this.GetPaymentMode();
    this.BindBank();
    this.BindBankBranch();
  }


  // Print Challan

  PrintFeeChallan(ReceiptNo) {
    var Template = this.globalHelper.GetLoginToken().ChallanTemplate;
    var url = Template + "?PrintTypes=FeeReceipt&ReceiptNo=" + ReceiptNo;
    this.globalHelper.OpenPopUpWindow(url, "yes", "950", "1000");
  };


}
