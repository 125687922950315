import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-testvenue',
  templateUrl: './testvenue.component.html',
  styleUrls: ['./testvenue.component.css']
})
export class TestVenueComponent implements OnInit {

  Submitted: boolean = false;
  TestVenueList: any;
  
  constructor(private MasterService: MasterService, private globalHelper: GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  TestVenueModel = {
   
    TestVenueID: 0,
    TestVenue: '',
    IsActive: true
           
  };

  GetTestVenue = function () {
    this.spinnerService.show();
    this.MasterService.GetTestVenue().subscribe((response: any) => {
      this.TestVenueList = response.TestVenueList;
      this.spinnerService.hide();
    });
  };
  

  Clear() {
    this.TestVenueModel.TestVenueID = 0;
    this.TestVenueModel.TestVenue = '';   
    this.TestVenueModel.IsActive = true;
    this.Submitted = false;
  };

 


  SaveTestVenue(valid) {
    this.Submitted = true;
    if (valid) {
      this.spinnerService.show();
      this.MasterService.SaveTestVenue(JSON.stringify(this.TestVenueModel)).subscribe((data: any) => {
        if (data.webResponse.Status) {
          this.GetTestVenue();
        }
        if (data.webResponse.Status && this.TestVenueModel.TestVenueID == 0) {
          this.Clear();
          this.GetTestVenue();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
  };

  TestVenueMarkActive(item) {
    this.TestVenueModel.TestVenueID = item.TestVenueID;
    this.TestVenueModel.IsActive = item.IsActive == true ? false : true;
    this.spinnerService.show();
    this.MasterService.TestVenueMarkActive(JSON.stringify(this.TestVenueModel)).subscribe((data: any) => {
      if (data.webResponse.Status) {
        this.Clear();
        this.GetTestVenue();
      }
      this.globalHelper.CheckActionStatus(data);
      this.spinnerService.hide();
    });

  };

 

  Edit(obj) {

    this.TestVenueModel.TestVenueID = obj.TestVenueID;
    this.TestVenueModel.TestVenue = obj.TestVenue;   
    this.TestVenueModel.IsActive = obj.IsActive;


  };


  // Init and Lookup

  ngOnInit() {
    this.GetTestVenue();
  }

 

}