import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalHelper } from './services/Common/global.service';
import { UserService } from './services/user/user.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'], 
})

export class AppComponent {
  loading: boolean = false; 
constructor(private globalHelper:GlobalHelper, private router:Router,private userService:UserService){
}

  ngOnInit() {

    if(this.globalHelper.GetToken()!=null){     

    if(this.globalHelper.SiteMap==null)
    {     
      this.GetUserRight();
    }    

  } 
  
  //  else
  //   this.router.navigate(['/']);

  } 

  GetUserRight(){    
    this.userService.GetUserRight().subscribe((data:any)=>{  
     this.globalHelper.SiteMap=  data.BranchRolePermissionList;       
     });
  }

  Logout() {
    window.localStorage.clear();
 }
 
  title = 'JOSA Web Next Gen';


}
