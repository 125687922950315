import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [  
   { path: '', loadChildren: () => import('./public/public.module').then(m => m.PublicModule) }, 
   { path: '', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
   { path: '', loadChildren: () => import('./groupadmin/groupadmin.module').then(m => m.GroupadminModule) },
   { path: '', loadChildren: () => import('./master/master.module').then(m => m.MasterModule) },
   { path: '', loadChildren: () => import('./fee/fee.module').then(m => m.FeeModule) },
   { path: '', loadChildren: () => import('./exam/exam.module').then(m => m.ExamModule) },
   { path: '', loadChildren: () => import('./reception/reception.module').then(m => m.ReceptionModule) },
   { path: '', loadChildren: () => import('./purchase/purchase.module').then(m => m.PurchaseModule) },
   { path: '', loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule) },
   { path: '', loadChildren: () => import('./controlpanel/controlpanel.module').then(m => m.ControlpanelModule) },
   { path: '', loadChildren: () => import('./transport/transport.module').then(m => m.TransportModule) },
   { path: '', loadChildren: () => import('./homework/homework.module').then(m => m.HomeworkModule) },
   { path: '', loadChildren: () => import('./attendance/attendance.module').then(m => m.AttendanceModule) },
   { path: '', loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule) },
   { path: '', loadChildren: () => import('./student/student.module').then(m => m.StudentModule) },
   { path: '', loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule) },
   {path:'superadmin',loadChildren: () => import('./superadmin/superadmin.module').then(m => m.SuperAdminModule)},
   {path:'',loadChildren: () => import('./sms/sms.module').then(m => m.SmsModule)},
   {path:'',loadChildren: () => import('./generalpayment/generalpayment.module').then(m => m.GeneralpaymentModule)},
   {path:'',loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule)},
   {path:'',loadChildren: () => import('./bookstore/bookstore.module').then(m => m.BookstoreModule)},
   {path:'',loadChildren: () => import('./adminsetting/adminsetting.module').then(m => m.AdminsettingModule)},
   {path:'',loadChildren: () => import('./library/library.module').then(m => m.LibraryModule)},
   {path:'',loadChildren: () => import('./homework/homework.module').then(m => m.HomeworkModule)},
   {path:'',loadChildren: () => import('./timetable/timetable.module').then(m => m.TimetableModule)},
   {path:'',loadChildren: () => import('./communication/communication.module').then(m => m.CommunicationModule)},
   {path:'',loadChildren: () => import('./lessonplan/lessonplan.module').then(m => m.LessonplanModule)},
   {path:'',loadChildren: () => import('./studentportal/studentportal.module').then(m => m.StudentportalModule)},
   {path:'',loadChildren: () => import('./hr/hr.module').then(m => m.HRModule)},
   {path:'',loadChildren: () => import('./learning/learning.module').then(m => m.LearningModule)},
   {path:'',loadChildren: () => import('./ticket/ticket.module').then(m => m.TicketModule)},
   {path:'',loadChildren: () => import('./sal/sal.module').then(m => m.SalModule)},
   {path:'channelpartner',loadChildren: () => import('./channelpartner/channelpartner.module').then(m => m.ChannelpartnerModule)},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes
      ,{
        preloadingStrategy: PreloadAllModules
      }
      )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
