import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})
export class SectionComponent implements OnInit {

  Submitted: boolean = false;
  SectionList: any;
  
  constructor(private MasterService: MasterService,private globalHelper: GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  SectionModel = {
    SectionID:0,
    SectionName: '', 
    SectionCode: '',         
    IsActive: true
  };

  Clear() {
    this.SectionModel.SectionID = 0;
    this.SectionModel.SectionCode = '';
    this.SectionModel.SectionName = '';
    this.SectionModel.IsActive = true;
    this.Submitted = false;
  };

  GetAllSection = function () {
    this.spinnerService.show();
    this.MasterService.GetAllSection().subscribe((response: any) => {
      this.SectionList = response.SectionList;   
      this.spinnerService.hide();  
    });
  };


  SaveSection(valid) {
    this.Submitted = true;
    if (valid) {
      this.spinnerService.show();
      this.MasterService.SaveSection(JSON.stringify(this.SectionModel)).subscribe((data: any) => {
        if (data.webResponse.Status) {
          this.GetAllSection();
        }
        if (data.webResponse.Status && this.SectionModel.SectionID == 0) {
          this.Clear();
          this.GetAllSection();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
  };

  SectionMarkActive(item) {
    this.SectionModel.SectionID = item.SectionID;
    this.SectionModel.IsActive = item.IsActive == true ? false : true;
    this.spinnerService.show();
    this.MasterService.SectionMarkActive(JSON.stringify(this.SectionModel)).subscribe((data: any) => {
      if (data.webResponse.Status) {
        this.Clear();
        this.GetAllSection();
      }
      this.globalHelper.CheckActionStatus(data);
      this.spinnerService.hide();
    });

  }; 

  Edit(obj) {

    this.SectionModel.SectionID =obj.SectionID;
    this.SectionModel.SectionName = obj.SectionName;
    this.SectionModel.SectionCode = obj.SectionCode;
    this.SectionModel.IsActive = obj.IsActive;


  };


  ngOnInit() {
    this.GetAllSection();
  }

}
