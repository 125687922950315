import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { GlobalHelper } from 'src/app/services/Common/global.service';

@Component({
    selector: 'app-fee-structure-installment-amount',
    templateUrl: './fee-structure-installment-amount.component.html',
    styleUrls: ['./fee-structure-installment-amount.component.css']
})

export class FeeStructureInstallmentAmountComponent implements OnInit {

    FeeStructureInstallmentModel = {
        ClassID: 0,

    }
    ClassList: any;
    FeeComponentList = [];
    TotalComponentAmount: any;
    Sum: any;
    totalAnual: any;
    totalSum: any;
    selectedRow: any;
    AlertAmount: any;
    InstallmentList = [];
    ClassWiseComponentAmount: any;
    constructor(private spinnerService: Ng4LoadingSpinnerService, private financeService: FinanceService, private lookupService: LookupService, public globalHelper: GlobalHelper) { }





    GetClass() {
        this.lookupService.GetLookupsList('Class', '').subscribe((data) => {
            this.ClassList = data.LookupItems;
        });

    };
    customTrackBy(index: number, obj: any): any {
        return index;
    }

    GetTotalComponent(item) {
        var TotalComponentAmount = 0;
        for (var i = 0; i < this.FeeComponentList.length; i++) {

            var installmentList = this.FeeComponentList[i].InstallmentList;
            for (var j = 0; j < installmentList.length; j++) {

                if (installmentList[j].InstallmentID == item.InstallmentID) {

                    TotalComponentAmount = TotalComponentAmount + parseFloat(installmentList[j].Amount);
                    break;
                }
            }
        }

        return this.TotalComponentAmount = this.globalHelper.Decimal(TotalComponentAmount);

    }

    GetTotalAmount(item) {
        var TotalAmount = 0;

        for (var i = 0; i < item.InstallmentList.length; i++) {
            TotalAmount = TotalAmount + parseFloat(item.InstallmentList[i].Amount);
        }

        item.TotalAmount = TotalAmount;

        this.Sum = this.Sum + TotalAmount;
        this.TotalAnualAmount();
        return item.TotalAmount
    }

    TotalAnualAmount() {
        var totalAnual = 0;
        var totalSum = 0;
        for (var i = 0; i < this.FeeComponentList.length; i++) {
            totalAnual = totalAnual + this.FeeComponentList[i].Amount
            totalSum = totalSum + this.FeeComponentList[i].TotalAmount
        }
        this.totalAnual = totalAnual;

        this.totalSum = totalSum;
    }


    SelectRow(item, index) {

        if (item.Amount < item.TotalAmount) {

            this.selectedRow = index;
        }

    }
    valid = true;
    Submitted = false;
    SaveInstallment() {
        this.Submitted = true;
        this.valid = true;
        var FeeComponentList = this.FeeComponentList;

        for (var i = 0; i < FeeComponentList.length; i++) {
            if (Math.round(FeeComponentList[i].Amount * 1000) / 1000 != Math.round(FeeComponentList[i].TotalAmount * 1000) / 1000) {
                this.valid = false;
                break;
            }
        }
        if (this.valid == false) {
            this.AlertAmount = 'Amounts are not distributed properly, please check the highlighted components';
            return;
        }

        if (this.valid) {
            this.Submitted = false;
            this.spinnerService.show();
            this.financeService.SaveInstallment(JSON.stringify(this.FeeComponentList), this.FeeStructureInstallmentModel.ClassID).subscribe((response) => {
                var response = JSON.parse(response);
                this.globalHelper.CheckActionStatus(response);
                this.spinnerService.hide();
                this.valid = true;
            });
        }
    };


    GetFeeStructurInstallment() {
        this.spinnerService.show();
        setTimeout(() => {

            this.financeService.GetFeeStructurInstallment(this.FeeStructureInstallmentModel.ClassID).subscribe((response) => {
                this.FeeComponentList = response.FeeComponentList;

                for (var i = 0; i < this.FeeComponentList.length; i++) {
                    var TotalAmount = 0;
                    for (var j = 0; j < this.FeeComponentList[i].InstallmentList.length; j++) {
                        TotalAmount = TotalAmount + parseFloat(this.FeeComponentList[i].InstallmentList[j].Amount);
                    }
                    this.FeeComponentList[i].TotalAmount = TotalAmount
                }

                var totalAnual = 0;
                var totalSum = 0;
                for (var i = 0; i < this.FeeComponentList.length; i++) {
                    totalAnual = totalAnual + this.FeeComponentList[i].Amount
                    totalSum = totalSum + this.FeeComponentList[i].TotalAmount
                }
                this.totalAnual = totalAnual;
        
                this.totalSum = totalSum;
                this.InstallmentList = response.InstallmentList;
                this.ClassWiseComponentAmount = response.ClassWiseComponentAmount;
                this.spinnerService.hide();
            });
        }, 2000);
    };



    ngOnInit() {
        this.GetClass();
    }

}
