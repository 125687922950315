import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { GlobalHelper } from '../Common/global.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class MasterService {

  constructor(private globalHelper: GlobalHelper, private httpclient: HttpClient) { }

  
handleError(error) {
  let errorMessage = '';
  if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
  } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  console.log(errorMessage);
  return throwError(errorMessage);
}

  //Admission Open------------------------------------------------------------------

  GetSession(strdata): Observable<any> {
    var param = 'objAdmissionOpen' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/GetSession?' + encodeURI(param), { headers: reqHeader }).pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  SaveAdmissionOpen(strdata): Observable<any> {
    var param = 'objAdmissionOpen=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SaveAdmissionOpen?' + encodeURI(param), { headers: reqHeader });
  }

  // Branch Profile

  GetBranchDetails(): Observable<any> {
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Master/GetBranchDetails', { headers: reqHeader });
  }

  UpdateBranchProfile(BranchModel: any, Logo: any, LogoPrint: any, LogoBoard: any, PrincipalSign: any): Observable<any> {

    let formData = new FormData();
    formData.append("objBranchProfile", BranchModel);
    formData.append("Logo", Logo);
    formData.append("LogoPrint", LogoPrint);
    formData.append("LogoBoard", LogoBoard);
    formData.append("PrincipalSign", PrincipalSign);

    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Master/UpdateBranchProfile', formData);
  }

  //Class Group------------------------------------------------------------------


  SaveClassGroup(strdata: any): Observable<any> {
    let Parameter = 'objClassGroup=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Master/SaveClassGroup?' + encodeURI(Parameter),
      { headers: reqHeader });
  }

  ClassGroupMarkActive(strdata: any): Observable<any> {
    let Parameter = 'objClassGroup=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Master/ClassGroupMarkActive?' + encodeURI(Parameter),
      { headers: reqHeader });
  }



  GetAllClassGroup(): Observable<any> {
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/GetAllClassGroup?', { headers: reqHeader });
  }


  //Class------------------------------------------------------------------

  SearchAllClass(): Observable<any> {
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SearchAllClass', { headers: reqHeader });
  }
  SaveClass(strdata: any): Observable<any> {
    let Parameter = 'objClass=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Master/SaveClass?' + encodeURI(Parameter),
      { headers: reqHeader });
  }


  ClassMarkActive(strdata): Observable<any> {
    var param = 'objClass=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/ClassMarkActive?' + encodeURI(param), { headers: reqHeader });
  }


  //Section------------------------------------------------------------------

  SaveSection(strdata): Observable<any> {
    var param = 'objSection=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SaveSection?' + encodeURI(param), { headers: reqHeader });
  }

  SectionMarkActive(strdata): Observable<any> {
    var param = 'objSection=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SectionMarkActive?' + encodeURI(param), { headers: reqHeader });
  }
  GetAllSection(): Observable<any> {

    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/GetAllSection', { headers: reqHeader });
  }

  //Class Section Map------------------------------------------------------------------

  SaveClassSectionMap(strdata, ClassID): Observable<any> {
    var param = 'objList=' + strdata + '&ClassID=' + ClassID;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SaveClassSectionMap?' + encodeURI(param), { headers: reqHeader });
  }

  GetClassSectionMap(strdata): Observable<any> {
    var param = 'ClassID=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/GetClassSectionMap?' + encodeURI(param), { headers: reqHeader });
  }

  //Subject------------------------------------------------------------------

  SaveSubject(strdata): Observable<any> {
    var param = 'objSubject=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SaveSubject?' + encodeURI(param), { headers: reqHeader });
  }

  SubjectMarkActive(strdata): Observable<any> {
    var param = 'objSubject=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SubjectMarkActive?' + encodeURI(param), { headers: reqHeader });
  }

  SearchAllSubject(strdata, CourseName): Observable<any> {
    var param = 'ObjCode=' + strdata + '&ObjName=' + CourseName;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SearchAllSubject?' + encodeURI(param), { headers: reqHeader });
  }

  //Admission Document------------------------------------------------------------------

  GetAllAdmissionDoc(strdata): Observable<any> {
    var param = 'objModel' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/GetAllAdmissionDoc?' + encodeURI(param), { headers: reqHeader });
  }

  SaveAdmissionDoc(strdata): Observable<any> {
    var param = 'objModel=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SaveAdmissionDoc?' + encodeURI(param), { headers: reqHeader });
  }
  AdmissionDocMarkActive(strdata): Observable<any> {
    var param = 'objModel=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/AdmissionDocMarkActive?' + encodeURI(param), { headers: reqHeader });
  }

  //ID Card------------------------------------------------------------------

  GetIDCardType(strdata): Observable<any> {
    var param = 'objIDCardType=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/GetIDCardType?' + encodeURI(param), { headers: reqHeader });
  }

  SaveIDCardType(strdata): Observable<any> {
    var param = 'objIDCardType=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SaveIDCardType?' + encodeURI(param), { headers: reqHeader });
  }
  IDCardMarkActive(strdata): Observable<any> {
    var param = 'objIDCardType=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SaveIDCardType?' + encodeURI(param), { headers: reqHeader });
  }

  //Department------------------------------------------------------------------

  GetAllDepartment(strdata): Observable<any> {
    var param = 'objModel=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/GetAllDepartment?' + encodeURI(param), { headers: reqHeader });
  }

  SaveDepartment(strdata): Observable<any> {
    var param = 'objModel=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SaveDepartment?' + encodeURI(param), { headers: reqHeader });
  }

  DepartmentMarkActive(strdata): Observable<any> {
    var param = 'objModel=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/DepartmentMarkActive?' + encodeURI(param), { headers: reqHeader });
  }

  

  //Designation------------------------------------------------------------------

  GetAllDesignation(strdata): Observable<any> {
    var param = 'strDesignation=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/GetAllDesignation?' + encodeURI(param), { headers: reqHeader });
  }

  SaveDesignation(strdata): Observable<any> {
    var param = 'objDesignation=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SaveDesignation?' + encodeURI(param), { headers: reqHeader });
  }

  DesignationMarkActive(strdata): Observable<any> {
    var param = 'objDesignation=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/DesignationMarkActive?' + encodeURI(param), { headers: reqHeader });
  }

 

  //School Location------------------------------------------------------------------

  GetSchoolLocation(): Observable<any> {
   var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/GetSchoolLocation' , { headers: reqHeader });
  }

  SaveSchoolLocation(strdata): Observable<any> {
    var param = 'obj=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SaveSchoolLocation?' + encodeURI(param), { headers: reqHeader });
  }
  SchoolLocationMarkActive(strdata): Observable<any> {
    var param = 'obj=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SchoolLocationMarkActive?' + encodeURI(param), { headers: reqHeader });
  }

  //House------------------------------------------------------------------

  GetAllHouse(strdata): Observable<any> {
    var param = 'objHouse=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/GetAllHouse?' + encodeURI(param), { headers: reqHeader });
  }

  SaveHouse(strdata): Observable<any> {
    var param = 'objHouse=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SaveHouse?' + encodeURI(param), { headers: reqHeader });
  }
  HouseMarkActive(strdata): Observable<any> {
    var param = 'objHouse=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/HouseMarkActive?' + encodeURI(param), { headers: reqHeader });
  }

  //Test Venue------------------------------------------------------------------

  GetTestVenue(strdata): Observable<any> {
    var param = 'obj=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/GetTestVenue?' + encodeURI(param), { headers: reqHeader });
  }

  SaveTestVenue(strdata): Observable<any> {
    var param = 'obj=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SaveTestVenue?' + encodeURI(param), { headers: reqHeader });
  }

  TestVenueMarkActive(strdata): Observable<any> {
    var param = 'obj=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/TestVenueMarkActive?' + encodeURI(param), { headers: reqHeader });
  }

  //Room------------------------------------------------------------------

  GetAllRoom(strdata): Observable<any> {
    var param = 'objRoom=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/GetAllRoom?' + encodeURI(param), { headers: reqHeader });
  }

  SaveRoom(strdata): Observable<any> {
    var param = 'objRoom=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SaveRoom?' + encodeURI(param), { headers: reqHeader });
  }
  RoomMarkActive(strdata): Observable<any> {
    var param = 'objRoom=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/RoomMarkActive?' + encodeURI(param), { headers: reqHeader });
  }

  //Qualification------------------------------------------------------------------

  SaveQualification(strdata): Observable<any> {
    var param = 'objQualification=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SaveQualification?' + encodeURI(param), { headers: reqHeader });
  }

  GetAllQualification(strdata): Observable<any> {
    var param = 'strQualification=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/GetAllQualification?' + encodeURI(param), { headers: reqHeader });
  }

  QualificationMarkActive(strdata): Observable<any> {
    var param = 'objQualification=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SaveQualification?' + encodeURI(param), { headers: reqHeader });
  }

  //Country Master-------------------------------------------------------------

  GetCountry(strdata): Observable<any> {
    var param = 'objModel=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/GetCountry?', { headers: reqHeader });
  }

  SaveCountry(strdata): Observable<any> {
    var param = 'objCountry=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SaveCountry?' + encodeURI(param), { headers: reqHeader });
  }

  //State Master---------------------------------------------------------------
  StateBind(strdata): Observable<any> {
    var param = 'objCountry' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/BindState?', { headers: reqHeader });
  }

  SaveState(strdata): Observable<any> {
    var param = 'objState=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SaveState?' + encodeURI(param), { headers: reqHeader });
  }

  //City Master------------------------------------------------------------------

  SaveCity(strdata): Observable<any> {
    var param = 'objCity' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/SaveCity?' + encodeURI(param), { headers: reqHeader });
  }

  GetAllCity(): Observable<any> {
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Master/GetAllCity', { headers: reqHeader });
  }


  //SpecialCategory-------------------------------------------------------------

  GetSpecialCategory(strdata): Observable<any> {
    var param = 'objModel=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Master/GetSpecialCategory?' + encodeURI(param), { headers: reqHeader });
  }

  SaveSpecialCategory(strdata): Observable<any> {
    var param = 'objModel=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Master/SaveSpecialCategory?' + encodeURI(param), { headers: reqHeader });
  }

  SpecialCategoryMarkActive(strdata): Observable<any> {
    var param = 'objModel=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Master/SpecialCategoryMarkActive?' + encodeURI(param),
      { headers: reqHeader });
  }


  //Student Location-------------------------------------------------------------
  GetLocation(strdata): Observable<any> {
    var param = 'objModel=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' }); return this.httpclient.post<any>(this.globalHelper.ApiUrl +
      'Master/GetLocation?' + encodeURI(param), { headers: reqHeader });
  }

  SaveLocation(strdata): Observable<any> {
    var param = 'objModel=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Master/SaveLocation?' + encodeURI(param), { headers: reqHeader });
  }

  LocationMarkActive(strdata): Observable<any> {
    var param = 'objModel=' + strdata;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Master/LocationMarkActive?' + encodeURI(param),
      { headers: reqHeader });
  }


}