import { DashboardComponent } from './dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { GroupDashboardComponent } from './group-dashboard/group-dashboard.component';
import { StudentDashboardComponent } from './student-dashboard/student-dashboard.component';
const routes: Routes = [
  { 
    path: '', 
    component: AdminComponent,
    children: [
      { path: '', component:DashboardComponent, pathMatch: 'full'},
      { path: 'dashboard', component:DashboardComponent, pathMatch: 'full'}, 
      { path: 'group-dashboard', component:GroupDashboardComponent, pathMatch: 'full'},   
      { path: 'main-dashboard', component:MainDashboardComponent, pathMatch: 'full'}, 
      { path: 'student-dashboard', component:StudentDashboardComponent, pathMatch: 'full'}, 
      
    ]
}
  
 ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
