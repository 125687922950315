import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TransportService } from 'src/app/services/Transport/transport.service';
import * as moment from 'moment';

@Component({
  selector: 'app-fee-adjustment-report',
  templateUrl: './fee-adjustment-report.component.html',
  styleUrls: ['./fee-adjustment-report.component.css']
})
export class FeeAdjustmentReportComponent implements OnInit {
  SearchModel = {
    StudentID: 0,
    SectionID: 0,
    SessionID: 0,
    ClassID: 0,
    StudentNo: '',
    FromDate: '',
    ToDate: '',
    RecType:'A',
    CreatedBy: 0
};
Reset  () {
   
    this.SearchModel.ClassID = 0;
    this.SearchModel.SectionID = 0;
    this.SearchModel.StudentNo = '';
    this.SearchModel.RecType='A';
    this.FeeAdjustmentList=[];
};

ClearList(){
    this.FeeAdjustmentList=[];  
}
ClassList:any;
SectionList:any;
AcademicSessionList:any;
PrintDate=moment(Date.now()).format('DD-MMM-YYYY');
FeeComponentList :any;
FeeAdjustmentList :any;
InstallmentList=[];
DownloadHeaderList=[];
lstTotalCollection:any=[];
p=0;
  constructor(private transportService:TransportService,private spinnerService: Ng4LoadingSpinnerService,private financeService: FinanceService,private lookupService:LookupService,public globalHelper :GlobalHelper) { }
  GetAcademicSession  () {
    
    this.lookupService.GetLookupsList('AcademicSession', '').subscribe( (data)=> {
        this.AcademicSessionList = data.LookupItems;
        
    });
};
 ValidateDropdownSearch() {
    return
    this.SearchModel.ClassID == 0 || this.SearchModel.SessionID == 0


};

GetClass  () {

    this.lookupService.GetLookupsList('Class', '').subscribe( (data)=> {
        this.ClassList = data.LookupItems;
    });
};
GetSection  () {
    this.SearchModel.SectionID = 0;
    var itemBo = {
        ItemId: this.SearchModel.ClassID

    };
    this.lookupService.GetLookupsList('SectionName', JSON.stringify(itemBo)).subscribe( (data)=> {
        this.SectionList = data.LookupItems;
    });
};
GetFeeAdjustmentReport  () {

    
    if (true) {
      
      this.spinnerService.show()
        this.financeService.GetFeeAdjustmentReport(JSON.stringify(this.SearchModel)).subscribe( (data)=> {
            
            this.spinnerService.hide()
            if (data.webResponse.Status) {
                this.FeeComponentList = data.FeeComponentList;
                this.FeeAdjustmentList = data.FeeAdjustmentList; 
                this.lstTotalCollection = data.lstTotalCollection;   
                this.InstallmentList = data.InstallmentList;  
                this.DownloadHeaderList=data.DownloadHeaderList;       
                this.p=this.FeeComponentList.length/this.InstallmentList.length;

            }
        });
    }

};
XLSDownload  () {

    
    if (true) {
      
       this.spinnerService.show()
        if (this.FeeAdjustmentList != null) {
            this.JSONToCsvConverterList(this.FeeAdjustmentList, this.SearchModel.RecType=='A'? 'Fee_Adjustment_Report':'Fee_Concession_Report', true);
        }
        this.spinnerService.hide()
            
    }

};
 JSONToCsvConverterList(JSONData, ReportTitle, ShowLabel) {
    var arrayData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    var CSV = '';
    if (ShowLabel) {
        var row = "";
        row += 'Sr. No.' + ',';
        row += 'GR No.' + ',';
        row += 'Student Name' + ',';
        row += 'Class' + ',';
        row += 'Section' + ',';
      
        for (var i = 0; i < this.DownloadHeaderList.length; i++) {
            row += this.DownloadHeaderList[i] + ',';
        }               
        row = row.slice(0, -1);
        CSV += row + '\r\n';
    }
    for (var i = 0; i < arrayData.length; i++) {
        var sno = i + 1;
        var row = "";
        row += '"' + sno + '",';
        row += '"' + arrayData[i].GRNo + '",';
        row += '"' + arrayData[i].FirstName + ' ' + arrayData[i].MiddleName + ' ' + arrayData[i].LastName + '",';
        row += '"' + arrayData[i].ClassName + '",';
        if (arrayData[i].SectionName != null) {
            row += '"' + arrayData[i].SectionName + '",';
        }
        else {
            row += '"' + '' + '",';
        }
     
        for (var j = 0; j < arrayData[i].AmountList.length; j++) {
            row += (arrayData[i].AmountList[j]!=null?arrayData[i].AmountList[j]:'') + ',';
        }
        row.slice(0, row.length - 1);
        CSV += row + '\r\n';
       
       
    }
    var row = "";
    row += '' + ',';
    row += '' + ',';
    row += '' + ',';
    row += '' + ',';
    row += '' + ',';
  
    for (var i = 0; i < this.lstTotalCollection.length; i++) {
        row += this.lstTotalCollection[i] + ',';
    }               
    row = row.slice(0, -1);
    CSV += row + '\r\n';
    if (CSV == '') {
        alert('Invalid Data');
        return;
    }
    var FileName = ReportTitle + '_' + moment(new Date()).format('DD-MMM-YYYY');
    var csvData = new Blob([CSV], { type: 'text/csv;charset=utf-8;' });
    var csvURL = window.URL.createObjectURL(csvData);
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', FileName + '.csv');
    tempLink.click();
}
Print  () {

    var divContents = document.getElementById("dvContainer").innerHTML;
    divContents = divContents.replace('no record found', '');
    var printWindow = window.open('', '', 'height=400,width=800');
    printWindow.document.write('<html><head><title>Voucher Receipt </title>');
    printWindow.document.write('<style type="text/css">');
    printWindow.document.write('.t1{ border-top: 1px solid black;  border-left: 1px solid black;white-space:nowrap;font-family:Calibri;font-size:10px} .t2 { border-right: 1px solid black; border-bottom: 1px solid black;}.t3 { border-right: 1px solid black;border-bottom: 1px solid black; }');
    printWindow.document.write('</style>');
    printWindow.document.write('</head><body >');
    printWindow.document.write(divContents);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();

}
  ngOnInit() {
    this.GetAcademicSession();
    this.GetClass();
    this.GetSection();
  }

}
