import { Injectable } from '@angular/core';
import { GlobalHelper } from '../Common/global.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalService {

  constructor(private globalHelper: GlobalHelper, private httpclient: HttpClient) { }
// Group Admin Start

GetAllSaleOrder(objmodel): Observable<any> {
  let formData = new FormData();
  formData.append("objmodel", objmodel);
   return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetAllSaleOrder', formData);
}
GetSOItem(objmodel): Observable<any> {
  let formData = new FormData();
  formData.append("objmodel", objmodel);
   return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetSOItem', formData);
}
GetAllInvoice(objmodel): Observable<any> {
  let formData = new FormData();
  formData.append("objmodel", objmodel);
   return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetAllInvoice', formData);
}
GetInvoiceItem(objmodel): Observable<any> {
  let formData = new FormData();
  formData.append("objmodel", objmodel);
   return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetInvoiceItem', formData);
}
  
GetAllPayment(objmodel): Observable<any> {
  let formData = new FormData();
  formData.append("objmodel", objmodel);
   return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetAllPayment', formData);
}
// Group Admin End

// Super Admin Start

GetAdminAllSaleOrder(objmodel): Observable<any> {
  let formData = new FormData();
  formData.append("objmodel", objmodel);
   return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetAdminAllSaleOrder', formData);
}
GetAdminSOItem(objmodel): Observable<any> {
  let formData = new FormData();
  formData.append("objmodel", objmodel);
   return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetAdminSOItem', formData);
}
GetAdminAllInvoice(objmodel): Observable<any> {
  let formData = new FormData();
  formData.append("objmodel", objmodel);
   return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetAdminAllInvoice', formData);
}
GetAdminInvoiceItem(objmodel): Observable<any> {
  let formData = new FormData();
  formData.append("objmodel", objmodel);
   return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetAdminInvoiceItem', formData);
}
  
GetAdminAllPayment(objmodel): Observable<any> {
  let formData = new FormData();
  formData.append("objmodel", objmodel);
   return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetAdminAllPayment', formData);
}
GetAdminSubscription(objmodel): Observable<any> {
  let formData = new FormData();
  formData.append("objmodel", objmodel);
   return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetAdminSubscription', formData);
}
SaveAdminRenewalService(strProductList, objModel): Observable<any> {
  let formData = new FormData();
  formData.append("objModel", objModel);
  formData.append("strProductList", strProductList);
  return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/SaveAdminRenewalService', formData);
}


GetAllPayout(objmodel): Observable<any> {
  let formData = new FormData();
  formData.append("objmodel", objmodel);
   return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetAllPayout', formData);
}
SavePayout(objmodel,objlist,file): Observable<any> {
  let formData = new FormData();
  formData.append("objmodel", objmodel);
  formData.append("objlist", objlist);
  formData.append("Attachment", file);
   return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/SavePayout', formData);
}

GetAllPayoutHistory(objmodel): Observable<any> {
  let formData = new FormData();
  formData.append("objmodel", objmodel);
   return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetAllPayoutHistory', formData);
}
// Group Admin End

















  // Dashboard

  GetDashboardDetails(): Observable<any> {
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetDashboardDetails', { headers: reqHeader });
  }
  
  

  //  Quotation

  SaveQuotation(strProductList, objModel): Observable<any> {
    let formData = new FormData();
    formData.append("objModel", objModel);
    formData.append("strProductList", strProductList);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/SaveQuotation', formData);
  }

  GetAllQuotation(objmodel): Observable<any> {
    let formData = new FormData();
    formData.append("objmodel", objmodel);
     return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetAllQuotation', formData);
  }
  
  GetQuotationProducts(objmodel): Observable<any> {
    let formData = new FormData();
    formData.append("objmodel", objmodel);
     return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetQuotationProducts', formData);
  }
  GenerateQuoationSaleOrder(objmodel): Observable<any> {
    let formData = new FormData();
    formData.append("objmodel", objmodel);
     return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GenerateQuoationSaleOrder', formData);
  }
  SaveCancelQuoation(objmodel): Observable<any> {
    let formData = new FormData();
    formData.append("objmodel", objmodel);
     return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/SaveCancelQuoation', formData);
  }
  GetPrintQuotation(objModel): Observable<any> {
    let formData = new FormData();
    formData.append("objModel", objModel);
     return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetPrintQuotation', formData);
  }
  
  // Create Order

  GetCustomerDetails(objModel): Observable<any> {
    let formData = new FormData();
    formData.append("objModel", objModel);
     return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetCustomerDetails', formData);
  }
  GetAllProducts(objModel): Observable<any> {
    let formData = new FormData();
    formData.append("objModel", objModel);
     return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetAllProducts', formData);
  }

  SaveSalOrder(strProductList, objModel): Observable<any> {
    let formData = new FormData();
    formData.append("objModel", objModel);
    formData.append("strProductList", strProductList);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/SaveSalOrder', formData);
  }

  // Order

  GetOrderProducts(objmodel): Observable<any> {
    let formData = new FormData();
    formData.append("objmodel", objmodel);
     return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetOrderProducts', formData);
  }
  
  SaveSalOrderDelivery(objModel): Observable<any> {
    let formData = new FormData();
    formData.append("objModel", objModel);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/SaveSalOrderDelivery', formData);
  }

  GenerateOrderInvoice(objModel): Observable<any> {
    let formData = new FormData();
    formData.append("objModel", objModel);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GenerateOrderInvoice', formData);
  }

  SaveCancelSaleOrder(objModel): Observable<any> {
    let formData = new FormData();
    formData.append("objModel", objModel);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/SaveCancelSaleOrder', formData);
  }


  // Invoice

  GetInvoice(objmodel): Observable<any> {
    let formData = new FormData();
    formData.append("objmodel", objmodel);
     return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetInvoice', formData);
  }

  SaveCancelInvoice(objModel): Observable<any> {
    let formData = new FormData();
    formData.append("objModel", objModel);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/SaveCancelInvoice', formData);
  }

  GetInvoiceByInvoiceNo(objmodel): Observable<any> {
    let formData = new FormData();
    formData.append("objmodel", objmodel);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetInvoiceByInvoiceNo', formData);
  }
  GetInvoiceProducts(objModel): Observable<any> {
    let formData = new FormData();
    formData.append("objModel", objModel);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetInvoiceProducts', formData);
  }
  // Payment

  GetCustomerPendingInvoice(objModel): Observable<any> {
    let formData = new FormData();
    formData.append("objModel", objModel);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetCustomerPendingInvoice', formData);
  }
  
  SaveInvoicePayment(objModel): Observable<any> {
    let formData = new FormData();
    formData.append("objModel", objModel);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/SaveInvoicePayment', formData);
  }
  
  GetAllReceipt(objmodel): Observable<any> {
    let formData = new FormData();
    formData.append("objmodel", objmodel);
     return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetAllReceipt', formData);
  }

  UpdatePaymentStatus(objModel): Observable<any> {
    let formData = new FormData();
    formData.append("objModel", objModel);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/UpdatePaymentStatus', formData);
  }
  
  // Renewal Service

  GetCustomerSubscription(objmodel): Observable<any> {
    let formData = new FormData();
    formData.append("objmodel", objmodel);
     return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetCustomerSubscription', formData);
  }

  GetGroupSubscription(objmodel): Observable<any> {
    let formData = new FormData();
    formData.append("objmodel", objmodel);
     return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetGroupSubscription', formData);
  }
  // SaveSaleOrderCustomer(ItemList,objmodel,): Observable<any> {
  //   let formData = new FormData();
  //   formData.append("strdata", objmodel)
  //   formData.append("ItemList", ItemList);
  //   return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SALCustomer/SaveSaleOrderCustomer', formData);

  // }
  SaveRenewalService(strProductList, objModel): Observable<any> {
    let formData = new FormData();
    formData.append("objModel", objModel);
    formData.append("strProductList", strProductList);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/SaveRenewalService', formData);
  }

  // Pay

  CreateRazorOrder(objmodel): Observable<any> {
    
    let formData = new FormData();
    formData.append("strPayment", objmodel);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'PG/CreateRazorOrder', formData);

  }
  VerifyRazorOrderPayment(objmodel): Observable<any> {
    
    let formData = new FormData();
    formData.append("strPayment", objmodel);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'PG/VerifyRazorOrderPayment', formData);

  }
  GetSaleOrderByOrderNo(objmodel): Observable<any> {
    let formData = new FormData();
    formData.append("objmodel", objmodel);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetSaleOrderByOrderNo', formData);
  }
  CreateTopupRazorOrder(objmodel): Observable<any> {
    
    let formData = new FormData();
    formData.append("strPayment", objmodel);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'PG/CreateTopupRazorOrder', formData);

  }
  VerifyTopupRazorOrderPayment(objmodel): Observable<any> {
    
    let formData = new FormData();
    formData.append("strPayment", objmodel);
    return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'PG/VerifyTopupRazorOrderPayment', formData);

  }
  // Services

  GetAllSubscription(objmodel): Observable<any> {
    let formData = new FormData();
    formData.append("objmodel", objmodel);
     return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetAllSubscription', formData);
  }

  // Customer
  
  GetAllCustomer(objmodel): Observable<any> {
    let formData = new FormData();
    formData.append("objmodel", objmodel);
     return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetAllCustomer', formData);
  }
  SaveCustomer(objmodel): Observable<any> {
    let formData = new FormData();
    formData.append("objmodel", objmodel);
     return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/SaveCustomer', formData);
  }

  // Credit Note

  GetAllCreditNote(objmodel): Observable<any> {
    let formData = new FormData();
    formData.append("objmodel", objmodel);
     return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/GetAllCreditNote', formData);
  }

  SaveCreditNote(objmodel): Observable<any> {
    let formData = new FormData();
    formData.append("objmodel", objmodel);
     return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'SAL/SaveCreditNote', formData);
  }
  
}


