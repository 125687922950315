import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master/master.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})
export class DepartmentComponent implements OnInit {

  Submitted: boolean = false;
  DepartmentList: any;
 
  constructor(private MasterService: MasterService, private globalHelper: GlobalHelper,private spinnerService: Ng4LoadingSpinnerService) { }

  DepartmentSearchModel = {
   
    DepartmentName: '',
    
           
  };

  GetAllDepartment = function () {
    this.spinnerService.show();
    this.MasterService.GetAllDepartment(JSON.stringify(this.DepartmentSearchModel)).subscribe((response: any) => {
      this.DepartmentList = response.DepartmentList;
      this.spinnerService.hide();
    });
  };
  DepartmentModel = {
    DepartmentID: 0,
    DepartmentName: '',
    IsActive: true
  };

  Clear() {
    this.DepartmentModel.DepartmentID = 0;
    this.DepartmentModel.DepartmentName = '';   
    this.DepartmentModel.IsActive = true;
    this.Submitted = false;
  };

 


  SaveDepartment(valid) {
    this.Submitted = true;
    if (valid) {
      this.spinnerService.show();
      this.MasterService.SaveDepartment(JSON.stringify(this.DepartmentModel)).subscribe((data: any) => {
        if (data.webResponse.Status) {
          this.GetAllDepartment();
        }
        if (data.webResponse.Status && this.DepartmentModel.DepartmentID == 0) {
          this.Clear();
          this.GetAllDepartment();
        }
        this.globalHelper.CheckActionStatus(data);
        this.spinnerService.hide();
      });
    }
  };

  DepartmentMarkActive(item) {
    this.DepartmentModel.DepartmentID = item.DepartmentID;
    this.DepartmentModel.IsActive = item.IsActive == true ? false : true;
    this.MasterService.DepartmentMarkActive(JSON.stringify(this.DepartmentModel)).subscribe((data: any) => {
      if (data.webResponse.Status) {
        this.Clear();
        this.GetAllDepartment();
      }
      this.globalHelper.CheckActionStatus(data);
    });

  };



  Edit(obj) {

    this.DepartmentModel.DepartmentID = obj.DepartmentID;
    this.DepartmentModel.DepartmentName = obj.DepartmentName;
    this.DepartmentModel.IsActive = obj.IsActive;


  };


  // Init and Lookup

  ngOnInit() {
    this.GetAllDepartment();
  }

 

}

