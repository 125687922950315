import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalHelper } from '../Common/global.service';

@Injectable({
  providedIn: 'root'
})
export class TransportService {
  constructor(private globalHelper: GlobalHelper,private httpclient:HttpClient) { }
  GetDueGeneralPayment(ClassID):Observable<any>{  
    let param='ClassID=' +ClassID; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'GeneralPayment/GetDueGeneralPayment?'
      +encodeURI(param),{headers:reqHeader});
  }
  GetDueBookStore(StudentID):Observable<any>{  
    let param='StudentID=' +StudentID; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Inventory/GetDueBookStore?'
      +encodeURI(param),{headers:reqHeader});
  }
 
  GetStudentTransportFeeInstallment(StudentID,RouteID,RouteLocationID):Observable<any>{  
    let param='StudentID=' +StudentID;  
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Transport/GetStudentTransportFeeInstallment?'
      +encodeURI(param),{headers:reqHeader});
  }
  SaveStudentTransportFeePayment(strStudent,FeeComponentList):Observable<any>{  
     let formData = new FormData();
      formData.append("strStudent", strStudent);
      formData.append("FeeComponentList", FeeComponentList);
      return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Transport/SaveStudentTransportFeePayment', formData);
  }
  

  SaveRoute(objRouteDetails):Observable<any>{  
    let param='objRouteDetails=' +objRouteDetails; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Transport/SaveRoute?'
      +encodeURI(param),{headers:reqHeader});
  }
  RouteMarkActive(objRouteDetails):Observable<any>{  
    let param='objRouteDetails=' +objRouteDetails; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Transport/RouteMarkActive?'
      +encodeURI(param),{headers:reqHeader});
  }
  SearchAllRoute():Observable<any>{       
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Transport/SearchAllRoute'
      ,{headers:reqHeader});
  }
  
  SaveLocation(objLocation):Observable<any>{  
    let param='objLocation=' +objLocation; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Transport/SaveLocation?'
      +encodeURI(param),{headers:reqHeader});
  }
  GetAllLocation():Observable<any>{  
   
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Transport/GetAllLocation'
      ,{headers:reqHeader});
  }
  LocationMarkActive(objLocation):Observable<any>{  
    let param='objLocation=' +objLocation; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Transport/LocationMarkActive?'
      +encodeURI(param),{headers:reqHeader});
  }
  SaveVehicleDetails(objVehicle):Observable<any>{  
    let param='objVehicle=' +objVehicle; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Transport/SaveVehicleDetails?'
      +encodeURI(param),{headers:reqHeader});
  }
  GetAllVehicleDetails():Observable<any>{  
    
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Transport/GetAllVehicleDetails'
      ,{headers:reqHeader});
  }
  VehicleMarkActive(objVehicle):Observable<any>{  
    let param='objVehicle=' +objVehicle; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Transport/VehicleMarkActive?'
      +encodeURI(param),{headers:reqHeader});
  }
  GetAllVehicleDetailsByStudent(objFilter):Observable<any>{  
    let param='objFilter=' +objFilter; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Transport/GetAllVehicleDetailsByStudent?'
      +encodeURI(param),{headers:reqHeader});
  }
  GetAllTransportOptReport(objFilter):Observable<any>{  
    let param='objFilter=' +objFilter; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Transport/GetAllTransportOptReport?'
      +encodeURI(param),{headers:reqHeader});
  }
  SaveRouteLocationMap(objRouteFeeStructure,strLocation):Observable<any>{  
    let formData = new FormData();
      formData.append("objRouteFeeStructure", objRouteFeeStructure);
      formData.append("strLocation", strLocation);
      return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Transport/SaveRouteLocationMap', formData);
      
  }
  SaveRouteFeeStructure(objRouteFee,RouteID):Observable<any>{     
      let formData = new FormData();
      formData.append("objRouteFee", objRouteFee);
      formData.append("RouteID", RouteID);
      return this.httpclient.post<any>(this.globalHelper.ApiUrl + 'Transport/SaveRouteFeeStructure', formData);
      
  }
  GetAllRouteFeeStructure(RouteID):Observable<any>{  
    let param='RouteID=' +RouteID; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Transport/BindRouteFeeStructure?'
      +encodeURI(param),{headers:reqHeader});
  }
  GetAllRouteFeeStructureList(RouteID):Observable<any>{  
    let param='RouteID=' +RouteID; 
    var reqHeader = new HttpHeaders({'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.httpclient.post<any>(this.globalHelper.ApiUrl
      +'Transport/BindRouteFeeStructureList?'
      +encodeURI(param),{headers:reqHeader});
  }
}
