import { Component, OnInit } from '@angular/core';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TransportService } from 'src/app/services/Transport/transport.service';
import * as moment from 'moment';

@Component({
  selector: 'app-fee-defaulter-reports',
  templateUrl: './fee-defaulter-reports.component.html',
  styleUrls: ['./fee-defaulter-reports.component.css']
})
export class FeeDefaulterReportsComponent implements OnInit {
  SearchModel = {
    StudentID: 0,
    SectionID: 0,
    SessionID: 0,
    ClassID: 0,
    StudentNo: '',
    FromDate: moment(new Date()).format('DD-MMM-YYYY'),
    ToDate: '',
    Print:'',
    CreatedBy: 0
}
AcademicSessionList:any;
InstallmentList :any;
FeeCollectionReportsList:any;
PaybleHeaderList :any;
TotalAmountList :any;
SectionList:any;
ClassList:any;
  constructor(private transportService:TransportService,private spinnerService: Ng4LoadingSpinnerService,private financeService: FinanceService,private lookupService:LookupService,public globalHelper :GlobalHelper) {  }
Reset  () {
   
    this.SearchModel.ClassID = 0;
    this.SearchModel.SectionID = 0;
    this.SearchModel.StudentNo = '';
};
GetAcademicSession  () {
  this.lookupService.GetLookupsList('AcademicSession', '').subscribe( (data)=> {
      this.AcademicSessionList = data.LookupItems;
  });
};
 ValidateDropdownSearch() {
  return this.SearchModel.FromDate == '' ;


};
GetClass () {

  this.lookupService.GetLookupsList('Class', '').subscribe( (data)=> {
      this.ClassList = data.LookupItems;
  });
};
GetSection () {
  this.SearchModel.SectionID = 0;
  var ItemBo = {
      'ItemId': this.SearchModel.ClassID
  }
  this.lookupService.GetLookupsList('SectionName', JSON.stringify(ItemBo)).subscribe( (data) =>{
      this.SectionList = data.LookupItems;

  });
};
Submitted=false;
GetFeeDefaulterReports () {
this.Submitted=true;
  
  if (!this.ValidateDropdownSearch()) {
    this.SearchModel.FromDate=moment(this.SearchModel.FromDate).format('DD-MMM-YYYY');
      this.spinnerService.show();
      this.financeService.GetFeeDefaulterReports(JSON.stringify(this.SearchModel)).subscribe( (data) =>{
          this.spinnerService.hide();
          this.Submitted=false;
          if (data.webResponse.Status) {
              this.InstallmentList = data.InstallmentList;
              this.FeeCollectionReportsList = data.FeeCollectionReportsList;
              this.PaybleHeaderList = data.PaybleHeaderList;
              this.TotalAmountList = data.TotalAmountList;
              
              

          }
      });
  }

};
GetFeeDefaulterReportsDownload  () {

  if (this.FeeCollectionReportsList != null) {
      this.JSONToCsvConverterList(this.FeeCollectionReportsList, 'Fee_Defaulter_Report', true);
  }

};

 JSONToCsvConverterList(JSONData, ReportTitle, ShowLabel) {
  var arrayData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
  var CSV = '';
  if (ShowLabel) {
      var row = "";
      row += 'Sr. No.' + ',';
      row += 'GR.No.' + ',';
      row += 'Name' + ',';
      row += 'Class' + ',';
      row += 'Section' + ',';
      row += 'Father Name' + ',';
      row += 'Mobile' + ',';
      row += 'Email ID' + ',';
      for (var j = 0; j < this.InstallmentList.length; j++) {
          row += this.InstallmentList[j].Installment +',';        
      }
      row += 'Total Due' + ',';
      row = row.slice(0, -1);
      CSV += row + '\r\n';
  }
  for (var i = 0; i < arrayData.length; i++) {
      var sno = i + 1;
      var row = "";
      row += '"' + sno + '",';
      row += '"' + arrayData[i].GRNo + '",';
      row += '"' + arrayData[i].FirstName + '",';
      row += '"' + arrayData[i].ClassName + '",';
      row += '"' + arrayData[i].SectionName + '",';
      if (arrayData[i].FatherName != null) {
          row += '"' + arrayData[i].FatherName + '",';
      }
      else {
          row += '"' + '' + '",';
      }
      if (arrayData[i].Mobile != null) {
          row += '"' + arrayData[i].Mobile + '",';
      }
      else {
          row += '"' + '' + '",';
      }
      if (arrayData[i].EmailID != null) {
          row += '"' + arrayData[i].EmailID + '",';
      }
      else {
          row += '"' + '' + '",';
      }
      for (var j = 0; j < arrayData[i].AmountList.length; j++) {
          if (arrayData[i].AmountList[j] == '-' || arrayData[i].AmountList[j] == '-NA-') {
              row += arrayData[i].AmountList[j] + ',';
          }
          else {
              row += this.globalHelper.Decimal(arrayData[i].AmountList[j]) + ',';
          }
      }
      row += '"' + this.globalHelper.Decimal(arrayData[i].TotalPayableAmountOnDate - arrayData[i].TotalPaidAmountOnDate) + '",';
      row.slice(0, row.length - 1);
      CSV += row + '\r\n';
  }
  var row1 = "";
  row1 += '"' + '' + '",';
  row1 += '"' + '' + '",';
  row1 += '"' + '' + '",';
  row1 += '"' + '' + '",';
  row1 += '"' + '' + '",';
  row1 += '"' + '' + '",';
  row1 += '"' + '' + '",';
  row1 += '"' + 'Total' + '",';
  for (var j = 0; j < this.TotalAmountList.length; j++) {
      if (this.TotalAmountList[j] == '-' || this.TotalAmountList[j] == '-NA-') {
          row1 += this.TotalAmountList[j] + ',';
      }
      else {
          row1 += this.globalHelper.Decimal(this.TotalAmountList[j]) + ',';
      }
  }
 
  row.slice(0, row.length - 1);
  CSV += row1 + '\r\n';
  if (CSV == '') {
      alert('Invalid Data');
      return;
  }
  var FileName = ReportTitle + '_'  + moment(new Date()).format('DD-MMM-YYYY');
  var csvData = new Blob([CSV], { type: 'text/csv;charset=utf-8;' });
  var csvURL = window.URL.createObjectURL(csvData);
  var tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', FileName + '.csv');
  tempLink.click();
}
  ngOnInit() {
    this.GetAcademicSession();
    this.GetClass();
    this.GetSection();
  }

}
