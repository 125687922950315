import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FinanceService } from 'src/app/services/Finance/finance.service';
import { LookupService } from 'src/app/services/Common/lookup.service';
import { GlobalHelper } from 'src/app/services/Common/global.service';
import { TransportService } from 'src/app/services/Transport/transport.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Component({
  selector: 'app-fee-challan-print',
  templateUrl: './fee-challan-print.component.html',
  styleUrls: ['./fee-challan-print.component.css']
})
export class FeeChallanPrintComponent implements OnInit {
  ReceiptNo = '';
  PrintType = '';
  CopyType = '';
  LogoPath = '';
  BranchDetails: any;
  FeeReceiptList: any;
  FeeReceiptModel = {
    ClassID: "0",
    StudentID: "0",
    FirstName: '',
    MiddleName: '',
    LastName: '',
    ClassName: '',
    StreamName: '',
    StudentName: '',
    PaymentMode: '',
    PaidAmount: 0,
    FeeAmount: 0,
    Fine: 0,
    TotalFeeAmounts: 0,
    TotalDiscountAmount: 0,
    OpeningAmount: 0,
    WaiverAmount: 0,
  }
  ReceiptList: any;
  Integer: any;
  decimal: any;
  itemsPerPage = 10;
  pagedItems = [];
  ComponentList: any;
  constructor(private activatedRoute: ActivatedRoute, private transportService: TransportService, private spinnerService: Ng4LoadingSpinnerService, private financeService: FinanceService, private lookupService: LookupService, public globalHelper: GlobalHelper) {
    this.activatedRoute.queryParams.subscribe(params => {

      this.ReceiptNo = params['ReceiptNo'];
      this.PrintType = params['PrintType'];
      this.CopyType = params['CopyType'];
      this.LogoPath = params['LogoPath'];
      this.GetFeeChallanPrint(this.ReceiptNo)
    });

  }
  GetFeeChallanPrint(ReceiptNo) {
    this.spinnerService.show()
    this.financeService.GetFeeChallanPrint(ReceiptNo).subscribe((response) => {
      this.BranchDetails = response.BranchDetails;
      this.spinnerService.hide()
      this.ComponentList = response.ComponentList;
      this.FeeReceiptList = response.FeeReceiptList;
      var TotalDiscountAmount = 0;
      var TotalFeeAmounts = 0;
      for (var i = 0; i < this.FeeReceiptList.length; i++) {
        TotalDiscountAmount = TotalDiscountAmount + this.FeeReceiptList[i].DiscountAmount;
        TotalFeeAmounts = TotalFeeAmounts + this.FeeReceiptList[i].Amount;
      }
      this.FeeReceiptModel.TotalDiscountAmount = TotalDiscountAmount;
      this.FeeReceiptModel.TotalFeeAmounts = TotalFeeAmounts;
      this.ReceiptList = this.FeeReceiptList[0];
      var str = (this.globalHelper.Decimal(this.ReceiptList.PaidAmount)).toString().trim();
      var substr = str.split('.');
      this.Integer = substr[0];
      this.decimal = (substr.length == 2 ? substr[1] : 0);

      setTimeout(function () {
        window.print();
      }, 100);
    });
  };
  search(itemlist) {

    for (var i = 0; i < itemlist.length; i++) {
      if (i % this.itemsPerPage === 0) {
        this.pagedItems[Math.floor(i / this.itemsPerPage)] = [itemlist[i]];
      } else {
        this.pagedItems[Math.floor(i / this.itemsPerPage)].push(itemlist[i]);
      }
    }

  }
  ngOnInit() {

  }

}